import Vue from "vue";
import VueRouter from "vue-router";
import VueMeta from "vue-meta";

// import IndexOne from '../pages/indices/IndexOne.vue';
// import IndexTwo from '../pages/indices/IndexTwo.vue';
// import IndexThree from '../pages/indices/IndexThree.vue';
// import IndexSix from '../pages/indices/IndexSix.vue';
// import IndexFour from '../pages/indices/IndexFour.vue';
import IndexFive from "../pages/indices/IndexFive.vue";
import ExtraMyle from "../pages/extramyle/ExtraMyle.vue";
// import NewYear from "../pages/new-year/new-year.vue";
// import StadiumNewYearEvent from "../pages/stadium-nye/stadium-nye.vue";
import AlumniChapter from "../pages/AlumniChapter/alumni-chapter.vue";
import SneakerJam from "../pages/SneakerJam/sneaker-jam.vue";
import SlowJamSocial from "../pages/SlowJam/slow-jam.vue";
import BohemianBrunch from "../pages/BohemianBrunch/bohemian-brunch.vue";
import BohemianLunch from "../pages/BohemianLunch/bohemian-lunch.vue";
// import GoldCarpetExperience from "../pages/GoldCarpet/gold-carpet.vue";
import WisdomSeekersConference from "../pages/WisdomSeeker/wisdom-seeker.vue";
import AnnualKappa from "../pages/AnnualKappa/Annual-Kappa.vue";
import TicketingFAQ from "../pages/TicketingFAQ/TicketingFAQ.vue";
import MYLEFAQ from "../pages/FAQs/FAQs.vue";

// import TermsAndConditions from "../pages/Terms&Conditions/TermsAndConditions.vue";
import privacyPolicy from "../pages/PrivacyPolicy/privacyPolicy.vue";
import cookies from "../pages/Cookies/cookies.vue";
import myleAmbassador from "../pages/Ambassador/myleAmbassador.vue";
import myleBusiness from "../pages/Business/myleBusiness.vue";
import myleAffilate from "../pages/Affiliate/myleAffilate.vue";
import orlando from "../pages/Orlando-Launch/orlando.vue";
import myleTerms from "../pages/terms.vue";
import ProfitPartner from "../pages/ProfitPartner/ProfitPartner.vue";

import ChangeManagement from "../pages/ChangeManagement.vue";
import DataIntegrityandGovernancePolicy from "../pages/DataIntegrityandGovernancePolicy.vue";
import DataManagementStrategy from "../pages/DataManagementStrategy.vue";
import IncidentResponsePlan from "../pages/IncidentResponsePlan.vue";

import TrainingComponent from "../pages/Training/Training";
import AtlantaBlog from "../pages/AtlantaBlog/atlanta-blog.vue";
import AtlantaLaunch from "../pages/AtlantaLaunch/atlanta-launch.vue";
import ohioBlogPost from "../pages/OhioBlogPost/ohioBlogPost.vue";
import kentuckyBlogPost from "../pages/KentuckyBlogPost/kentuckyBlogPost.vue";
import AtlantaRSVP from "../pages/AtlantaRSVP/atlantaRSVP.vue";
import tennesseeBlogPost from "../pages/TennesseeBlogPost/tennesseeBlogPost.vue";
import atlantaBlogPost from "../pages/AtlantaBlogPost/atlantaBlogPost.vue";
import MYLEMIXERReg from "../pages/MYLEMIXER/MYLEMIXER.vue";
import Win500 from "../pages/Win500/win500.vue";
import winningRules from "../pages/winningRules/winningRules.vue";
import MYLEVipExperience from "../pages/MYLEVipExperience/MYLEVipExperience.vue";
import DinnerAndDancing from "../pages/DinnerAndDancing/DinnerAndDancing.vue";
import ALLACCESSPASS from "../pages/Juneteenth/ALLACCESSPASS/ALLACCESSPASS.vue";
import BlackFridayOrlando from "../pages/Juneteenth/BlackFridayOrlando/BlackFridayOrlando.vue";
import BlackWallStreet from "../pages/Juneteenth/BlackWallStreet/BlackWallStreet.vue";
import RetroHappyHour from "../pages/Juneteenth/RetroHappyHour/RetroHappyHour.vue";
// import TheArtofBrunch from "../pages/Juneteenth/TheArtofBrunch/TheArtofBrunch.vue";
import juneteenth from "../pages/Juneteenth/juneteenth.vue";
import boujeeBall from "../pages/BoujeeBall/BoujeeBall.vue";
import JazzOnTheLawn from "../pages/JazzOnTheLawn/JazzOnTheLawn.vue";
import ComedyShow from "../pages/ComedyShow/ComedyShow.vue";
import MozartMonsterEscapeRoom from "../pages/MozartMonsterEscapeRoom/MozartMonsterEscapeRoom.vue";
import ComedyFestival from "../pages/1865ComedyFestival/1865ComedyFestival.vue";
import VCFestEduDay from "../pages/VCFestEduDay/VCFestEduDay.vue";
import VCFestPitchDay from "../pages/VCFestPitchDay/VCFestPitchDay.vue";
import VCFestDays from "../pages/VCFestDays/VCFestDays.vue";
import KappaCigarsAndSundresses from "../pages/KappaCigarsAndSundresses/KappaCigarsAndSundresses.vue";
import BecomingBankableMasterClass from "../pages/BecomingBankableMasterClass/BecomingBankableMasterClass.vue";
import ChuckTuxVirgoBash from "../pages/ChuckTuxVirgoBash/ChuckTuxVirgoBash.vue";
import LegendaryCultural from "../pages/LegendaryCultural/LegendaryCultural.vue";
import ComedyShowWithDinner from "../pages/ComedyShowWithDinner/ComedyShowWithDinner.vue";
import KillerMikeDay from "../pages/KillerMikeDay/KillerMikeDay.vue";
import JackLeFloreMemorialGolfTournament from "../pages/JackLeFloreMemorialGolfTournament/JackLeFloreMemorialGolfTournament.vue";
import ANNUALKAPPARETREAT from "../pages/ANNUALKAPPARETREAT/ANNUALKAPPARETREAT.vue";
import SavingOurChildern from "../pages/SavingOurChildern/SavingOurChildern.vue";
// import TheBigGolfWeekend from "../pages/TheBigGolfWeekend/TheBigGolfWeekend.vue";
// import TheBigGolfWeekend2nd from "../pages/2ndTheBigGolfWeekend/2ndTheBigGolfWeekend.vue";
import TheBigGolfWeekend3rd from "../pages/3rdTheBigGolfWeekend/3rdTheBigGolfWeekend.vue";

import EntrepreneursGala from "../pages/EntrepreneursGala/EntrepreneursGala.vue";
import STEMmys from "../pages/STEMmys/STEMmys.vue";
import MYLEExposure from "../pages/MYLEExposure/MYLEExposure.vue";
import CommunityHallTown from "../pages/CommunityHallTown/CommunityHallTown.vue";
import TheKappaBlackWhite2ndAnnualScholarshipFundraiser from "../pages/TheKappaBlackWhite2ndAnnualScholarshipFundraiser/TheKappaBlackWhite2ndAnnualScholarshipFundraiser.vue";
import BlackFoodTruckFestival from "../pages/BlackFoodTruckFestival/BlackFoodTruckFestival.vue";
import BlackFoodTruckFestivalDay1 from "../pages/BlackFoodTruckFestivalDay1/BlackFoodTruckFestivalDay1.vue";
import BlackFoodTruckFestivalDay2 from "../pages/BlackFoodTruckFestivalDay2/BlackFoodTruckFestivalDay2.vue";
import BlackFoodTruckFestivalWeekendBundle from "../pages/BlackFoodTruckFestivalWeekendBundle/BlackFoodTruckFestivalWeekendBundle.vue";
import BlackFoodTruckFestivalTwoDaysBundle from "../pages/BlackFoodTruckFestivalTwoDaysBundle/BlackFoodTruckFestivalTwoDaysBundle.vue";
import CSUStepShow from "../pages/CSUStepShow/CSUStepShow.vue";
import CSUStepShowGreeks from "../pages/CSUStepShowGreeks/CSUStepShowGreeks.vue";
import CSUConcert from "../pages/CSUConcert/CSUConcert.vue";
import CSUConcertMainPage from "../pages/CSUConcertMainPage/CSUConcertMainPage.vue";
import NupeKoming from "../pages/NupeKoming/NupeKoming.vue";
import ALLBLACKAFFAIR from "../pages/ALLBLACKAFFAIR/ALLBLACKAFFAIR.vue";
import SkegeeBall from "../pages/SkegeeBall/SkegeeBall.vue";
import TheirBodiesTheirChoices from "../pages/TheirBodiesTheirChoices/TheirBodiesTheirChoices.vue";
import NAAAHP from "../pages/NAAAHP/NAAAHP.vue";
import NAAAHP2nd from "../pages/2ndNAAAHP/2ndNAAAHP.vue";
import DealDay from "../pages/DealDay/DealDay.vue";
import AllAccessKrimsonPartyPackages from "../pages/AllAccessKrimsonPartyPackages/AllAccessKrimsonPartyPackages.vue";
import FamilyOutingontheRiverwalk from "../pages/FamilyOutingontheRiverwalk/FamilyOutingontheRiverwalk.vue";
import KremeKultureJam from "../pages/KremeKultureJam/KremeKultureJam.vue";
import KRIMSONKlimaxPartyinBlack from "../pages/KRIMSONKlimaxPartyinBlack/KRIMSONKlimaxPartyinBlack.vue";
import KrimsonKultureTour from "../pages/KrimsonKultureTour/KrimsonKultureTour.vue";
import KrimsonTakeoverParty from "../pages/KrimsonTakeoverParty/KrimsonTakeoverParty.vue";
import KappaConclaveCollection from "../pages/KappaConclaveCollection/KappaConclaveCollection.vue";
import KappaConclaveRegistered2023 from "../pages/KappaConclaveRegistered2023/KappaConclaveRegistered2023.vue";
import ACCCCF from "../pages/ACCCCF/ACCCCF.vue";
import BLACKFATHERDAUGHTERDANCEATCOSI from "../pages/BLACKFATHERDAUGHTERDANCEATCOSI/BLACKFATHERDAUGHTERDANCEATCOSI.vue";
import PreGameDayParty from "../pages/PreGameDayParty/PreGameDayParty.vue";
import PreGameNightParty from "../pages/PreGameNightParty/PreGameNightParty.vue";
import OSUvsUGAPreGamePartyCollection from "../pages/OSUvsUGAPreGamePartyCollection/OSUvsUGAPreGamePartyCollection.vue";
import InvestinginourFutureScholarshipGala from "../pages/InvestinginourFutureScholarshipGala/InvestinginourFutureScholarshipGala.vue";
import InvestinginourFutureScholarshipGala2024 from "../pages/InvestinginourFutureScholarshipGala2024/InvestinginourFutureScholarshipGala2024.vue";
import InvestinginourFutureScholarshipGala2024Widget from "../pages/InvestinginourFutureScholarshipGala2024Widget/InvestinginourFutureScholarshipGala2024Widget.vue";
import InvestinginourFutureScholarshipGala2024Donations from "../pages/InvestinginourFutureScholarshipGala2024Donations/InvestinginourFutureScholarshipGala2024Donations.vue";

import GammaEpsilonchapterofKappaAlphaPsi from "../pages/GammaEpsilonchapterofKappaAlphaPsi/GammaEpsilonchapterofKappaAlphaPsi.vue";
import AnnualKappaWineSip2nd from "../pages/2ndAnnual-Kappa/2ndAnnual-Kappa.vue";
import BlackFoodTruckFestival2nd from "../pages/2ndBlackFoodTruckFestival/2ndBlackFoodTruckFestival.vue";
import BlackFoodTruckFestivalDay12nd from "../pages/2ndBlackFoodTruckFestivalDay1/2ndBlackFoodTruckFestivalDay1.vue";
import BlackFoodTruckFestivalDay22nd from "../pages/2ndBlackFoodTruckFestivalDay2/2ndBlackFoodTruckFestivalDay2.vue";
import BlackFoodTruckFestivalTwoDaysBundle2nd from "../pages/2ndBlackFoodTruckFestivalTwoDays/2ndBlackFoodTruckFestivalTwoDays.vue";
import NorthCentralRegionConference from "../pages/NorthCentralRegionConference/NorthCentralRegionConference.vue";
import CSUGreekStrollOff from "../pages/CSUGreekStrollOff/CSUGreekStrollOff.vue";
import CSUGreekStrollOffGreeksOnly from "../pages/CSUGreekStrollOffGreeksOnly/CSUGreekStrollOffGreeksOnly.vue";
import CSUSpringfestSilentHeadphoneParty from "../pages/CSUSpringfestSilentHeadphoneParty/CSUSpringfestSilentHeadphoneParty.vue";
import CSUSpringFestMainPage from "../pages/CSUSpringfestMain/CSUSpringfestMain.vue";
import PlayDate from "../pages/PlayDate/PlayDate.vue";
import VIPAllAccessPass from "../pages/2ndJuneteenth/VIPAllAccessPass/VIPAllAccessPass.vue";
import BlackFridayScavengerHunt from "../pages/2ndJuneteenth/BlackFridayScavengerHunt/BlackFridayScavengerHunt.vue";
import BetterTogetherJuneteenthMixer from "../pages/2ndJuneteenth/BetterTogetherJuneteenthMixer/BetterTogetherJuneteenthMixer.vue";
import BetterTogetherJuneteenthMixerDiscounted from "../pages/2ndJuneteenth/BetterTogetherJuneteenthMixerDiscounted/BetterTogetherJuneteenthMixerDiscounted.vue";
import BLKYRDJuneteenthBlockParty from "../pages/2ndJuneteenth/BLKYRDJuneteenthBlockParty/BLKYRDJuneteenthBlockParty.vue";

import BLKYRDJuneteenthBlockPartyDiscounted from "../pages/2ndJuneteenth/BLKYRDJuneteenthBlockPartyDiscounted/BLKYRDJuneteenthBlockPartyDiscounted.vue";

import TheAntiBrunch from "../pages/2ndJuneteenth/TheAntiBrunch/TheAntiBrunch.vue";
import Juneteenth2nd from "../pages/2ndJuneteenth/2ndJuneteenth.vue";
import boujeeBallTwo from "../pages/2ndBoujeeBall/2ndBoujeeBall.vue";

import TheSkegeeBallParlayHomecomingSoiree from "../pages/TheSkegeeBallParlayHomecomingSoiree/TheSkegeeBallParlayHomecomingSoiree.vue";

import NewTheSkegeeBallParlayHomecomingSoiree from "../pages/NewTheSkegeeBallParlayHomecomingSoiree/NewTheSkegeeBallParlayHomecomingSoiree.vue";

import BisonRattlerBoogieFriday from "../pages/BisonRattlerBoogieFriday/BisonRattlerBoogieFriday.vue";

import JazzBrunch from "../pages/BlackMen100/JazzBrunch/JazzBrunch.vue";
import ScholarshipGala from "../pages/BlackMen100/ScholarshipGala/ScholarshipGala.vue";

import ScholarshipGalaDonation from "../pages/BlackMen100/ScholarshipGalaDonation/ScholarshipGalaDonation.vue";

import ScholarshipGalaSilentAuction from "../pages/BlackMen100/ScholarshipGalaSilentAuction/ScholarshipGalaSilentAuction.vue";

import ScholarShipGalaWeekendSocialKickoff from "../pages/BlackMen100/ScholarShipGalaWeekendSocialKickoff/ScholarShipGalaWeekendSocialKickoff.vue";
import BlackMen100 from "../pages/BlackMen100/BlackMen100.vue";

import AnnualFatherhoodExpo from "../pages/AnnualFatherhoodExpo/AnnualFatherhoodExpo.vue";

import VeteransCarShow from "../pages/VeteransCarShow/VeteransCarShow.vue";

import MtZion from "../pages/MtZion/MtZion.vue";

import JuneteenthUNATION from "../pages/2ndJuneteenthUNATION/2ndJuneteenthUNATION.vue";

import Summer614 from "../pages/Summer614/Summer614.vue";

import BLACKFATHERDAUGHTERDANCEATCOSI2nd from "../pages/2ndBLACKFATHERDAUGHTERDANCEATCOSI/2ndBLACKFATHERDAUGHTERDANCEATCOSI.vue";

import BubblesBags from "../pages/Bubbles&Bags/Bubbles&Bags.vue";

import GrandPolemarchsInvitationalGolfOuting from "../pages/GrandPolemarchsInvitationalGolfOuting/GrandPolemarchsInvitationalGolfOuting.vue";

import ColumbusBlackProfessionalNetworkingSocial from "../pages/ColumbusBlackProfessionalNetworkingSocial/ColumbusBlackProfessionalNetworkingSocial.vue";

import NAAIA from "../pages/NAAIA/NAAIA.vue";

import ColumbusUrbanLeagueEmpowermentDay from "../pages/ColumbusUrbanLeagueEmpowermentDay/ColumbusUrbanLeagueEmpowermentDay.vue";

import ColumbusUrbanLeagueEmpowermentDayWidget from "../pages/ColumbusUrbanLeagueEmpowermentDayWidget/ColumbusUrbanLeagueEmpowermentDayWidget.vue";

import Metro47thFoundersDayAfterParty from "../pages/Metro47thFoundersDayAfterParty/Metro47thFoundersDayAfterParty.vue";

import Metro47thFoundersDayDigitalSouvenirBooklet from "../pages/Metro47thFoundersDayDigitalSouvenirBooklet/Metro47thFoundersDayDigitalSouvenirBooklet.vue";

import Metro47thFoundersDayVendorRegistration from "../pages/Metro47thFoundersDayVendorRegistration/Metro47thFoundersDayVendorRegistration.vue";

import Metro47thFoundersDay from "../pages/Metro47thFoundersDay/Metro47thFoundersDay.vue";

import Metro47thFoundersDayMainCollection from "../pages/Metro47thFoundersDayMainCollection/Metro47thFoundersDayMainCollection.vue";

import BOAO from "../pages/BOAO/BOAO.vue";

import ATribeCalledCAPS from "../pages/ATribeCalledCAPS/ATribeCalledCAPS.vue";

import TheLeadershipLoungeReception from "../pages/TheLeadershipLoungeReception/TheLeadershipLoungeReception.vue";

import BoujeeBrunch from "../pages/BoujeeBrunch/BoujeeBrunch.vue";
import Success from "../pages/Success/Success.vue";
import Error from "../pages/Error/Error.vue";

import UNCFLaunchPartyCelebration from "../pages/UNCFLaunchPartyCelebration/UNCFLaunchPartyCelebration.vue";

import HBCUAllStarGameExtravaganza from "../pages/HBCUAllStarGameExtravaganza/HBCUAllStarGameExtravaganza.vue";

import BEN from "../pages/BEN/BEN.vue";
import SurveyForm from "../pages/BEN/surveyform.vue";
import BENAtlantaSurvey from "../pages/BEN/BENAtlantaSurvey.vue";

import BENExecutiveDinner from "../pages/BENExecutiveDinner/BENExecutiveDinner.vue";

import BENNationalMuseumofAfricanAmericanMusicTour from "../pages/BENNationalMuseumofAfricanAmericanMusicTour/BENNationalMuseumofAfricanAmericanMusicTour.vue";

import BENMusicCityShowcase from "../pages/BENMusicCityShowcase/BENMusicCityShowcase.vue";

import BENBNA2SiteTourLeadershipPanel from "../pages/BENBNA2SiteTourLeadershipPanel/BENBNA2SiteTourLeadershipPanel.vue";

import BENBlackWellnessRally from "../pages/BENBlackWellnessRally/BENBlackWellnessRally.vue";

import BENWelcomeToNashvilleMixer from "../pages/BENWelcomeToNashvilleMixer/BENWelcomeToNashvilleMixer.vue";

import BENCollection from "../pages/BENCollection/BENCollection.vue";

import SWAVisa from "../pages/SWAVisa/SWAVisa.vue";
import SWAVisaFAQs from "../pages/SWAVisa/SWAVisaFAQs.vue";
import SWAVisaContact from "../pages/SWAVisa/SWAVisaContact.vue";
import SWAVisaCardVerify from "../pages/SWAVisa/SWAVisaCardVerify/SWAVisaCardVerify.vue";
import SWAVisaCardVerifyFailed from "../pages/SWAVisa/SWAVisaCardVerifyFailed/SWAVisaCardVerifyFailed.vue";
import SWAVisaWaitlistSuccess from "../pages/SWAVisa/SWAVisaWaitlistSuccess/SWAVisaWaitlistSuccess.vue";

import BENBlackIsEntrepreneurialHBCUEdition from "../pages/BENBlackIsEntrepreneurialHBCUEdition/BENBlackIsEntrepreneurialHBCUEdition.vue";

import BENBlackBizBusTour from "../pages/BENBlackBizBusTour/BENBlackBizBusTour.vue";

import BENKingCenterCommunityService from "../pages/BENKingCenterCommunityService/BENKingCenterCommunityService.vue";

import BENBlaqueBusinessExpo from "../pages/BENBlaqueBusinessExpo/BENBlaqueBusinessExpo.vue";

import BENAtlantaCollection from "../pages/BENAtlantaCollection/BENAtlantaCollection.vue";

import BENRetroRenaissanceSoiree from "../pages/BENRetroRenaissanceSoiree/BENRetroRenaissanceSoiree.vue";

import AnnualTeachersBall from "../pages/AnnualTeachersBall/AnnualTeachersBall.vue";

import BOAO2nd from "../pages/2ndBOAO/2ndBOAO.vue";

import LouisianaMBDATrainingConferencePoweringYourProgress from "../pages/LouisianaMBDATrainingConferencePoweringYourProgress/LouisianaMBDATrainingConferencePoweringYourProgress.vue";

import TheLeadershipLounge from "../pages/TheLeadershipLounge/TheLeadershipLounge.vue";

import FoodTruckFestivalWaitlist from "../pages/FoodTruckFestivalWaitlist/FoodTruckFestivalWaitlist.vue";

import GammaEpsilonBiAnnualRetreat from "../pages/GammaEpsilonBiAnnualRetreat/GammaEpsilonBiAnnualRetreat.vue";

import GammaEpsilonGolfTournament from "../pages/GammaEpsilonGolfTournament/GammaEpsilonGolfTournament.vue";

import GammaEspilonBiAnnualRetreatCookout from "../pages/GammaEspilonBiAnnualRetreatCookout/GammaEspilonBiAnnualRetreatCookout.vue";

import GammaEpsilonCollection from "../pages/GammaEpsilonCollection/GammaEpsilonCollection.vue";

import StateOfBlackMensHealth from "../pages/StateOfBlackMensHealth/StateOfBlackMensHealth.vue";

import BlackMenHealthYouAreWhatYouEatLivestream from "../pages/BlackMenHealthYouAreWhatYouEatLivestream/BlackMenHealthYouAreWhatYouEatLivestream.vue";

import BlackMenHealthFiretalkTVLivestream from "../pages/BlackMenHealthFiretalkTVLivestream/BlackMenHealthFiretalkTVLivestream.vue";

import BlackMenHealthFunctionalMobilityForMen from "../pages/BlackMenHealthFunctionalMobilityForMen/BlackMenHealthFunctionalMobilityForMen.vue";

import BlackMenHealthMensHealthBrunch from "../pages/BlackMenHealthMensHealthBrunch/BlackMenHealthMensHealthBrunch.vue";

import Summer6142nd from "../pages/2ndSummer614/2ndSummer614.vue";

import Summer6142ndWidget from "../pages/2ndSummer614Widget/2ndSummer614Widget.vue";

import BlackMensHealthCollection from "../pages/BlackMensHealthCollection/BlackMensHealthCollection.vue";

import JazzBrunch2nd from "../pages/2ndBlackMen100/2ndJazzBrunch/2ndJazzBrunch.vue";
import ScholarshipGala2nd from "../pages/2ndBlackMen100/2ndScholarshipGala/2ndScholarshipGala.vue";
import BlackMen1002nd from "../pages/2ndBlackMen100/2ndBlackMen100.vue";
import SouvenirProgramBooklet from "../pages/2ndBlackMen100/SouvenirProgramBooklet/SouvenirProgramBooklet.vue";
import ScholarshipGala2ndDonation from "../pages/2ndBlackMen100/2ndScholarshipGalaDonation/2ndScholarshipGalaDonation.vue";
import BlackMen1002ndDonationWidget from "../pages/2ndBlackMen100/2ndBlackMen100DonationWidget/2ndBlackMen100DonationWidget.vue";
import ScholarshipGala2ndSilentAuction from "../pages/2ndBlackMen100/2ndScholarshipGalaSilentAuction/2ndScholarshipGalaSilentAuction.vue";

// import DeltaRideForTheFightComingSoon from "../pages/DeltaRideForTheFight/DeltaRideForTheFightComingSoon.vue";

import DeltaRideForTheFight from "../pages/DeltaRideForTheFight/DeltaRideForTheFight.vue";

import DeltaRideForTheFightDonation from "../pages/DeltaRideForTheFight/DeltaRideForTheFightDonation/DeltaRideForTheFightDonation.vue";

import DeltaRideForTheFightTShirt from "../pages/DeltaRideForTheFight/DeltaRideForTheFightTShirt.vue";

import MIDWESTCIGARSOCIAL from "../pages/MIDWESTCIGARSOCIAL/MIDWESTCIGARSOCIAL.vue";

import SkegeeRBSoiree from "../pages/SkegeeRBSoiree/SkegeeRBSoiree.vue";

import BandoReunion from "../pages/BandoReunion/BandoReunion.vue";
import BandoReunionDonation from "../pages/BandoReunion/BandoReunionDonation/BandoReunionDonation.vue";
import BandoReunionSouvenirProgram from "../pages/BandoReunion/BandoReunionSouvenirProgram/BandoReunionSouvenirProgram.vue";
import BandoReunionCollection from "../pages/BandoReunion/BandoReunionCollection.vue";

import TheBlueTigerBisonBoogie from "../pages/TheBlueTigerBisonBoogie/TheBlueTigerBisonBoogie.vue";

// import ColumbusBlackcomCountdownToElectionDay from "../pages/ColumbusBlackcomCountdownToElectionDay/ColumbusBlackcomCountdownToElectionDay.vue";
// import ColumbusBlackcomCountdownToElectionDay2nd from "../pages/2ndColumbusBlackcomCountdownToElectionDay/2ndColumbusBlackcomCountdownToElectionDay.vue";
import ColumbusBlackcomCountdownToElectionDay3rd from "../pages/3rdColumbusBlackcomCountdownToElectionDay/3rdColumbusBlackcomCountdownToElectionDay.vue";

import PricelineVisa from "../pages/PricelineVisa/PricelineVisa.vue";
import PricelineVisaFAQs from "../pages/PricelineVisa/PricelineVisaFAQs.vue";
import PricelineVisaTravelGuide from "../pages/PricelineVisa/PricelineVisaTravelGuide.vue";
// import SWAVisaContact from "../pages/SWAVisa/SWAVisaContact.vue";
// import SWAVisaCardVerify from "../pages/SWAVisa/SWAVisaCardVerify/SWAVisaCardVerify.vue";
// import SWAVisaCardVerifyFailed from "../pages/SWAVisa/SWAVisaCardVerifyFailed/SWAVisaCardVerifyFailed.vue";
// import SWAVisaWaitlistSuccess from "../pages/SWAVisa/SWAVisaWaitlistSuccess/SWAVisaWaitlistSuccess.vue";

// import BreezeVISA from "../pages/BreezeVISA/BreezeVISA.vue";
import BreezeVISAVenue from "../pages/BreezeVISA/BreezeVISAVenueiframe.vue";
import BreezeVISAContact from "../pages/BreezeVISA/BreezeVISAContactiframe.vue";
import BreezeVISASofarSounds from "../pages/BreezeVISA/BreezeVISASofarSoundsiframe.vue";
import VisaCardVerifyFailed from "../pages/BreezeVISA/VisaCardVerifyFailed/VisaCardVerifyFailed.vue";

import BreezeVISA1 from "../pages/BreezeVISA/breezeiframe.vue";

import MobilityinColorCocktailReception from "../pages/MobilityinColor/MobilityinColorCocktailReception/MobilityinColorCocktailReception.vue";
import MobilityinColorCocktailReceptionMainEvent from "../pages/MobilityinColor/MobilityinColorCocktailReceptionMainEvent/MobilityinColorCocktailReceptionMainEvent.vue";

import TuxedosandTennisShoesAnnualGala from "../pages/TuxedosandTennisShoesAnnualGala/TuxedosandTennisShoesAnnualGala.vue";

import FiveReasonsWhyYouShouldUseMYLE from "../pages/FiveReasonsWhyYouShouldUseMYLE/FiveReasonsWhyYouShouldUseMYLE.vue";

import TheSkegeeBallParlaySoiree100thHomecomingEdition from "../pages/TheSkegeeBallParlaySoiree100thHomecomingEdition/TheSkegeeBallParlaySoiree100thHomecomingEdition.vue";

import MIDWESTCIGARSOCIALPRESENTS2FAST2FURIOUS from "../pages/MIDWESTCIGARSOCIALPRESENTS2FAST2FURIOUS/MIDWESTCIGARSOCIALPRESENTS2FAST2FURIOUS.vue";

import AlbertsonsVISADallas from "../pages/AlbertsonsVISA/AlbertsonsVISADallas/AlbertsonsVISADallas.vue";

import AlbertsonsVISALA from "../pages/AlbertsonsVISA/AlbertsonsVISALA/AlbertsonsVISALA.vue";

import AlbertsonsVISAPhilly from "../pages/AlbertsonsVISA/AlbertsonsVISAPhilly/AlbertsonsVISAPhilly.vue";

import TheUltimateTailgateandCommUNITYExperience from "../pages/TheUltimateTailgateandCommUNITYExperience/TheUltimateTailgateandCommUNITYExperience.vue";

import HBCUNYClassic from "../pages/HBCUNYClassic/HBCUNYClassic.vue";

import BigBlueAndHUHomecomingPostGameSoiree from "../pages/BigBlueAndHUHomecomingPostGameSoiree/BigBlueAndHUHomecomingPostGameSoiree.vue";

import The2024ElectionDebateWatch from "../pages/The2024ElectionDebateWatch/The2024ElectionDebateWatch.vue";

import RealHUVIPAfterparty from "../pages/RealHUVIPAfterparty/RealHUVIPAfterparty.vue";

import NEWYEAREVEBOWTIESDIAMONDSEXPERIENCE from "../pages/2024NEWYEAREVEBOWTIESDIAMONDSEXPERIENCE/2024NEWYEAREVEBOWTIESDIAMONDSEXPERIENCE.vue";

import NavyFederalCreditMemberinGA from "../pages/NavyFederalCreditUnionVeteransDay2024DiningEvents/NavyFederalCreditMemberinGA/NavyFederalCreditMemberinGA.vue";
import NavyFederalCreditMemberinGAFAQs from "../pages/NavyFederalCreditUnionVeteransDay2024DiningEvents/NavyFederalCreditMemberinGA/NavyFederalCreditMemberinGAFAQs.vue";

// import IndexSeven from '../pages/indices/IndexSeven.vue';
// import LoginPageOne from '../pages/auth/LoginPageOne.vue';
// import LoginPageTwo from '../pages/auth/LoginPageTwo.vue';
// import SignupPageOne from '../pages/auth/SignupPageOne.vue';
// import SignupPageTwo from '../pages/auth/SignupPageTwo.vue';
// import PasswordRest from '../pages/auth/PasswordReset.vue';
// import ChangePassword from '../pages/auth/ChangePassword.vue';
// import DownloadPage from '../pages/utilities/DownloadPage';
// import ReviewPage from '../pages/utilities/ReviewPage.vue';
// import FaqPage from "../pages/utilities/FaqPage.vue";
import Page404 from "../pages/utilities/Page404.vue";
// import TheLeadershipLoungeReception from "../pages/TheLeadershipLoungeReception/TheLeadershipLoungeReception.vue";
// import ComingsoonPage from '../pages/utilities/ComingsoonPage.vue';
// import ThankyouPage from '../pages/ThankyouPage.vue';
// import TeamPage from '../pages/team/TeamPage.vue';
// import TeamOne from '../pages/team/TeamOne.vue';
// import BlogGrid from '../pages/blogs/BlogGrid.vue';
// import BlogLeftSidebar from '../pages/blogs/BlogLeftSidebar.vue';
// import DetailsRightSidebar from '../pages/blogs/DetailsRightSidebar.vue';

Vue.use(VueRouter);
Vue.use(VueMeta);

const routes = [
  // {
  //   path: '/',
  //   name: 'IndexOne',
  //   component: IndexOne,
  //   meta: { title: 'Home 1 - Appco - App Landing Page Template' }
  // },
  // {
  //   path: '/index-2',
  //   name: 'IndexTwo',
  //   component: IndexTwo,
  //   meta: { title: 'Home 2 - Appco - App Landing Page Template' }
  // },
  // {
  //   path: '/index-3',
  //   name: 'IndexThree',
  //   component: IndexThree,
  //   meta: { title: 'Home 3 - Appco - App Landing Page Template' }
  // },
  // {

  //   path: '/index-4',
  //   name: 'IndexFour',
  //   component: IndexFour,
  //   meta: { title: 'Home 4 - Appco - App Landing Page Template' }
  // },
  {
    path: "/",
    name: "IndexFive",
    component: IndexFive,
    meta: { title: "MYLE - Make Your Life Entertaining" },
  },
  {
    path: "/extramyle",
    name: "ExtraMyle",
    component: ExtraMyle,
    meta: { title: "MYLE - Extra Myle" },
  },

  {
    path: "/road-trip-blog",
    name: "Blog",
    component: AtlantaBlog,
    meta: { title: "MYLE - Road Trip Blog" },
  },

  // {
  //   path: "/new-years-eve-2021-at-grand-bohemian-orlando",
  //   name: "NewYear",
  //   component: NewYear,
  //   meta: { title: "MYLE - New Years Eve 2021 at Grand Bohemian Orlando" },
  // },

  // {
  //   path: "/new-years-eve-2021-@-stadium",
  //   name: "StadiumNewYearEvent",
  //   component: StadiumNewYearEvent,
  //   meta: { title: "MYLE - New Years Eve 2021 @ Stadium" },
  // },

  // {
  //   path: "/new-years-eve-2022-@-stadium",
  //   name: "StadiumNewYearEvent",
  //   component: StadiumNewYearEvent,
  //   meta: { title: "MYLE - New Years Eve 2022 @ Stadium" },
  // },

  {
    path: "/success",
    name: "success",
    component: Success,
  },
  {
    path: "/cancel",
    name: "error",
    component: Error,
  },
  {
    path: "/comedy-show-and-live-music-with-dinner-included-a-fathers-day-and-juneteenth-celebration",
    name: "ComedyShow",
    component: ComedyShow,
    meta: {
      title:
        "MYLE - Comedy Show and live music with Dinner Included a Fathers Day and Juneteenth Celebration",
    },
  },

  {
    path: "/bohemian-brunch-picnic-package-for-two",
    name: "BohemianBrunch",
    component: BohemianBrunch,
    meta: { title: "MYLE - Bohemian Brunch Picnic Package for Two" },
  },

  {
    path: "/bohemian-al-fresco-lunch-picnic-package-for-two",
    name: "BohemianLunch",
    component: BohemianLunch,
    meta: { title: "MYLE - Bohemian Al Fresco Lunch Picnic for Two" },
  },

  {
    path: "/slow-jam-social",
    name: "SlowJamSocial",
    component: SlowJamSocial,
    meta: { title: "MYLE - Slow Jam Social" },
  },

  {
    path: "/1st-annual-sneaker-jam",
    name: "SneakerJam",
    component: SneakerJam,
    meta: { title: "MYLE - 1st Annual Sneaker Jam" },
  },

  {
    path: "/columbus-oh-alumni-chapter-of-black-white-scholarship-fundraiser",
    name: "AlumniChapter",
    component: AlumniChapter,
    meta: {
      title:
        "MYLE - Columbus (OH) Alumni Chapter of ΚΑΨ- Black & White Scholarship Fundraiser",
    },
  },

  // {
  //   path: "/level-up-gold-carpet-experience",
  //   name: "LevelUpGoldCarpetExperience",
  //   component: GoldCarpetExperience,
  //   meta: {
  //     title: "MYLE - Level Up Gold Carpet Experience",
  //   },
  // },

  {
    path: "/jazz-on-the-lawn",
    name: "JazzOnTheLawn",
    component: JazzOnTheLawn,
    meta: {
      title: "MYLE - Jazz On The Lawn",
    },
  },

  {
    path: "/wisdom-seekers-womens-conference",
    name: "WisdomSeekersConference",
    component: WisdomSeekersConference,
    meta: {
      title: "MYLE - Wisdom Seekers Women's Conference",
    },
  },

  {
    path: "/the-26th-annual-kappa-wine-sip",
    name: "AnnualKappa",
    component: AnnualKappa,
    meta: {
      title: "MYLE - The 26th Annual Kappa Wine Sip",
    },
  },

  {
    path: "/all-access-pass-juneteenth-407-weekend",
    name: "ALLACCESSPASS",
    component: ALLACCESSPASS,
    meta: {
      title: "MYLE - ALL ACCESS PASS - Juneteenth 407 Weekend",
    },
  },

  {
    path: "/black-friday-orlando-scavenger-hunt-city-skip-day",
    name: "BlackFridayOrlando",
    component: BlackFridayOrlando,
    meta: {
      title: "MYLE - Black Friday Orlando: Scavenger Hunt + City Skip Day",
    },
  },

  {
    path: "/black-wall-street-juneteenth-block-party",
    name: "BlackWallStreet",
    component: BlackWallStreet,
    meta: {
      title: "MYLE - Black Wall Street Juneteenth Block Party",
    },
  },

  {
    path: "/retro-happy-hour-black-in-the-day",
    name: "RetroHappyHour",
    component: RetroHappyHour,
    meta: {
      title: "MYLE - Retro Happy Hour: Black in the Day",
    },
  },

  // {
  //   path: "/for-the-kings-juneteenth-father-day-brunch",
  //   name: "TheArtofBrunch",
  //   component: TheArtofBrunch,
  //   meta: {
  //     title: "MYLE - For The Kings | Juneteenth + Father's Day Brunch",
  //   },
  // },

  {
    path: "/juneteenth-407-weekend-2022",
    name: "juneteenth",
    component: juneteenth,
    meta: {
      title: "MYLE - Juneteenth 407 Weekend",
    },
  },

  {
    path: "/boujee-ball-the-inaugural-event",
    name: "boujeeBall",
    component: boujeeBall,
    meta: {
      title: "MYLE - Boujee Ball - The Inaugural Event",
    },
  },

  {
    path: "/dinner-and-dancing",
    name: "DinnerAndDancing",
    component: DinnerAndDancing,
    meta: {
      title: "MYLE - Dinner And Dancing",
    },
  },

  {
    path: "/mozart-monster-escape-room",
    name: "MozartMonsterEscapeRoom",
    component: MozartMonsterEscapeRoom,
    meta: {
      title: "MYLE - Mozart’s Monster Escape Room",
    },
  },

  {
    path: "/1865-comedy-festival",
    name: "ComedyFestival",
    component: ComedyFestival,
    meta: {
      title: "MYLE - 1865 Comedy Festival",
    },
  },

  {
    path: "/atlanta-launch",
    name: "AtlantaLaunch",
    component: AtlantaLaunch,
    meta: {
      title: "MYLE - Atlanta Launch",
    },
  },

  {
    path: "/cigars-and-sundresses",
    name: "KappaCigarsAndSundresses",
    component: KappaCigarsAndSundresses,
    meta: {
      title:
        "MYLE - Cigars & Sundresses Presented by Columbus (OH) Alumni Chapter of Kappa Alpha Psi",
    },
  },

  {
    path: "/becoming-bankable-master-class",
    name: "BecomingBankableMasterClass",
    component: BecomingBankableMasterClass,
    meta: {
      title: "MYLE - Becoming Bankable Master Class",
    },
  },

  {
    path: "/chuck-tux-virgo-bash",
    name: "ChuckTuxVirgoBash",
    component: ChuckTuxVirgoBash,
    meta: {
      title: "MYLE - Sneaker Jam: Chuck & Tux Virgo Bash",
    },
  },

  {
    path: "/legendary-cultural-extravaganza-fashion-show-fundraiser",
    name: "LegendaryCultural",
    component: LegendaryCultural,
    meta: {
      title: "MYLE - Legendary Cultural Extravaganza/Fashion Show Fundraiser",
    },
  },

  {
    path: "/comedy-show-with-dinner-included",
    name: "ComedyShowWithDinner",
    component: ComedyShowWithDinner,
    meta: {
      title: "MYLE - Comedy Show with Dinner Included",
    },
  },

  {
    path: "/killermikeday-playlist-kickback-bge-studios",
    name: "KillerMikeDay",
    component: KillerMikeDay,
    meta: {
      title: "MYLE - #KillerMikeDay Playlist Kickback @ BGE Studios",
    },
  },

  {
    path: "/jack-leFlore-memorial-golf-tournament",
    name: "JackLeFloreMemorialGolfTournament",
    component: JackLeFloreMemorialGolfTournament,
    meta: {
      title: "MYLE - Jack LeFlore Memorial Golf Tournament",
    },
  },

  {
    path: "/geaa-bi-annual-kappa-retreat",
    name: "ANNUALKAPPARETREAT",
    component: ANNUALKAPPARETREAT,
    meta: {
      title: "MYLE - GEAA BI-ANNUAL KAPPA RETREAT",
    },
  },

  {
    path: "/tennessee",
    name: "TennesseeBlogPost",
    component: tennesseeBlogPost,
    meta: {
      title: "MYLE - Tennessee",
    },
  },

  {
    path: "/atlanta",
    name: "AtlantaBlogPost",
    component: atlantaBlogPost,
    meta: {
      title: "MYLE - Atlanta",
    },
  },

  {
    path: "/win500",
    name: "Win500",
    component: Win500,
    meta: {
      title: "MYLE - Win $500",
    },
  },

  {
    path: "/winning-terms",
    name: "winningRules",
    component: winningRules,
    meta: {
      title: "MYLE - Winning $500 Terms & Conditions",
    },
  },

  {
    path: "/columbus-cincinnati",
    name: "ohioBlogPost",
    component: ohioBlogPost,
    meta: {
      title: "MYLE - Columbus and Cincinnati",
    },
  },

  {
    path: "/kentucky",
    name: "kentuckyBlogPost",
    component: kentuckyBlogPost,
    meta: {
      title: "MYLE - Kentucky",
    },
  },

  {
    path: "/atlanta-rsvp",
    name: "AtlantaRSVP",
    component: AtlantaRSVP,
    meta: {
      title: "MYLE - Atlanta RSVP",
    },
  },

  {
    path: "/myle-mixer",
    name: "MYLEMIXER",
    component: MYLEMIXERReg,
    meta: {
      title: "MYLE - MYLE MIXER",
    },
  },

  {
    path: "/terms",
    name: "TermsAndConditions",
    component: myleTerms, // TermsAndConditions,
    meta: { title: "MYLE - Terms & Conditions" },
  },

  {
    path: "/change-management",
    name: "ChangeManagement",
    component: ChangeManagement,
    meta: { title: "MYLE - Change Management" },
  },
  {
    path: "/data-integrity-governance-policy",
    name: "DataIntegrityandGovernancePolicy",
    component: DataIntegrityandGovernancePolicy,
    meta: { title: "MYLE - Data Integrity and Governance Policy" },
  },
  {
    path: "/data-management-strategy",
    name: "DataManagementStrategy",
    component: DataManagementStrategy,
    meta: { title: "MYLE - Data Management Strategy" },
  },
  {
    path: "/incident-response-plan",
    name: "IncidentResponsePlan",
    component: IncidentResponsePlan,
    meta: { title: "MYLE - Incident Response Plan" },
  },

  {
    path: "/office-hours",
    name: "officeHours",
    component: TrainingComponent,
    meta: { title: "MYLE - Training" },
  },

  {
    path: "/privacy",
    name: "privacyPolicy",
    component: privacyPolicy,
    meta: { title: "MYLE - Privacy Policy" },
  },

  {
    path: "/cookies",
    name: "cookies",
    component: cookies,
    meta: { title: "MYLE - Cookies Policy" },
  },

  {
    path: "/ambassador",
    name: "myleAmbassador",
    component: myleAmbassador,
    meta: { title: "MYLE - Ambassador" },
  },

  {
    path: "/Business",
    name: "myleBusiness",
    component: myleBusiness,
    meta: { title: "MYLE - Business" },
  },

  {
    path: "/Affiliate",
    name: "myleAffiliate",
    component: myleAffilate,
    meta: { title: "MYLE - Affiliate" },
  },

  {
    path: "/profit-partner",
    name: "ProfitPartner",
    component: ProfitPartner,
    meta: { title: "MYLE - Profit Partner" },
  },

  {
    path: "/Orlando-Launch",
    name: "orlando",
    component: orlando,
    meta: { title: "Orlando Launch" },
  },

  {
    path: "/ticketing-faq",
    name: "TicketingFAQ",
    component: TicketingFAQ,
    meta: { title: "Ticketing FAQ" },
  },

  {
    path: "/classic-for-columbus",
    name: "MYLEVipExperience",
    component: MYLEVipExperience,
    meta: { title: "Classic For Columbus" },
  },

  {
    path: "/fearless-fund-vc-summit-education-day",
    name: "VCFestEduDay",
    component: VCFestEduDay,
    meta: {
      title: "MYLE | Fearless Fund VC Summit - Education Day (In-Person)",
    },
  },

  {
    path: "/fearless-fund-vc-summit-pitch-day",
    name: "VCFestPitchDay",
    component: VCFestPitchDay,
    meta: {
      title: "MYLE | Fearless Fund VC Summit - Pitch Day (Viewing)",
    },
  },

  {
    path: "/fearless-fund-vc-summit",
    name: "VCFestDays",
    component: VCFestDays,
    meta: {
      title: "MYLE | Fearless Fund VC Summit",
    },
  },

  {
    path: "/saving-our-childre-confronting-the-mental-health-crisis-k-12-education",
    name: "SavingOurChildern",
    component: SavingOurChildern,
    meta: {
      title:
        "MYLE | “SAVING OUR CHILDREN”: CONFRONTING THE MENTAL HEALTH CRISIS K-12 EDUCATION",
    },
  },

  // {
  //   path: "/the-big-golf-weekend",
  //   name: "TheBigGolfWeekend",
  //   component: TheBigGolfWeekend,
  //   meta: {
  //     title: "MYLE | The Big Golf Weekend",
  //   },
  // },

  // {
  //   path: "/the-big-golf-weekend",
  //   name: "TheBigGolfWeekend2nd",
  //   component: TheBigGolfWeekend2nd,
  //   meta: {
  //     title: "MYLE | The Big Golf Weekend",
  //   },
  // },

  {
    path: "/the-big-golf-weekend",
    name: "TheBigGolfWeekend3rd",
    component: TheBigGolfWeekend3rd,
    meta: {
      title: "MYLE | The Big Golf Weekend",
    },
  },

  {
    path: "/entrepreneurs-gala",
    name: "EntrepreneursGala",
    component: EntrepreneursGala,
    meta: {
      title: "MYLE | Entrepreneurs Gala",
    },
  },

  {
    path: "/4th-annual-stemmys-awards",
    name: "STEMmys",
    component: STEMmys,
    meta: {
      title: "MYLE | 4th Annual STEMmys Awards",
    },
  },

  {
    path: "/myle-ticketing",
    name: "MYLEExposure",
    component: MYLEExposure,
    meta: {
      title: "MYLE + Ticketing",
    },
  },

  {
    path: "/community-hall-town",
    name: "CommunityHallTown",
    component: CommunityHallTown,
    meta: {
      title: "MYLE | Community Hall Town",
    },
  },

  {
    path: "/the-kappa-black-white-2nd-annual-scholarship-fundraiser",
    name: "TheKappaBlackWhite2ndAnnualScholarshipFundraiser",
    component: TheKappaBlackWhite2ndAnnualScholarshipFundraiser,
    meta: {
      title: "MYLE | The Kappa Black White 2nd Annual Scholarship Fundraiser",
    },
  },

  {
    path: "/2nd-black-food-truck-festival",
    name: "BlackFoodTruckFestival2nd",
    component: BlackFoodTruckFestival2nd,
    meta: {
      title: "MYLE | Black Food Truck Festival",
    },
  },

  {
    path: "/2nd-black-food-truck-festival-day-1",
    name: "BlackFoodTruckFestivalDay12nd",
    component: BlackFoodTruckFestivalDay12nd,
    meta: {
      title: "MYLE | Black Food Truck Festival Day 1",
    },
  },

  {
    path: "/2nd-black-food-truck-festival-day-2",
    name: "BlackFoodTruckFestivalDay22nd",
    component: BlackFoodTruckFestivalDay22nd,
    meta: {
      title: "MYLE | Black Food Truck Festival Day 2",
    },
  },

  {
    path: "/2nd-black-food-truck-festival-two-day-festival-package",
    name: "BlackFoodTruckFestivalTwoDaysBundle2nd",
    component: BlackFoodTruckFestivalTwoDaysBundle2nd,
    meta: {
      title: "MYLE | Black Food Truck Festival Two Day Festival Package",
    },
  },

  {
    path: "/58th-north-central-region-conference",
    name: "NorthCentralRegionConference",
    component: NorthCentralRegionConference,
    meta: {
      title: "MYLE | 58th North Central Region Conference",
    },
  },

  {
    path: "/csu-greek-stroll-off",
    name: "CSUGreekStrollOff",
    component: CSUGreekStrollOff,
    meta: {
      title: "MYLE | CSU Greek Stroll Off",
    },
  },

  {
    path: "/csu-greek-stroll-off-greeks-only",
    name: "CSUGreekStrollOffGreeksOnly",
    component: CSUGreekStrollOffGreeksOnly,
    meta: {
      title: "MYLE | CSU Greek Stroll Off (Greeks Only)",
    },
  },

  {
    path: "/springfest-silent-headphone-party",
    name: "CSUSpringfestSilentHeadphoneParty",
    component: CSUSpringfestSilentHeadphoneParty,
    meta: {
      title: "MYLE | Springfest Silent Headphone Party",
    },
  },

  {
    path: "/central-state-spring-fest-2023",
    name: "CSUSpringFestMainPage",
    component: CSUSpringFestMainPage,
    meta: {
      title: "MYLE | Central State SpringFest 2023",
    },
  },

  {
    path: "/gamma-epsilon-chapter-of-kappa-alpha-psi-75th-anniversary",
    name: "GammaEpsilonchapterofKappaAlphaPsi",
    component: GammaEpsilonchapterofKappaAlphaPsi,
    meta: {
      title: "MYLE | Gamma Epsilon chapter of Kappa Alpha Psi 75th Anniversary",
    },
  },

  {
    path: "/the-27th-annual-kappa-wine-sip",
    name: "AnnualKappaWineSip2nd",
    component: AnnualKappaWineSip2nd,
    meta: {
      title: "MYLE - The 27th Annual Kappa Wine Sip",
    },
  },

  {
    path: "/black-food-truck-festival",
    name: "BlackFoodTruckFestival",
    component: BlackFoodTruckFestival,
    meta: {
      title: "MYLE | Black Food Truck Festival",
    },
  },

  {
    path: "/black-food-truck-festival-day-1",
    name: "BlackFoodTruckFestivalDay1",
    component: BlackFoodTruckFestivalDay1,
    meta: {
      title: "MYLE | Black Food Truck Festival Day 1",
    },
  },

  {
    path: "/black-food-truck-festival-day-2",
    name: "BlackFoodTruckFestivalDay2",
    component: BlackFoodTruckFestivalDay2,
    meta: {
      title: "MYLE | Black Food Truck Festival Day 2",
    },
  },

  {
    path: "/black-food-truck-festival-weekend-bundle",
    name: "BlackFoodTruckFestivalWeekendBundle",
    component: BlackFoodTruckFestivalWeekendBundle,
    meta: {
      title: "MYLE | Black Food Truck Festival Weekend Bundle",
    },
  },

  {
    path: "/black-food-truck-festival-two-days-bundle",
    name: "BlackFoodTruckFestivalTwoDaysBundle",
    component: BlackFoodTruckFestivalTwoDaysBundle,
    meta: {
      title: "MYLE | Black Food Truck Festival Two Days Bundle",
    },
  },

  {
    path: "/central-state-2022-homecoming-step-show",
    name: "CSUStepShow",
    component: CSUStepShow,
    meta: {
      title: "MYLE | Central State 2022 Homecoming Step Show",
    },
  },

  {
    path: "/central-state-2022-homecoming-step-show-greeks",
    name: "CSUStepShowGreeks",
    component: CSUStepShowGreeks,
    meta: {
      title: "MYLE | Central State 2022 Homecoming Step Show (Greeks)",
    },
  },

  {
    path: "/homecoming-concert-2022-feat-tink",
    name: "CSUConcert",
    component: CSUConcert,
    meta: {
      title: "MYLE | Homecoming Concert 2022 Feat. TINK",
    },
  },

  {
    path: "/central-state-2022-homecoming",
    name: "CSUConcertMainPage",
    component: CSUConcertMainPage,
    meta: {
      title: "MYLE | Central State 2022 Homecoming",
    },
  },

  {
    path: "/nupekoming-2022",
    name: "NupeKoming",
    component: NupeKoming,
    meta: {
      title: "MYLE | NupeKoming 2022",
    },
  },

  {
    path: "/tbayb-and-friends-all-black-affair",
    name: "ALLBLACKAFFAIR",
    component: ALLBLACKAFFAIR,
    meta: {
      title: "MYLE | TBayB and Friends - ALL BLACK AFFAIR",
    },
  },

  {
    path: "/the-skegee-bal-parlay-homecoming-soiree",
    name: "SkegeeBall",
    component: SkegeeBall,
    meta: {
      title: "MYLE | The Skegee Ball & Parlay Homecoming Soire",
    },
  },

  {
    path: "/their-bodies-their-choices",
    name: "TheirBodiesTheirChoices",
    component: TheirBodiesTheirChoices,
    meta: {
      title: "MYLE | Their Bodies, Their Choices... Let's VOTE!",
    },
  },

  {
    path: "/31st-annual-naaahp-conference",
    name: "NAAAHP",
    component: NAAAHP,
    meta: {
      title: "MYLE | 31st Annual NAAAHP Conference",
    },
  },

  {
    path: "/31st-annual-naaahp-conference",
    name: "NAAAHP",
    component: NAAAHP,
    meta: {
      title: "MYLE | 31st Annual NAAAHP Conference",
    },
  },

  {
    path: "/32nd-annual-naaahp-conference",
    name: "NAAAHP2nd",
    component: NAAAHP2nd,
    meta: {
      title: "MYLE | 32nd Annual NAAAHP Conference",
    },
  },

  {
    path: "/the-columbus-ceo-collective-deal-day",
    name: "DealDay",
    component: DealDay,
    meta: {
      title: "MYLE | The Columbus CEO Collective Deal Day",
    },
  },

  {
    path: "/all-access-party-packages",
    name: "AllAccessKrimsonPartyPackages",
    component: AllAccessKrimsonPartyPackages,
    meta: {
      title: "MYLE | All Access Krimson Party Packages",
    },
  },

  {
    path: "/family-outing",
    name: "FamilyOutingontheRiverwalk",
    component: FamilyOutingontheRiverwalk,
    meta: {
      title: "MYLE | Family Outing",
    },
  },

  {
    path: "/kreme-kulture-jam",
    name: "KremeKultureJam",
    component: KremeKultureJam,
    meta: {
      title: "MYLE | Kreme Kulture Jam",
    },
  },

  {
    path: "/the-kulmination-the-grand-polemarch-closed-ball",
    name: "KRIMSONKlimaxPartyinBlack",
    component: KRIMSONKlimaxPartyinBlack,
    meta: {
      title: "MYLE | The Kulmination – The Grand Polemarch’s Closed Ball",
    },
  },

  {
    path: "/tampa-kulture-bus-tour",
    name: "KrimsonKultureTour",
    component: KrimsonKultureTour,
    meta: {
      title: "MYLE | Tampa Kulture Bus Tour",
    },
  },

  {
    path: "/krimson-takeover-party",
    name: "KrimsonTakeoverParty",
    component: KrimsonTakeoverParty,
    meta: {
      title: "MYLE | Krimson Takeover Party",
    },
  },

  {
    path: "/kappa-conclave-2023",
    name: "KappaConclaveCollection",
    component: KappaConclaveCollection,
    meta: {
      title: "MYLE | Kappa Conclave",
    },
  },

  {
    path: "/kappa-conclave-registered-2023",
    name: "KappaConclaveRegistered2023",
    component: KappaConclaveRegistered2023,
    meta: {
      title: "MYLE | Kappa Conclave Registered 2023",
    },
  },

  {
    path: "/aacccf-business-after-hours-holiday-edition",
    name: "ACCCCF",
    component: ACCCCF,
    meta: {
      title: "MYLE | AACCCF Business After Hours - Holiday Edition",
    },
  },

  {
    path: "/black-father-daughter-dance-at-cosi-2022",
    name: "BLACKFATHERDAUGHTERDANCEATCOSI",
    component: BLACKFATHERDAUGHTERDANCEATCOSI,
    meta: {
      title: "MYLE | BLACK FATHER DAUGHTER DANCE AT COSI",
    },
  },

  {
    path: "/investing-in-our-future-scholarship-gala",
    name: "InvestinginourFutureScholarshipGala",
    component: InvestinginourFutureScholarshipGala,
    meta: {
      title: "MYLE | Investing in our Future Scholarship Gala",
    },
  },

  {
    path: "/investing-in-our-future-2nd-annual-scholarship-gala",
    name: "InvestinginourFutureScholarshipGala2024",
    component: InvestinginourFutureScholarshipGala2024,
    meta: {
      title: "MYLE | Investing in our Future Scholarship Gala 2024",
    },
  },

  {
    path: "/investing-in-our-future-2nd-annual-scholarship-gala-widget",
    name: "InvestinginourFutureScholarshipGala2024Widget",
    component: InvestinginourFutureScholarshipGala2024Widget,
    meta: {
      title: "MYLE | Investing in our Future Scholarship Gala 2024 - Widget",
    },
  },

  {
    path: "/investing-in-our-future-2nd-annual-scholarship-gala-donations",
    name: "InvestinginourFutureScholarshipGala2024Donations",
    component: InvestinginourFutureScholarshipGala2024Donations,
    meta: {
      title: "MYLE | Investing in our Future Scholarship Gala 2024 - Donations",
    },
  },

  {
    path: "/the-official-osu-vs-uga-pre-game-day-party",
    name: "PreGameDayParty",
    component: PreGameDayParty,
    meta: {
      title: "MYLE | The Official OSU vs. UGA Pre-Game 'Day Party'",
    },
  },

  {
    path: "/the-official-osu-vs-uga-pre-game-night-party",
    name: "PreGameNightParty",
    component: PreGameNightParty,
    meta: {
      title: "MYLE | The Official OSU vs. UGA Pre-Game Night Party",
    },
  },

  {
    path: "/the-official-osu-vs-uga-pre-game-party",
    name: "OSUvsUGAPreGamePartyCollection",
    component: OSUvsUGAPreGamePartyCollection,
    meta: {
      title: "MYLE | The Official OSU vs. UGA Pre-Game Party",
    },
  },

  {
    path: "/play-date-presented-by-west-lakes-district-a-mainstreet-organization/:id?",
    name: "PlayDate",
    component: PlayDate,
    meta: {
      title:
        "MYLE | Play Date - Presented by West Lakes District - A Mainstreet Organization",
    },
  },

  {
    path: "/vip-all-access-pass",
    name: "VIPAllAccessPass",
    component: VIPAllAccessPass,
    meta: {
      title: "MYLE - VIP All Access Pass",
    },
  },

  {
    path: "/black-friday-scavenger-hunt",
    name: "BlackFridayScavengerHunt",
    component: BlackFridayScavengerHunt,
    meta: {
      title: "MYLE - Black Friday Scavenger Hunt",
    },
  },

  {
    path: "/better-together-juneteenth-mixer",
    name: "BetterTogetherJuneteenthMixer",
    component: BetterTogetherJuneteenthMixer,
    meta: {
      title: "MYLE - Better Together Juneteenth Mixer",
    },
  },

  {
    path: "/better-together-juneteenth-mixer-discounted",
    name: "BetterTogetherJuneteenthMixerDiscounted",
    component: BetterTogetherJuneteenthMixerDiscounted,
    meta: {
      title: "MYLE - Better Together Juneteenth Mixer - Discounted",
    },
  },

  {
    path: "/blk-yrd-juneteenth-block-party",
    name: "BLKYRDJuneteenthBlockParty",
    component: BLKYRDJuneteenthBlockParty,
    meta: {
      title: "MYLE - BLK YRD-Juneteenth Block Party",
    },
  },

  {
    path: "/blk-yrd-juneteenth-block-party-discounted",
    name: "BLKYRDJuneteenthBlockPartyDiscounted",
    component: BLKYRDJuneteenthBlockPartyDiscounted,
    meta: {
      title: "MYLE - BLK YRD-Juneteenth Block Party - Discounted",
    },
  },

  {
    path: "/the-anti-brunch",
    name: "TheAntiBrunch",
    component: TheAntiBrunch,
    meta: {
      title: "MYLE - The Anti Brunch",
    },
  },

  {
    path: "/juneteenth-407-weekend",
    name: "Juneteenth2nd",
    component: Juneteenth2nd,
    meta: {
      title: "MYLE - Juneteenth 407 Weekend",
    },
  },

  {
    path: "/boujee-ball",
    name: "boujeeBallTwo",
    component: boujeeBallTwo,
    meta: {
      title: "MYLE - Boujee Ball",
    },
  },

  {
    path: "/the-skegee-ball-parlay-homecoming-soiree-shifted",
    name: "The Skegee Ball & Parlay Homecoming Soiree - Shifted",
    component: TheSkegeeBallParlayHomecomingSoiree,
    meta: {
      title: "MYLE - The Skegee Ball & Parlay Homecoming Soiree - Shifted",
    },
  },

  {
    path: "/the-skegee-ball-parlay-homecoming-soiree",
    name: "The Skegee Ball & Parlay Homecoming Soiree",
    component: NewTheSkegeeBallParlayHomecomingSoiree,
    meta: {
      title: "MYLE - The Skegee Ball & Parlay Homecoming Soiree",
    },
  },

  {
    path: "/the-bison-rattler-boogie-at-opium-lounge",
    name: "BisonRattlerBoogieFriday",
    component: BisonRattlerBoogieFriday,
    meta: {
      title: "MYLE - The Bison Rattler Boogie @ Opium Lounge",
    },
  },

  {
    path: "/jazz-brunch-old",
    name: "JazzBrunch",
    component: JazzBrunch,
    meta: {
      title: "MYLE - Jazz Brunch",
    },
  },

  {
    path: "/scholarship-gala",
    name: "ScholarshipGala",
    component: ScholarshipGala,
    meta: {
      title: "MYLE - Scholarship Gala",
    },
  },

  {
    path: "/2024-scholarship-gala-weekend",
    name: "BlackMen1002nd",
    component: BlackMen1002nd,
    meta: {
      title: "MYLE - 2024 Scholarship Gala Weekend",
    },
  },

  {
    path: "/souvenir-program-booklet",
    name: "SouvenirProgramBooklet",
    component: SouvenirProgramBooklet,
    meta: {
      title: "MYLE - Souvenir Program Booklet",
    },
  },

  {
    path: "/scholarship-gala-donations",
    name: "ScholarshipGalaDonation",
    component: ScholarshipGalaDonation,
    meta: {
      title: "MYLE - Scholarship Gala Donation",
    },
  },

  {
    path: "/scholarship-gala-silent-auction",
    name: "ScholarshipGalaSilentAuction",
    component: ScholarshipGalaSilentAuction,
    meta: {
      title: "MYLE - Scholarship Gala Silent Auction",
    },
  },

  {
    path: "/2023-scholarship-gala-weekend-social-kickoff",
    name: "ScholarShipGalaWeekendSocialKickoff",
    component: ScholarShipGalaWeekendSocialKickoff,
    meta: {
      title: "MYLE - 2023 ScholarShip Gala Weekend Social Kickoff",
    },
  },

  {
    path: "/100-black-men-of-dekalb-county",
    name: "BlackMen100",
    component: BlackMen100,
    meta: {
      title: "MYLE - 100 Black Men of Dekalb County",
    },
  },

  {
    path: "/5th-annual-fatherhood-expo",
    name: "AnnualFatherhoodExpo",
    component: AnnualFatherhoodExpo,
    meta: {
      title: "MYLE - 5th Annual Fatherhood Expo",
    },
  },

  {
    path: "/veterans-car-show",
    name: "VeteransCarShow",
    component: VeteransCarShow,
    meta: {
      title: "MYLE - Veterans Car Show",
    },
  },

  {
    path: "/mt-zion-40th-anniversary-celebration",
    name: "MtZion",
    component: MtZion,
    meta: {
      title: "MYLE - Mt Zion 40th Anniversary Celebration",
    },
  },

  {
    path: "/unation-juneteenth-407-weekend",
    name: "JuneteenthUNATION",
    component: JuneteenthUNATION,
    meta: {
      title: "MYLE - Juneteenth 407 Weekend UNATION",
    },
  },

  {
    path: "/summer614-backstage-pass-fundraising-campaign",
    name: "Summer614",
    component: Summer614,
    meta: {
      title: "MYLE - SUMMER614 Backstage Pass Fundraising Campaign",
    },
  },

  {
    path: "/black-father-daughter-dance-at-cosi",
    name: "BLACKFATHERDAUGHTERDANCEATCOSI2nd",
    component: BLACKFATHERDAUGHTERDANCEATCOSI2nd,
    meta: {
      title: "MYLE | BLACK FATHER DAUGHTER DANCE AT COSI",
    },
  },

  {
    path: "/wceo-presents-bubbles-&-bags",
    name: "BubblesBags",
    component: BubblesBags,
    meta: {
      title: "MYLE | WCEO Presents Bubbles & Bags",
    },
  },

  {
    path: "/34th-grand-polemarch-invitational-golf-outing",
    name: "GrandPolemarchsInvitationalGolfOuting",
    component: GrandPolemarchsInvitationalGolfOuting,
    meta: {
      title: "MYLE | 34th Grand Polemarch's Invitational Golf Outing",
    },
  },

  {
    path: "/columbusBlack-professional-networking-social",
    name: "ColumbusBlackProfessionalNetworkingSocial",
    component: ColumbusBlackProfessionalNetworkingSocial,
    meta: {
      title: "MYLE - ColumbusBlack's Professional Networking Social",
    },
  },

  {
    path: "/2023-naaia-national-conference-gala",
    name: "NAAIA",
    component: NAAIA,
    meta: {
      title: "MYLE - 2023 NAAIA National Conference Gala",
    },
  },

  {
    path: "/columbus-urban-league-empowerment-day",
    name: "ColumbusUrbanLeagueEmpowermentDay",
    component: ColumbusUrbanLeagueEmpowermentDay,
    meta: {
      title: "MYLE - Columbus Urban League Empowerment Day",
    },
  },

  {
    path: "/columbus-urban-league-empowerment-day-widget",
    name: "ColumbusUrbanLeagueEmpowermentDayWidget",
    component: ColumbusUrbanLeagueEmpowermentDayWidget,
    meta: {
      title: "MYLE - Columbus Urban League Empowerment Day Widget",
    },
  },

  {
    path: "/47th-metro-founders-day-afterparty",
    name: "Metro47thFoundersDayAfterParty",
    component: Metro47thFoundersDayAfterParty,
    meta: {
      title: "MYLE - 47th Metro Founders' Day AfterParty",
    },
  },

  {
    path: "/47th-metro-founders-day-digital-souvenir-booklet",
    name: "Metro47thFoundersDayDigitalSouvenirBooklet",
    component: Metro47thFoundersDayDigitalSouvenirBooklet,
    meta: {
      title: "MYLE - 47th Metro Founders' Day Digital Souvenir Booklet",
    },
  },

  {
    path: "/47th-metro-founders-day-vendor-registration",
    name: "Metro47thFoundersDayVendorRegistration",
    component: Metro47thFoundersDayVendorRegistration,
    meta: {
      title: "MYLE - 47th Metro Founders' Day Vendor Registration",
    },
  },

  {
    path: "/47th-metro-founders-day",
    name: "Metro47thFoundersDay",
    component: Metro47thFoundersDay,
    meta: {
      title: "MYLE - 47th Metro Founders' Day",
    },
  },

  {
    path: "/47th-metro-founders-day-collection",
    name: "Metro47thFoundersDayMainCollection",
    component: Metro47thFoundersDayMainCollection,
    meta: {
      title: "MYLE - 47th Metro Founders' Day",
    },
  },

  {
    path: "/black-owned-and-operated-the-marketplace",
    name: "BOAO",
    component: BOAO,
    meta: {
      title: "MYLE - Black Owned And Operated - The Marketplace",
    },
  },

  {
    path: "/a-tribe-called-caps",
    name: "ATribeCalledCAPS",
    component: ATribeCalledCAPS,
    meta: {
      title: "MYLE - A Tribe Called CAPS",
    },
  },

  {
    path: "/the-leadership-lounge-reception",
    name: "TheLeadershipLoungeReception",
    component: TheLeadershipLoungeReception,
    meta: {
      title: "MYLE - The Leadership Lounge Reception",
    },
  },

  {
    path: "/the-leadership-lounge",
    name: "TheLeadershipLounge",
    component: TheLeadershipLounge,
    meta: {
      title: "MYLE - The Leadership Lounge",
    },
  },

  {
    path: "/boujee-brunch",
    name: "BoujeeBrunch",
    component: BoujeeBrunch,
    meta: {
      title: "MYLE - Boujee Brunch",
    },
  },

  {
    path: "/uncf-launch-party-celebration",
    name: "UNCFLaunchPartyCelebration",
    component: UNCFLaunchPartyCelebration,
    meta: {
      title: "MYLE - UNCF Launch Party Celebration",
    },
  },

  {
    path: "/hbcu-all-star-game-extravaganza",
    name: "HBCUAllStarGameExtravaganza",
    component: HBCUAllStarGameExtravaganza,
    meta: {
      title: "MYLE - HBCU All★Star Game & Extravaganza",
    },
  },

  {
    path: "/ben-development-day-las-vegas",
    name: "BEN",
    component: BEN,
    meta: {
      title: "MYLE - BEN Development Day - Las Vegas",
    },
  },

  {
    path: "/ben-survey",
    name: "Survey",
    component: SurveyForm,
    meta: {
      title: "MYLE - BEN Survey",
    },
  },

  {
    path: "/ben-atlanta-survey",
    name: "BENAtlantaSurvey",
    component: BENAtlantaSurvey,
    meta: {
      title: "MYLE - BEN Atlanta Survey",
    },
  },

  {
    path: "/ben-executive-dinner",
    name: "BENExecutiveDinner",
    component: BENExecutiveDinner,
    meta: {
      title: "MYLE - BEN Executive Dinner",
    },
  },

  {
    path: "/national-museum-of-african-american-music-tour",
    name: "BENNationalMuseumofAfricanAmericanMusicTour",
    component: BENNationalMuseumofAfricanAmericanMusicTour,
    meta: {
      title: "MYLE - National Museum of African American Music Tour",
    },
  },

  {
    path: "/music-city-showcase",
    name: "BENMusicCityShowcase",
    component: BENMusicCityShowcase,
    meta: {
      title: "MYLE - Music City Showcase",
    },
  },

  {
    path: "/bna2-site-tour-leadership-panel",
    name: "BENBNA2SiteTourLeadershipPanel",
    component: BENBNA2SiteTourLeadershipPanel,
    meta: {
      title: "MYLE - BNA2 Site Tour & Leadership Panel",
    },
  },

  {
    path: "/black-wellness-rally",
    name: "BENBlackWellnessRally",
    component: BENBlackWellnessRally,
    meta: {
      title: "MYLE - Black Wellness Rally",
    },
  },

  {
    path: "/welcome-to-nashville-mixer",
    name: "BENWelcomeToNashvilleMixer",
    component: BENWelcomeToNashvilleMixer,
    meta: {
      title: "MYLE - Welcome To Nashville Mixer",
    },
  },

  {
    path: "/ben-collection",
    name: "BENCollection",
    component: BENCollection,
    meta: {
      title: "MYLE - BEN",
    },
  },

  {
    path: "/black-is-entrepreneurial-hbcu-edition",
    name: "BENBlackIsEntrepreneurialHBCUEdition",
    component: BENBlackIsEntrepreneurialHBCUEdition,
    meta: {
      title: "MYLE - Black Is Entrepreneurial HBCU Edition",
    },
  },

  {
    path: "/black-biz-bus-tour",
    name: "BENBlackBizBusTour",
    component: BENBlackBizBusTour,
    meta: {
      title: "MYLE - Black Biz Bus Tour",
    },
  },

  {
    path: "/king-center-community-service",
    name: "BENKingCenterCommunityService",
    component: BENKingCenterCommunityService,
    meta: {
      title: "MYLE - King Center Community Service",
    },
  },

  {
    path: "/blaque-business-expo",
    name: "BENBlaqueBusinessExpo",
    component: BENBlaqueBusinessExpo,
    meta: {
      title: "MYLE - Blaque Business Expo",
    },
  },

  {
    path: "/ben-atlanta-collection",
    name: "BENAtlantaCollection",
    component: BENAtlantaCollection,
    meta: {
      title: "MYLE - BEN Atlanta Collection",
    },
  },

  {
    path: "/retro-renaissance-soiree",
    name: "BENRetroRenaissanceSoiree",
    component: BENRetroRenaissanceSoiree,
    meta: {
      title: "MYLE - Retro Renaissance Soiree",
    },
  },

  // {
  //   path: "/the-southwest-landing-lounge/:checkout?/:status?",
  //   name: "SWAVisa",
  //   component: SWAVisa,
  //   meta: {
  //     title: "The Southwest® Landing Lounge",
  //   },
  // },

  // {
  //   path: "/the-southwest-landing-lounge-faqs",
  //   name: "SWAVisaFAQs",
  //   component: SWAVisaFAQs,
  //   meta: {
  //     title: "The Southwest® Landing Lounge FAQs",
  //   },
  // },

  {
    path: "/the-southwest-landing-lounge-card-validation",
    name: "SWAVisaCardVerify",
    component: SWAVisaCardVerify,
    meta: {
      title: "The Southwest® Landing Lounge",
    },
  },

  {
    path: "/the-southwest-landing-lounge-card-validation-failed",
    name: "SWAVisaCardVerifyFailed",
    component: SWAVisaCardVerifyFailed,
    meta: {
      title: "The Southwest® Landing Lounge",
    },
  },

  // {
  //   path: "/the-southwest-landing-lounge-waitlist",
  //   name: "SWAVisaWaitlistSuccess",
  //   component: SWAVisaWaitlistSuccess,
  //   meta: {
  //     title: "The Southwest® Landing Lounge",
  //   },
  // },

  // {
  //   path: "/the-southwest-landing-lounge-contact-us",
  //   name: "SWAVisaContact",
  //   component: SWAVisaContact,
  //   meta: {
  //     title: "The Southwest® Landing Lounge Contact Us",
  //   },
  // },

  {
    path: "/swa-landing-lounge-contact-us",
    name: "SWAVisaContact",
    component: SWAVisaContact,
    meta: {
      title: "The Southwest® Landing Lounge Contact Us",
    },
  },

  {
    path: "/swa-landing-lounge/:checkout?/:status?",
    name: "SWAVisa",
    component: SWAVisa,
    meta: {
      title: "The Southwest® Landing Lounge",
    },
  },

  {
    path: "/swa-landing-lounge-faqs",
    name: "SWAVisaFAQs",
    component: SWAVisaFAQs,
    meta: {
      title: "The Southwest® Landing Lounge FAQs",
    },
  },

  {
    path: "/swa-landing-lounge-card-validation",
    name: "SWAVisaCardVerify",
    component: SWAVisaCardVerify,
    meta: {
      title: "The Southwest® Landing Lounge",
    },
  },

  {
    path: "/swa-landing-lounge-card-validation-failed",
    name: "SWAVisaCardVerifyFailed",
    component: SWAVisaCardVerifyFailed,
    meta: {
      title: "The Southwest® Landing Lounge",
    },
  },

  {
    path: "/swa-landing-lounge-waitlist",
    name: "SWAVisaWaitlistSuccess",
    component: SWAVisaWaitlistSuccess,
    meta: {
      title: "The Southwest® Landing Lounge",
    },
  },

  {
    path: "/1st-annual-teachers-ball",
    name: "AnnualTeachersBall",
    component: AnnualTeachersBall,
    meta: {
      title: "MYLE - 1st Annual Teachers Ball",
    },
  },

  {
    path: "/boao-the-fashion-district",
    name: "BOAO2nd",
    component: BOAO2nd,
    meta: {
      title: "MYLE - BOAO The Fashion District",
    },
  },

  {
    path: "/empowered-entrepreneurs-accelerating-success",
    name: "LouisianaMBDATrainingConferencePoweringYourProgress",
    component: LouisianaMBDATrainingConferencePoweringYourProgress,
    meta: {
      title: "MYLE - EmpowerED Entrepreneurs: Accelerating Success",
    },
  },

  {
    path: "/food-truck-festival-waitlist-morrow-ga",
    name: "FoodTruckFestivalWaitlist",
    component: FoodTruckFestivalWaitlist,
    meta: {
      title: "MYLE - Food Truck Festival Waitlist - Morrow, GA",
    },
  },

  {
    path: "/2024-gamma-epsilon-bi-annual-retreat-business-meeting-fraternizer",
    name: "GammaEpsilonBiAnnualRetreat",
    component: GammaEpsilonBiAnnualRetreat,
    meta: {
      title:
        "MYLE - 2024 Gamma Epsilon Bi-Annual Retreat Business Meeting/Fraternizer",
    },
  },

  {
    path: "/gamma-epsilon-golf-tournament",
    name: "GammaEpsilonGolfTournament",
    component: GammaEpsilonGolfTournament,
    meta: {
      title: "MYLE - Gamma Epsilon Golf Tournament",
    },
  },

  {
    path: "/gamma-espilon-bi-annual-retreat-cookout",
    name: "GammaEspilonBiAnnualRetreatCookout",
    component: GammaEspilonBiAnnualRetreatCookout,
    meta: {
      title: "MYLE - Gamma Espilon Bi-Annual Retreat-Cookout",
    },
  },

  {
    path: "/2024-gamma-epsilon-collection",
    name: "GammaEpsilonCollection",
    component: GammaEpsilonCollection,
    meta: {
      title: "MYLE - 2024 Gamma Epsilon Collection",
    },
  },

  {
    path: "/state-of-black-mens-health-with-dr-adewale",
    name: "StateOfBlackMensHealth",
    component: StateOfBlackMensHealth,
    meta: {
      title: "MYLE - State of Black Men's Health with Dr. Adewale",
    },
  },

  {
    path: "/you-are-what-you-eat-livestream",
    name: "BlackMenHealthYouAreWhatYouEatLivestream",
    component: BlackMenHealthYouAreWhatYouEatLivestream,
    meta: {
      title: "MYLE - You are What you Eat Livestream",
    },
  },

  {
    path: "/firetalk-tv-livestream",
    name: "BlackMenHealthFiretalkTVLivestream",
    component: BlackMenHealthFiretalkTVLivestream,
    meta: {
      title: "MYLE - Firetalk TV Livestream",
    },
  },

  {
    path: "/functional-mobility-for-men",
    name: "BlackMenHealthFunctionalMobilityForMen",
    component: BlackMenHealthFunctionalMobilityForMen,
    meta: {
      title: "MYLE - Functional Mobility for Men",
    },
  },

  {
    path: "/mens-health-brunch",
    name: "BlackMenHealthMensHealthBrunch",
    component: BlackMenHealthMensHealthBrunch,
    meta: {
      title: "MYLE - Men's Health Brunch",
    },
  },

  {
    path: "/summer614-presents-the-myle-backstage-experience-giveaway",
    name: "Summer6142nd",
    component: Summer6142nd,
    meta: {
      title: "MYLE - SUMMER614 Presents the MYLE Backstage Experience Giveaway",
    },
  },

  {
    path: "/black-mens-health-collection",
    name: "BlackMensHealthCollection",
    component: BlackMensHealthCollection,
    meta: {
      title: "MYLE - Black Men's Health Collection",
    },
  },

  {
    path: "/jazz-brunch",
    name: "JazzBrunch2nd",
    component: JazzBrunch2nd,
    meta: {
      title: "MYLE - Jazz Brunch",
    },
  },

  {
    path: "/scholarship-gala-benefitting-the-leadership-academy",
    name: "ScholarshipGala2nd",
    component: ScholarshipGala2nd,
    meta: {
      title: "MYLE - Scholarship Gala Benefitting the Leadership Academy",
    },
  },

  {
    path: "/scholarship-gala-benefitting-the-leadership-academy-donations",
    name: "ScholarshipGala2ndDonation",
    component: ScholarshipGala2ndDonation,
    meta: {
      title:
        "MYLE - Scholarship Gala Benefitting the Leadership Academy - Donations",
    },
  },

  {
    path: "/scholarship-gala-benefitting-the-leadership-academy-donations-widget",
    name: "BlackMen1002ndDonationWidget",
    component: BlackMen1002ndDonationWidget,
    meta: {
      title:
        "MYLE - Scholarship Gala Benefitting the Leadership Academy - Donations Widget",
    },
  },

  {
    path: "/scholarship-gala-benefitting-the-leadership-academy-silent-auction",
    name: "ScholarshipGala2ndSilentAuction",
    component: ScholarshipGala2ndSilentAuction,
    meta: {
      title:
        "MYLE - Scholarship Gala Benefitting the Leadership Academy Silent Auction",
    },
  },

  {
    path: "/summer614-presents-the-myle-backstage-experience-giveaway-widget",
    name: "Summer6142ndWidget",
    component: Summer6142ndWidget,
    meta: {
      title:
        "MYLE - SUMMER614 Presents the MYLE Backstage Experience Giveaway - Widget",
    },
  },

  {
    path: "/delta-ride-for-the-fight",
    name: "DeltaRideForTheFight",
    component: DeltaRideForTheFight,
    meta: {
      title: "MYLE - Delta Ride For the Fight",
    },
  },

  {
    path: "/delta-ride-for-the-fight-fundraising",
    name: "DeltaRideForTheFightDonation",
    component: DeltaRideForTheFightDonation,
    meta: {
      title: "MYLE - Delta Ride For the Fight - Fundraising",
    },
  },

  {
    path: "/delta-ride-for-the-fight-tshirts",
    name: "DeltaRideForTheFightTShirt",
    component: DeltaRideForTheFightTShirt,
    meta: {
      title: "MYLE - Delta Ride For the Fight - T-Shirt",
    },
  },

  // {
  //   path: "/delta-ride-for-the-fight-preview",
  //   name: "DeltaRideForTheFight",
  //   component: DeltaRideForTheFight,
  //   meta: {
  //     title: "MYLE - Delta Ride For the Fight",
  //   },
  // },

  {
    path: "/midwest-cigar-social-the-grand-slam-edition",
    name: "MIDWESTCIGARSOCIAL",
    component: MIDWESTCIGARSOCIAL,
    meta: {
      title: "MYLE - MIDWEST CIGAR SOCIAL: THE GRAND SLAM EDITION",
    },
  },

  {
    path: "/skegee-r-b-soiree",
    name: "SkegeeRBSoiree",
    component: SkegeeRBSoiree,
    meta: {
      title: "MYLE - Skegee R&B Soiree",
    },
  },

  {
    path: "/reuniting-warriors-gathering-2024",
    name: "BandoReunion",
    component: BandoReunion,
    meta: {
      title: "MYLE - Reuniting Warriors Gathering 2024",
    },
  },

  {
    path: "/reuniting-warriors-gathering-2024-donations",
    name: "BandoReunionDonation",
    component: BandoReunionDonation,
    meta: {
      title: "MYLE - Reuniting Warriors Gathering 2024 - Donations",
    },
  },

  {
    path: "/reuniting-warriors-gathering-2024-souvenir-program",
    name: "BandoReunionSouvenirProgram",
    component: BandoReunionSouvenirProgram,
    meta: {
      title: "MYLE - Reuniting Warriors Gathering 2024 - Souvenir Program",
    },
  },

  {
    path: "/reuniting-warriors-gathering-2024-collection",
    name: "BandoReunionCollection",
    component: BandoReunionCollection,
    meta: {
      title: "MYLE - Reuniting Warriors Gathering 2024 - Collection",
    },
  },

  {
    path: "/the-big-blue-hu-official-tennessee-state-alumnus-party",
    name: "TheBlueTigerBisonBoogie",
    component: TheBlueTigerBisonBoogie,
    meta: {
      title: "MYLE - The Big Blue & HU: Official Tennessee State Alumnus Party",
    },
  },

  // {
  //   path: "/columbusblack-com-countdown-to-election-day-the-community-forum",
  //   name: "ColumbusBlackcomCountdownToElectionDay",
  //   component: ColumbusBlackcomCountdownToElectionDay,
  //   meta: {
  //     title:
  //       "MYLE - ColumbusBlack.com's Countdown to Election Day: The Community Forum",
  //   },
  // },

  // {
  //   path: "/columbusblack-com-countdown-to-election-day-the-community-forum",
  //   name: "ColumbusBlackcomCountdownToElectionDay2nd",
  //   component: ColumbusBlackcomCountdownToElectionDay2nd,
  //   meta: {
  //     title:
  //       "MYLE - ColumbusBlack.com's Countdown to Election Day: The Community Forum",
  //   },
  // },

  {
    path: "/columbusblack-com-countdown-to-election-day-the-community-forum",
    name: "ColumbusBlackcomCountdownToElectionDay3rd",
    component: ColumbusBlackcomCountdownToElectionDay3rd,
    meta: {
      title:
        "MYLE - ColumbusBlack.com's Countdown to Election Day: The Community Forum",
    },
  },

  {
    path: "/priceline-visa-presents-a-virtual-cooking-experience-with-chef-michael-mina-and-the-wine-mvp-will-blackmon/:checkout?/:status?",
    name: "PricelineVisa",
    component: PricelineVisa,
    meta: {
      title:
        "Priceline Visa® Presents: A Virtual Cooking Experience with Chef Michael Mina and The Wine MVP, Will Blackmon",
    },
  },

  {
    path: "/priceline-visa-presents-a-virtual-cooking-experience-with-chef-michael-mina-and-the-wine-mvp-will-blackmon-faqs",
    name: "PricelineVisaFAQs",
    component: PricelineVisaFAQs,
    meta: {
      title:
        "Priceline Visa® Presents: A Virtual Cooking Experience with Chef Michael Mina and The Wine MVP, Will Blackmon FAQs",
    },
  },

  {
    path: "/priceline-visa-presents-a-virtual-cooking-experience-with-chef-michael-mina-and-the-wine-mvp-will-blackmon-travel-guide",
    name: "PricelineVisaTravelGuide",
    component: PricelineVisaTravelGuide,
    meta: {
      title:
        "Priceline Visa® Presents: A Virtual Cooking Experience with Chef Michael Mina and The Wine MVP, Will Blackmon Travel Guide",
    },
  },

  {
    path: "/the-breeze-easy-visa-signature-cardmember-experience/:checkout?/:status?",
    name: "BreezeVISA",
    component: BreezeVISA1,
    meta: {
      title: "The Breeze Easy™ Visa Signature® Cardmember Experience",
    },
  },

  {
    path: "/the-breeze-easy-visa-signature-cardmember-experience-venue",
    name: "BreezeVISAVenue",
    component: BreezeVISAVenue,
    meta: {
      title: "The Breeze Easy™ Visa Signature® Cardmember Experience Venue",
    },
  },

  {
    path: "/the-breeze-easy-visa-signature-cardmember-experience-sofar-sounds",
    name: "BreezeVISASofarSounds",
    component: BreezeVISASofarSounds,
    meta: {
      title:
        "The Breeze Easy™ Visa Signature® Cardmember Experience Sofar Sounds",
    },
  },

  {
    path: "/the-breeze-easy-visa-signature-cardmember-experience-contact-us",
    name: "BreezeVISAContact",
    component: BreezeVISAContact,
    meta: {
      title:
        "The Breeze Easy™ Visa Signature® Cardmember Experience Contact Us",
    },
  },

  {
    path: "/the-breeze-easy-visa-signature-cardmember-experience1/:checkout?/:status?",
    name: "BreezeVISA1",
    component: BreezeVISA1,
    meta: {
      title: "Breeze Easy™ Visa Cardmember Experience",
    },
  },

  {
    path: "/the-breeze-easy-visa-signature-cardmember-experience-card-validation-failed",
    name: "VisaCardVerifyFailed",
    component: VisaCardVerifyFailed,
    meta: {
      title: "The Breeze Easy™ Visa Signature® Cardmember Experience",
    },
  },

  {
    path: "/mobility-in-color-cocktail-reception",
    name: "MobilityinColorCocktailReception",
    component: MobilityinColorCocktailReception,
    meta: {
      title: "MYLE - Mobility in Color - Cocktail Reception",
    },
  },

  {
    path: "/mobility-in-color-main-event",
    name: "MobilityinColorCocktailReceptionMainEvent",
    component: MobilityinColorCocktailReceptionMainEvent,
    meta: {
      title: "MYLE - Mobility in Color - Main Event",
    },
  },

  {
    path: "/tuxedos-and-tennis-shoes-annual-gala",
    name: "TuxedosandTennisShoesAnnualGala",
    component: TuxedosandTennisShoesAnnualGala,
    meta: {
      title: "MYLE - Tuxedos and Tennis Shoes Annual Gala",
    },
  },

  {
    path: "/5-reasons-why-you-should-use-myle-for-your-next-event",
    name: "FiveReasonsWhyYouShouldUseMYLE",
    component: FiveReasonsWhyYouShouldUseMYLE,
    meta: {
      title: "MYLE - 5 Reasons Why You Should Use MYLE for Your Next Event!",
    },
  },

  {
    path: "/the-skegee-ball-parlay-soiree-100th-homecoming-edition",
    name: "TheSkegeeBallParlaySoiree100thHomecomingEdition",
    component: TheSkegeeBallParlaySoiree100thHomecomingEdition,
    meta: {
      title: "MYLE - The Skegee Ball & Parlay Soiree 100th Homecoming Edition",
    },
  },

  {
    path: "/midwest-cigar-social-presents-2-fast-2-furious",
    name: "MIDWESTCIGARSOCIALPRESENTS2FAST2FURIOUS",
    component: MIDWESTCIGARSOCIALPRESENTS2FAST2FURIOUS,
    meta: {
      title: "MYLE - MIDWEST CIGAR SOCIAL PRESENTS: 2 FAST 2 FURIOUS",
    },
  },

  {
    path: "/backyard-bash-with-the-signature-select-life-dallas",
    name: "AlbertsonsVISADallas",
    component: AlbertsonsVISADallas,
    meta: {
      title: "Backyard Bash With The Signature SELECT Life - Dallas",
    },
  },

  {
    path: "/backyard-bash-with-the-signature-select-life-los-angeles",
    name: "AlbertsonsVISALA",
    component: AlbertsonsVISALA,
    meta: {
      title: "Backyard Bash With The Signature SELECT Life - Los Angeles",
    },
  },

  {
    path: "/backyard-bash-with-the-signature-select-life-philadelphia",
    name: "AlbertsonsVISAPhilly",
    component: AlbertsonsVISAPhilly,
    meta: {
      title: "Backyard Bash With The Signature SELECT Life - Philadelphia",
    },
  },

  {
    path: "/the-ultimate-tailgate-a-community-gathering",
    name: "TheUltimateTailgateandCommUNITYExperience",
    component: TheUltimateTailgateandCommUNITYExperience,
    meta: {
      title: "MYLE - The Ultimate Tailgate - A CommUNITY Gathering",
    },
  },

  {
    path: "/hbcu-ny-classic-howard-spelman-morehouse",
    name: "HBCUNYClassic",
    component: HBCUNYClassic,
    meta: {
      title: "MYLE - HBCU NY Classic - Howard Spelman & Morehouse",
    },
  },

  {
    path: "/big-blue-and-hu-homecoming-post-game-soiree",
    name: "BigBlueAndHUHomecomingPostGameSoiree",
    component: BigBlueAndHUHomecomingPostGameSoiree,
    meta: {
      title: "MYLE - Big Blue and HU Homecoming Post-Game Soiree",
    },
  },

  {
    path: "/the-2024-election-debate-watch",
    name: "The2024ElectionDebateWatch",
    component: The2024ElectionDebateWatch,
    meta: {
      title: "MYLE - The 2024 Election Debate Watch",
    },
  },

  {
    path: "/real-hu-vip-afterparty",
    name: "RealHUVIPAfterparty",
    component: RealHUVIPAfterparty,
    meta: {
      title:
        "MYLE - Real HU VIP Afterparty hosted by LAWCF, Tiffany-Rose, Tiger, Omar-The-Creative, Akil & Tony Solano",
    },
  },

  {
    path: "/2024-new-year-eve-bow-ties-diamonds-experience",
    name: "NEWYEAREVEBOWTIESDIAMONDSEXPERIENCE",
    component: NEWYEAREVEBOWTIESDIAMONDSEXPERIENCE,
    meta: {
      title: "MYLE - 2024 NEW YEAR EVE BOW TIES & DIAMONDS EXPERIENCE",
    },
  },

  {
    path: "/navy-federal-credit-member-in-ga",
    name: "NavyFederalCreditMemberinGA",
    component: NavyFederalCreditMemberinGA,
    meta: {
      title: "Navy Federal Credit Member in GA",
    },
  },

  {
    path: "/navy-federal-credit-member-in-ga-faqs",
    name: "NavyFederalCreditMemberinGAFAQs",
    component: NavyFederalCreditMemberinGAFAQs,
    meta: {
      title: "Navy Federal Credit Member in GA - FAQs",
    },
  },

  // {
  //   path: "/the-southwest-landing-lounge-card-validation",
  //   name: "SWAVisaCardVerify",
  //   component: SWAVisaCardVerify,
  //   meta: {
  //     title: "The Southwest® Landing Lounge",
  //   },
  // },

  // {
  //   path: "/the-southwest-landing-lounge-card-validation-failed",
  //   name: "SWAVisaCardVerifyFailed",
  //   component: SWAVisaCardVerifyFailed,
  //   meta: {
  //     title: "The Southwest® Landing Lounge",
  //   },
  // },

  // {
  //   path: "/the-southwest-landing-lounge-waitlist",
  //   name: "SWAVisaWaitlistSuccess",
  //   component: SWAVisaWaitlistSuccess,
  //   meta: {
  //     title: "The Southwest® Landing Lounge",
  //   },
  // },

  // {
  //   path: "/the-southwest-landing-lounge-contact-us",
  //   name: "SWAVisaContact",
  //   component: SWAVisaContact,
  //   meta: {
  //     title: "The Southwest® Landing Lounge Contact Us",
  //   },
  // },

  // {
  //   path: '/index-6',
  //   name: 'IndexSix',
  //   component: IndexSix,
  //   meta: { title: 'Home 6 -Appco - App Landing Page Template' }

  // },
  // {
  //   path: '/index-7',
  //   name: 'IndexSeven',
  //   component: IndexSeven,
  //   meta: { title: 'Home 7 -Appco - App Landing Page Template' }

  // },
  // {
  //   path: '/about',
  //   name: 'IndexOne#about',
  //   component: IndexOne,
  //   meta: { anchor: 'about' }
  // },
  // {
  //   path: '/features',
  //   name: 'IndexOne#features',
  //   component: IndexOne,
  //   meta: { anchor: 'features' }
  // },
  // {
  //   path: '/screenshots',
  //   name: 'IndexOne#screenshots',
  //   component: IndexOne,
  //   meta: { anchor: 'screenshots' }
  // },
  // {
  //   path: '/pricing',
  //   name: 'IndexOne#pricing',
  //   component: IndexOne,
  //   meta: { anchor: 'pricing' }
  // },
  // {
  //   path: '/team',
  //   name: 'IndexOne#team',
  //   component: IndexOne,
  //   meta: { anchor: 'team' }
  // },
  // {
  //   path: '/contact',
  //   name: 'IndexOne#contact',
  //   component: IndexOne,
  //   meta: { anchor: 'contact' }
  // },
  // {
  //   path: '/login-1',
  //   name: 'login-one',
  //   component: LoginPageOne,
  //   meta: { title: 'login page 1 -Appco -App Landing Page Template' }
  // },
  // {
  //   path: '/login-2',
  //   name: 'login-two',
  //   component: LoginPageTwo,
  //   meta: { title: 'login page 2 -Appco -App Landing Page Template' }
  // },
  // {
  //   path: '/signup-1',
  //   name: 'signup-1',
  //   component: SignupPageOne,
  //   meta: { title: 'signup page 1 -Appco -App Landing Page Template' }
  // },
  // {
  //   path: '/signup-2',
  //   name: 'signup-2',
  //   component: SignupPageTwo,
  //   meta: { title: 'signup page 2 -Appco -App Landing Page Template' }
  // },
  // {
  //   path: '/reset-password',
  //   name: 'reset-password',
  //   component: PasswordRest,
  //   meta: { title: 'password reset -Appco -App Landing Page Template' }
  // },
  // {
  //   path: '/change-password',
  //   name: 'change-password',
  //   component: ChangePassword,
  //   meta: { title: 'change password -Appco -App Landing Page Template' }
  // },
  // {
  //   path: '/download',
  //   name: 'download',
  //   component: DownloadPage,
  //   meta: { title: 'download -Appco -App Landing Page Template' }
  // },
  // {
  //   path: '/review',
  //   name: 'review',
  //   component: ReviewPage,
  //   meta: { title: 'review -Appco -App Landing Page Template' }
  // },
  {
    path: "/faqs",
    name: "MYLEFAQ",
    component: MYLEFAQ,
    meta: { title: "MYLE - FAQs" },
  },
  {
    path: "/404",
    name: "404",
    component: Page404,
    meta: { title: "404 -Appco -App Landing Page Template" },
  },
  // {
  //   path: '/coming-soon',
  //   name: 'coming-soon',
  //   component: ComingsoonPage,
  //   meta: { title: 'Coming Soon -Appco -App Landing Page Template' }
  // },
  // {
  //   path: '/thank-you',
  //   name: 'thank-you',
  //   component: ThankyouPage,
  //   meta: { title: 'Thank you -Appco -App Landing Page Template' }
  // },
  // {
  //   path: '/team-1',
  //   name: 'team-1',
  //   component: TeamPage,
  //   meta: { title: 'Team 1 -Appco -App Landing Page Template' }
  // },
  // {
  //   path: '/team-2',
  //   name: 'team-2',
  //   component: TeamOne,
  //   meta: { title: 'Team 2 -Appco -App Landing Page Template' }
  // },
  // {
  //   path: '/blog-grid',
  //   name: 'blog-grid',
  //   component: BlogGrid,
  //   meta: { title: 'Blog Grid -Appco -App Landing Page Template' }
  // },
  // {
  //   path: '/blog-left-sidebar',
  //   name: 'blog-left-sidebar',
  //   component: BlogLeftSidebar,
  //   meta: { title: 'Blog left -Appco -App Landing Page Template' }
  // },
  // {
  //   path: '/blog-right-sidebar',
  //   name: 'blog-right-sidebar',
  //   component: DetailsRightSidebar,
  //   meta: { title: 'Details Right Sidebar -Appco -App Landing Page Template' }
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  // if (to.meta && to.meta.title) document.title = to.meta.title;
  next();
});

export default router;
