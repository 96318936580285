<template>
  <div style="background-color: white">
    <!-- form starts -->
    <vue-element-loading
      :active="isSubmitted"
      text="MYLE"
      :text-style="loadStyle"
      :is-full-screen="true"
      class="h-full background-overlay"
      spinner="bar-fade-scale"
      color="#ffffff"
    />

    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="checkout.publishKey"
      :sessionId="checkout.clientReferenceId"
    />
    <nu-modal ref="modal">
      <form>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="text-center">
                <h3 class="w-auto d-inline-block">Reservation Form</h3>
              </div>
            </div>
            <div class="col-md-12 mt-4">
              <p>
                <b class="font-weight-bold">Ticket Title:</b>
                {{
                  TicketsResult[selected]
                    ? TicketsResult[selected].ticketName
                    : ""
                }}
              </p>
              <p v-if="!redeemData">
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  TicketsResult[selected]
                    ? TicketsResult[selected].price.toFixed(2)
                    : ""
                }}
              </p>
              <p v-if="redeemData">
                <b class="font-weight-bold">Per Person Price:</b> ${{
                  redeemData.price.toFixed(2)
                }}
              </p>
              <p v-if="!redeemData">
                <b class="font-weight-bold">Total Price: </b> ${{
                  (
                    (TicketsResult[selected]
                      ? TicketsResult[selected].price
                      : 0) * tickcount
                  ).toFixed(2)
                }}
              </p>
              <p v-if="redeemData">
                <b class="font-weight-bold">Total Price: </b> ${{
                  ((redeemData.price || 0) * tickcount).toFixed(2)
                }}
              </p>
            </div>
            <div class="col-md-6">
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Full Name"
                required
                v-model="fullname"
              />
            </div>
            <div class="col-md-6">
              <input
                type="email"
                class="form-control mb-3"
                placeholder="Email"
                v-model="email"
                required
              />
            </div>
            <!-- <div class="col-md-6">
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Phone"
                v-model="phoneNo"
              />
            </div> -->
            <!-- <div
              class="col-md-6"
              v-if="
                TicketsResult[selected] &&
                TicketsResult[selected].ticketCode !== 'tk_Fx4F63eTUSiM2mf'
              "
            >
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Business Name (optional)"
                v-model="bname"
              />
            </div> -->
            <div
              class="col-md-6 mb-1"
              v-if="
                TicketsResult[selected] &&
                TicketsResult[selected].ticketCode !== 'tk_6jAiz9ZUHELvpdv'
              "
            >
              <label class="mt-3">Number of Ticket(s)</label>
              <select class="form-control" v-model="tickcount">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in tickcountlist"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mb-1"
              v-if="
                ((TicketsResult[selected] || {}).availableSeating || [])
                  .length > 0 &&
                (TicketsResult[selected] || {}).ticketCode !=
                  'tk_Fx4F63eTUSiM2mf'
              "
            >
              <label class="mt-3">Available Seating</label>
              <select class="form-control" v-model="availSeating">
                <option
                  class="form-control"
                  v-bind:key="seating.table"
                  v-for="seating in uniq(
                    (TicketsResult[selected] || {}).availableSeating
                  )"
                  :value="seating"
                >
                  {{ seating }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mb-1"
              v-if="
                ((TicketsResult[selected] || {}).availableSeating || [])
                  .length > 0 &&
                (TicketsResult[selected] || {}).ticketCode ==
                  'tk_ZNDRjDCAnOGEjAn'
              "
            >
              <label class="mt-3">Available Seating</label>
              <select class="form-control" v-model="availSeating">
                <option
                  class="form-control"
                  v-bind:key="seating.table"
                  v-for="seating in uniq1(
                    (TicketsResult[selected] || {}).availableSeating
                  )"
                  :value="seating"
                >
                  {{ seating }}
                </option>
              </select>
            </div>

            <div
              class="col-md-6 mt-3"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <label class="">Do you have a promo code?</label>

              <input
                type="text"
                v-model="redeempromo"
                class="form-control"
                placeholder="Promo code"
              />
            </div>

            <div
              class="col-md-6"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            ></div>

            <div
              class="col-md-6 text-center"
              id="redeem"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <button
                type="button"
                @click="redeem()"
                :disabled="!redeempromo.trim().length"
                class="btn solid-btn btn-sm my-auto"
                id="reserve"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  padding: 7px;
                  margin-top: 11px !important;
                "
              >
                Redeem Code
              </button>
            </div>

            <div
              class="col-md-12 mt-2"
              v-if="
                TicketsResult[selected] &&
                TicketsResult[selected].ticketCode == 'tk_Fx4F63eTUSiM2mf'
              "
            >
              <doublelist
                @listtopush="listtopush"
                v-if="
                  TicketsResult[selected] &&
                  TicketsResult[selected].availableSeating.length > 0
                "
                :list="(TicketsResult[selected] || {}).availableSeating"
                :limit="(TicketsResult[selected] || {}).allowedPerRequest || 0"
              />
            </div>

            <div class="col-md-12 mt-4">
              <input type="checkbox" v-model="reserveFormAccept" />
              I understand that all sales are final. No refunds. I have read and
              agree with the MYLE
              <a
                href="https://myle.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions.</a
              >
            </div>

            <div class="col-md-12 text-center mt-4">
              <button
                type="submit"
                @click="paynow()"
                :disabled="
                  (TicketsResult[selected] &&
                  groupData.includes(TicketsResult[selected].ticketCode)
                    ? !required_power_needs.trim().length
                    : false) ||
                  !fullname.trim().length ||
                  !invalidemail ||
                  isSubmitted ||
                  !tickcountlist.length ||
                  !reserveFormAccept ||
                  (TicketsResult[selected] &&
                  TicketsResult[selected].ticketCode == 'tk_Fx4F63eTUSiM2mf'
                    ? selectionList.length < 1
                    : false)
                "
                class="btn solid-btn-2024NEWYEAREVEBOWTIESDIAMONDSEXPERIENCE m-auto mr-2"
                style="pointer-events: all; cursor: pointer"
              >
                Buy now
              </button>
            </div>
          </div>
        </div>
      </form>
    </nu-modal>
    <!-- form ends -->

    <!-- zelle model -->
    <nu-modal ref="zellemodal">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 text-center">
            <img
              style="width: 80%; max-width: 80%"
              src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/zelle.jpeg"
            />
          </div>
        </div>
      </div>
    </nu-modal>
    <!-- zelle model -->

    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_2024NEWYEAREVEBOWTIESDIAMONDSEXPERIENCE.png"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.6rem;
            "
          />
        </div>
      </div>
      <!-- banner section ends -->
      <div
        class="row w-100 m-0 promo-section MYLE-lite-green"
        style="background: #170200; border-top: 1px solid white"
      >
        <div class="col-lg-12 col-md-12">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  color: #dab785;
                "
              >
                2024 New Year Eve Bow Ties & Diamonds Experience
              </h1>

              <h1
                class="text-center"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 1.2rem;
                  color: #dab785;
                "
              >
                Tuesday, December 31, 2024, <br />
                04:00pm - 02:00am
              </h1>

              <h1
                class="text-center"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                "
              >
                <a
                  href="https://maps.app.goo.gl/7bbLPrAA4J4vjmaA6"
                  target="_blank"
                  rel="noopener noreferrer"
                  style="color: #dab785"
                >
                  Westfields Marriott Washington Dulles
                </a>
              </h1>
            </div>
          </div>
        </div>
      </div>
      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="col-md-12">
            <div class="download-content">
              <p
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 1.4rem;
                  color: black;
                "
              >
                Join us for an unforgettable New Year's Eve celebration at the
                Bowties & Diamonds Experience! Get ready to welcome 2025 in
                style at our glamorous event filled with glitz and glamor. The
                festivities will kick off on Tuesday, December 31, 2024, at 4:00
                PM (Eastern Standard Time).
                <br /><br />

                Located at 14750 Conference Center Drive, Chantilly, VA 20151,
                our stunning venue promises an enchanting ambiance to make your
                evening truly magical. Dress to impress in your finest attire,
                with bowties and diamonds adding that extra touch of elegance.
                <br /><br />

                Indulge in an evening of entertainment, dancing, and live
                performances that will keep you on your feet throughout the
                night. Savor delicious culinary delights from our gourmet menu
                and raise a glass to toast the arrival of the New Year.
                <br /><br />

                Whether you're attending with friends, a loved one, or simply
                looking to make new connections, the Bowties & Diamonds
                Experience guarantees an unforgettable night filled with
                laughter, joy, and memories that will last a lifetime. Don't
                miss out on this exclusive event!

                <br /><br />

                <span
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-size: 2.2rem;
                  "
                >
                  Jeans and Sneaks Kappa Kickback Reception
                </span>

                <br />

                <span
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 1.4rem;
                  "
                >
                  Brand new experience to welcome in the New Year. The New
                  Year’s Eve Experience with the hottest Day party in the DMV;
                  hosted by the World renowned DJ Flow!!!
                  <!-- <a
                    href="https://www.instagram.com/djflowdc/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://www.instagram.com/djflowdc</a
                  > -->
                </span>
              </p>

              <div class="row mt-2">
                <div class="col-md-3 col-md-3"></div>

                <div class="col-md-6 col-md-6">
                  <div class="text-center">
                    <a
                      href="https://www.instagram.com/djflowdc/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style="color: #e28332"
                    >
                      <img
                        src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_dj_flow.jpg"
                        class="w-75 img-fluid"
                        style="border-radius: 3%"
                      />
                      <img
                        src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_follow_on_instagram.png"
                        class="w-50 img-fluid mt-3"
                        style="border-radius: 3%"
                      />
                    </a>
                  </div>
                </div>
                <div class="col-md-3 col-md-3"></div>
              </div>

              <br />

              <p
                class=""
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 500;
                  font-size: 1.4rem;
                  color: black;
                "
              >
                <span
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-size: 1.6rem;
                  "
                >
                  Dress code:
                </span>

                <span
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 1.4rem;
                  "
                >
                  Upscale and stylish
                </span>

                <br />
                <br />

                <span
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-size: 2.2rem;
                  "
                >
                  Evening Experience
                </span>

                <br />

                <span
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 1.4rem;
                  "
                >
                  It's time to celebrate bringing in the New Year! Let's make a
                  champagne toast to a wonderful evening and raise 2025! We will
                  raise your glasses and join in a toast to a year 2025 with
                  joy, laughter, and good times. Here's to the 2024 memories we
                  will make tonight, and the wonderful future that awaits us.
                  Cheers!
                </span>

                <br />
                <br />

                <span
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-size: 1.6rem;
                  "
                >
                  Dress code:
                </span>

                <span
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 1.4rem;
                  "
                >
                  Upscale and stylish
                </span>

                <br />
                <br />

                <span
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-size: 2.2rem;
                  "
                >
                  Raffle:
                </span>

                <span
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 1.4rem;
                  "
                >
                  Cash and gift card raffle tickets will be available throughout
                  the evening. 10 tickets for $25 with drawing(s) every hour.
                  Must be present to win.
                </span>

                <br />
                <br />

                <span
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-size: 2.2rem;
                  "
                >
                  Silent Auction:
                </span>

                <span
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 1.4rem;
                  "
                >
                  We have some amazing items up for bid this year, including
                  fine dining experiences, adventure sports and travel, and much
                  more.

                  <br />
                  <br />

                  The funds we raise go towards programs and initiatives that
                  benefit underserved communities, with a focus on youth
                  development, education, diversity, advocacy, and community
                  outreach.
                </span>

                <br />
                <br />

                <span
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-size: 2.2rem;
                  "
                >
                  Child care:
                </span>

                <span
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 1.4rem;
                  "
                >
                  No cost, Worry free child care available for school age
                  children 5 and over. Exceptions available for siblings (must
                  be potty trained). Register here to make your child care
                  reservation. Drop off starts at 7:45pm in Adams Room.
                </span>

                <br />
                <br />

                <span
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-size: 2.2rem;
                  "
                >
                  New Year's Eve Experience Activities
                </span>

                <br />

                <span
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 1.4rem;
                  "
                >
                  📅 <span class="bolded"> Date:</span> Tuesday, December 31,
                  2024<br />
                  ⏰ <span class="bolded"> Time:</span> 4 PM - 2 AM
                  <br />
                  📍 <span class="bolded"> Location:</span>

                  <a
                    href="https://maps.app.goo.gl/7bbLPrAA4J4vjmaA6"
                    target="_blank"
                    rel="noopener noreferrer"
                    style="color: black"
                  >
                    Westfields Marriott Washington Dulles, 14750 Conference
                    Center Dr, Chantilly, VA 20151
                  </a>
                </span>

                <br />
                <br />

                <span
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-size: 2.2rem;
                  "
                >
                  Kappa Kickback Reception:
                </span>

                <br />

                <span
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 1.4rem;
                  "
                >
                  • 4-7pm<br />
                  • (Causally Grown & Stylish Attire Requested)<br />
                  • Dance Party with DJ Flow<br />
                  • Fairfax Dining Room<br />
                  • Pasta Station, MeatBalls and Salad will be served
                </span>

                <br />
                <br />

                <span
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-size: 2.2rem;
                  "
                >
                  Bowties & Diamonds Experience:
                </span>

                <br />

                <span
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 1.4rem;
                  "
                >
                  • 9pm-midnight <br />
                  • (Cocktail Attire & Sneakers Requested) <br />
                  • Dance Party with DJ Aron <br />
                  • Grand Dominion Ballroom <br />
                  • Dry Snacks will be served <br />
                  • Champagne Toast - Midnight
                </span>

                <br />
                <br />

                <span
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-size: 2.2rem;
                  "
                >
                  West Wing Breakfast:
                </span>

                <br />

                <span
                  style="
                    font-family: 'Quicksand', sans-serif;
                    font-weight: 500;
                    font-size: 1.4rem;
                  "
                >
                  • 12:15am - 1:30am <br />
                  • Encore Breakfast Buffet
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- Description section endss -->

      <div
        id="d1"
        style="max-width: 700px; margin: auto; height: auto; margin-top: 50px"
      ></div>

      <div
        class="row align-items-center justify-content-center test"
        style="margin: auto; margin-top: 20px"
      >
        <div
          class="col-md-2 align-items-center justify-content-center"
          style="display: flex"
        >
          <table>
            <tbody>
              <tr>
                <td style="vertical-align: middle">
                  <div
                    style="
                      background: #dab785;
                      color: #dab785;
                      width: 20px;
                      height: 20px;
                      margin-right: 3px;
                    "
                  ></div>
                </td>
                <td style="vertical-align: middle">
                  <div
                    style="
                      font-family: 'Quicksand', sans-serif;
                      font-weight: 500;
                      font-size: 12px;
                    "
                  >
                    Available
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          class="col-md-2 align-items-center justify-content-center"
          style="display: flex"
        >
          <table>
            <tbody>
              <tr>
                <td style="vertical-align: middle">
                  <div
                    style="
                      background: lightgrey;
                      color: lightgrey;
                      width: 20px;
                      height: 20px;
                      margin-right: 3px;
                    "
                  ></div>
                </td>
                <td style="vertical-align: middle">
                  <div
                    style="
                      font-family: 'Quicksand', sans-serif;
                      font-weight: 500;
                      font-size: 12px;
                    "
                  >
                    Reserved
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- Tickets Section starts -->
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="py-5">
            <div class="text-center">
              <h3
                class="w-auto d-inline-block mb-2"
                style="
                  font-family: 'Quicksand', sans-serif;
                  font-weight: 700;
                  font-size: 30px;
                "
              >
                TICKET OPTIONS
              </h3>
            </div>
            <div class="d-flex flex-row flex-wrap justify-content-center">
              <div
                class="card mx-4 mt-4"
                style="
                  max-width: 400px !important;
                  border-radius: 10px;
                  overflow: hidden;
                "
                v-bind:key="objk"
                v-for="(obj, objk) in individualTick(TicketsResult)"
              >
                <div
                  class="card-header MYLE-green white"
                  style="
                    background: #170200;
                    color: #dab785;
                    width: 310px !important;
                  "
                >
                  {{ obj.ticketName }}
                </div>
                <div
                  class="card-body"
                  style="display: flex; flex-direction: column"
                >
                  <p
                    v-bind:key="w"
                    v-for="(msgs, w) in (tkList[obj.ticketCode] || []).messages"
                  >
                    - {{ msgs }}
                  </p>
                  <div class="text-center" style="margin-top: auto">
                    <p>
                      <b class="font-weight-bold">Total Price: </b>${{
                        obj.price.toFixed(2)
                      }}
                    </p>
                    <p
                      v-bind:key="linek"
                      v-for="(line, linek) in obj.description"
                    >
                      {{ line }}
                    </p>
                    <button
                      :disabled="
                        !obj.availableTickets ||
                        obj.status == 'EXPIRED' ||
                        obj.status == 'COMING_SOON'
                      "
                      v-bind:class="{
                        'bg-disable':
                          obj.status == 'EXPIRED' ||
                          !obj.availableTickets ||
                          obj.status == 'COMING_SOON',
                      }"
                      class="btn solid-btn-2024NEWYEAREVEBOWTIESDIAMONDSEXPERIENCE"
                      @click="openmodel(obj)"
                      style="
                        pointer-events: all;
                        cursor: pointer;
                        font-size: 18px;
                      "
                    >
                      {{
                        obj.status == "EXPIRED" || !obj.availableTickets
                          ? "Sold Out"
                          : obj.status == "COMING_SOON"
                          ? "Coming Soon"
                          : "Reserve Now"
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <br />

    <div
      class="row w-100 m-0 promo-section MYLE-lite-green"
      style="background: #170200"
    >
      <div class="col-lg-12 col-md-12">
        <div class="mt-5">
          <div class="section-heading mb-5">
            <h2 class="text-center" style="color: #dab785">
              Download today and try it for yourself
            </h2>
            <div
              class="download-btn mt-4 text-center animate__animated animate__fadeIn"
            >
              <a
                href=" https://apps.apple.com/us/app/myle-create-discover-events-curated-for-you/id1162354481"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-apple"></span> App Store</a
              >
              <a
                href="https://play.google.com/store/apps/details?id=xyz.getmyle.myle&hl=en&gl=US"
                class="btn google-play-btn mr-3"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-android"></span> Google Play</a
              >
              <a
                href="https://web.myle.com"
                class="btn google-play-btn"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="ti-world"></span> MYLE Web</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
// Component Imports
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";
import VueElementLoading from "vue-element-loading";
import Doublelist from "./doubleList.vue";

// tslint-disable no-mixed-spaces-and-tabs
// defining component state
export default {
  name: "newyear",
  data() {
    return {
      options: {
        text: {
          color: "black",
          fontSize: 10,
          textAdjustmentY: 2,
          fontFamily: "Arial",
          textEnabled: true,
        },
        thermo: {
          color: "#145143",
          backgroundColor: "#ffffff",
          frameColor: "black",
          ticks: 10,
          ticksEnabled: true,
          tickColor: "black",
          tickWidth: "1",
        },
        layout: {
          height: 300,
          width: 90,
        },
      },
      selectionList: [],
      dict: {},
      dictlist: {},
      required_power_needs: "No",
      selectedSizes: "Extra Small - XS",
      availSeating: "",
      sizes: [
        "Extra Small - XS",
        "Small - S",
        "Medium - M",
        "Large - L",
        "Extra Large - XL",
        "2 Extra Large - 2XL",
        "3 Extra Large - 3XL",
        "4 Extra Large - 4XL",
        "5 Extra Large - 5XL",
      ],
      individualsData: [
        "tk_Fx4F63eTUSiM2mf",
        "tk_6jAiz9ZUHELvpdv",
        "tk_bTDSY8ie8I9yDOF",
        "tk_tOVDlpp3dg4HMDw",
        "tk_20ws90syyvl0sxT",
        "tk_rM7htyFperIy5yi",
      ],

      groupData: [],
      redeempromo: "",
      redeemPriceId: "",
      redeemData: null,
      reserveFormAccept: false,
      tkList: {
        tk_Fx4F63eTUSiM2mf: {
          messages: ["Ticket Price: $135", "Processing fee of $10.7"],
        },
        tk_6jAiz9ZUHELvpdv: {
          messages: ["Ticket Price: $1350", "Processing fee of $92.19"],
        },
        tk_bTDSY8ie8I9yDOF: {
          messages: ["Ticket Price: $2500", "Processing fee of $169.32"],
        },
        tk_tOVDlpp3dg4HMDw: {
          messages: ["Ticket Price: $3000", "Processing fee of $202.86"],
        },
        tk_20ws90syyvl0sxT: {
          messages: ["Ticket Price: $5000", "Processing fee of $337"],
        },
        tk_rM7htyFperIy5yi: {
          messages: ["Ticket Price: $7500", "Processing fee of $504.69"],
        },
      },

      priceIds: {
        tk_Fx4F63eTUSiM2mf: "price_1PsqaJD0vTZ4QB9cODZBjCpW",
        tk_6jAiz9ZUHELvpdv: "price_1PxxtVD0vTZ4QB9cBJIsbhy7",
        tk_bTDSY8ie8I9yDOF: "price_1PsqaLD0vTZ4QB9ce8EtSAUi",
        tk_tOVDlpp3dg4HMDw: "price_1PsqaLD0vTZ4QB9cN6VIpNwi",
        tk_20ws90syyvl0sxT: "price_1PsqaMD0vTZ4QB9csXYew3az",
        tk_rM7htyFperIy5yi: "price_1PsqaMD0vTZ4QB9cajYhWPKz",
      },

      countsListShow: [],
      eventTicketResult: [],
      selectedAccor: null,
      ticketForm: {
        name: "",
        email: "",
        phone: "",
        ticketCount: 0,
        ticketName: "",
        ticketId: "",
        method: "",
        accept: "",
        file: null,
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      fullname: "",
      bname: "",
      email: "",
      phoneNo: "",
      tickcount: 1,
      isSubmitted: false,
      tickcountlist: [],
      show: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
      vfOptions: {
        autoplay: true,
      },
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],
      vfImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
      ],
      vfEventImgs: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_1.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_2.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_3.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_4.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_5.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_6.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_7.png",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/stadiumImages/Stadium_8.png",
      ],
    };
  },
  components: {
    NavBar,
    SiteFooter,
    VueElementLoading,
    Doublelist,
    StripeCheckout,
  },
  methods: {
    datad1(listdata) {
      const seat = {
        1: 1200,
        2: 600,
        3: 400,
        4: 300,
        5: 240,
        6: 200,
        7: 171.4285714,
        8: 150,
        9: 133.33333,
        10: 120,
        11: 109.1,
      };
      const texts = [
        [3, 7, 14, 21, 28, 35, 42],
        [1, 5, 8, 15, 22, 29, 36, 43],
        [9, 16, 23, 30, 37, 44],
        [10, 17, 24, 31, 38],
        [11, 18, 25, 32, 39, 45],
        [2, 6, 12, 19, 26, 33, 40, 46],
        [4, 13, 20, 27, 34, 41, 47],
      ];
      const ydistances = [30, 0, 200, 220, 200, 0, 30];
      const nodes = [];
      for (let y = 1; y <= texts.length; y++) {
        for (let i = 1; i <= texts[y - 1].length; i++) {
          if (texts[y - 1][i - 1]) {
            nodes.push({
              x: 70 * y,
              y: 70 * i + ydistances[y - 1],
              val: seat[10],
              distance: y * 10,
              ydistance: i * 10,
              selected: false,
              text: texts[y - 1][i - 1],
              availableSeats: [],
            });
          }
        }
      }

      let recs = [];
      this.dict = {};
      listdata.forEach((r) => {
        recs = [...recs, ...r.availableSeating];
      });

      recs.forEach((r) => {
        const tableRecord = r.table.split("Table ")[1];
        const seatRecord = r.seat.split("Seat ")[1];

        if (!this.dict[tableRecord]) {
          this.dict[tableRecord] = [];
        }

        this.dict[tableRecord].push(Number(seatRecord - 1));
      });
      return nodes;
    },
    drawseatsd1(listdata) {
      const vm = this;
      const svg = window.d3
        .select("div#d1")
        .append("svg")
        .attr("width", "600px")
        .attr("height", "750px");
      const innerCircleRadius = 15;
      const outerCircleRadius = 15;
      const outerCircleRadius1 = 15;
      this.datad1(listdata).forEach((v) => {
        const originX = v.x + v.distance;
        const originY = v.y + v.ydistance;
        svg.append("circle").attr({
          cx: originX,
          cy: originY,
          r: innerCircleRadius,
          fill: v.selected ? "yellow" : "none",
          stroke: "black",
        });

        svg
          .append("text")
          .attr({
            x:
              originX -
              (Number(v.text) > 9 ? (Number(v.text) > 99 ? 12 : 9) : 3),
            y:
              originY +
              (Number(v.text) > 9 ? (Number(v.text) > 99 ? 5 : 5) : 5),
            "font-size": 15,
          })
          .text(v.text);

        for (let i = 0; i < 1200; i += v.val) {
          const chairOriginX1 =
            originX - 3 + (outerCircleRadius1 + 16) * Math.sin(i);
          const chairOriginY1 =
            originY + 2 - (outerCircleRadius1 + 16) * Math.cos(i);
          const chairOriginX = originX + (outerCircleRadius + 5) * Math.sin(i);
          const chairOriginY = originY - (outerCircleRadius + 5) * Math.cos(i);

          svg.append("circle").attr({
            cx: i == 1200 ? Math.ceil(chairOriginX + 1) : chairOriginX,
            cy: chairOriginY,
            r: 4,
            fill:
              vm.dict[v.text] &&
              vm.dict[v.text].includes(i > 0 ? Math.ceil(i / v.val) : 0)
                ? "#dab785"
                : "lightgrey",
          });

          svg
            .append("text")
            .attr({
              x: chairOriginX1,
              y: chairOriginY1,
              stroke: "black",
              "font-size": 10,
            })
            .text(() => {
              return i > 0 ? Math.ceil(i / v.val) + 1 : 1;
            });
        }
      });

      /// rects
      svg
        .append("rect")
        .attr("width", 50)
        .attr("height", 40)
        .attr("x", 290)
        .attr("fill", "none")
        .attr("stroke", "black");
      svg
        .append("text")
        .attr("x", 296.5)
        .attr("y", 25)
        .text("Stage")
        .attr("fill", "none")
        .attr("stroke", "black");

      svg
        .append("rect")
        .attr("width", 230)
        .attr("height", 150)
        .attr("x", 200)
        .attr("y", 60)
        .attr("fill", "none")
        .attr("stroke", "black");
    },
    listtopush(rr) {
      this.selectionList = [...rr];
    },
    uniq(list) {
      if (list && list.length > 0) {
        const uniqValues = {};
        list.forEach((v) => {
          uniqValues[v.table] = true;
        });

        return Object.keys(uniqValues);
      }

      return [];
    },
    uniq1(list) {
      if (list && list.length > 0) {
        const uniqValues = [];
        list.forEach((v) => {
          if (
            v.seat.split("Seat ")[1] == "1" ||
            v.seat.split("Seat ")[1] == "6"
          ) {
            uniqValues.push(
              v.table +
                " " +
                "Seat " +
                (v.seat.split("Seat ")[1] == "1" ? "1 to 5" : "6 to 10")
            );
          }
        });

        return uniqValues;
      }

      return [];
    },
    returnColor(v) {
      const vm = this;
      if (vm.dictlist[v]) {
        if (vm.dictlist[v] == "#ffff00") {
          return "#ffff00";
        } else {
          return vm.dictlist[v];
        }
      } else {
        return "lightgrey";
      }
    },
    datad2(listdata) {
      const seat = {
        1: 1200,
        2: 600,
        3: 400,
        4: 300,
        5: 240,
        6: 200,
        7: 171.4285714,
        8: 150,
        9: 133.33333,
        10: 120,
        11: 109.1,
      };

      const zx = [];
      const zobj = {
        0: {
          texts: [28, 27, 13, 12, 1],
          x: 70,
          y: 145,
          count: 5,
        },
        1: {
          texts: [43, 29, 26, 14, 11, 2],
          x: 140,
          y: 70,
          count: 6,
        },
        2: {
          texts: [42, 30, 25, 15, 10, 3],
          x: 210,
          y: 70,
          count: 6,
        },
        3: {
          texts: [41, 31, 24],
          x: 280,
          y: 70,
          count: 3,
        },
        4: {
          texts: [40, 32, 23],
          x: 350,
          y: 70,
          count: 3,
        },
        5: {
          texts: [39, 33, 22],
          x: 420,
          y: 70,
          count: 3,
        },
        6: {
          texts: [38, 34, 21, 16, 9, 4],
          x: 490,
          y: 70,
          count: 6,
        },
        7: {
          texts: [37, 35, 20, 17, 8, 5],
          x: 560,
          y: 70,
          count: 6,
        },
        8: {
          texts: [36, 19, 18, 7, 6],
          x: 630,
          y: 145,
          count: 5,
        },
      };
      for (let j = 0; j < 9; j++) {
        for (let k = 0; k < zobj[j].count; k++) {
          zx.push({
            x: zobj[j].x,
            y: zobj[j].y + k * 80,
            val: seat[8],
            distance: 10,
            ydistance: 10,
            text: zobj[j].texts[k],
          });
        }
      }

      let recs = [];
      this.dict = {};
      this.dictlist = {};

      listdata.forEach((r) => {
        r.availableSeating.forEach((r1) => {
          this.dictlist[r1.table.split("Table ")[1]] = "#dab785";

          //   if (r.ticketCode == "tk_c9sI6URakcKvxLN") {
          //     // "Crimson Level - $2,000" red
          //     this.dictlist[r1.table.split("Table ")[1]] = "#dab785";
          //   }

          //   if (r.ticketCode == "tk_rfrhYXdNpy8KMze") {
          //     // "Creme Level - $5,000" blue
          //     this.dictlist[r1.table.split("Table ")[1]] = "#dab785";
          //   }

          //   if (r.ticketCode == "tk_jay1QUDhTlzRvws") {
          //     // "Platinum Level - $10,000" blue
          //     this.dictlist[r1.table.split("Table ")[1]] = "#dab785";
          //   }

          //   if (r.ticketCode == "tk_V5oXHp3DhGTk5Tu") {
          //     // "Diamond Level - $15,000" blue
          //     this.dictlist[r1.table.split("Table ")[1]] = "#dab785";
          //   }

          //   if (r.ticketCode == "tk_pX0Lp69INGHCNiw") {
          //     // "General Admission - $175" yellow
          //     this.dictlist[r1.table.split("Table ")[1]] = "#dab785";
          //   }
        });

        recs = [...recs, ...r.availableSeating];
      });

      recs.forEach((r) => {
        const tableRecord = r.table.split("Table ")[1];
        const seatRecord = r.seat.split("Seat ")[1];

        if (!this.dict[tableRecord]) {
          this.dict[tableRecord] = [];
        }

        this.dict[tableRecord].push(Number(seatRecord) - 1);
      });

      return [...zx];
    },
    drawseatsdd(listdata) {
      const vm = this;
      const svg = window.d3
        .select("div#d2")
        .append("svg")
        .attr("width", "700px")
        .attr("height", "600px");
      const innerCircleRadius = 15;
      const outerCircleRadius = 15;
      const outerCircleRadius1 = 15;

      this.datad2(listdata).forEach((v) => {
        const originX = v.x + v.distance;
        const originY = v.y + v.ydistance;

        svg.append("circle").attr({
          cx: originX,
          cy: originY,
          r: innerCircleRadius,
          fill: v.selected ? "yellow" : "none",
          stroke: "black",
        });

        svg
          .append("text")
          .attr({
            x:
              originX -
              (Number(v.text) > 9 ? (Number(v.text) > 99 ? 12 : 9) : 3),
            y:
              originY +
              (Number(v.text) > 9 ? (Number(v.text) > 99 ? 5 : 5) : 5),
            "font-size": 15,
          })
          .text(v.text);

        for (let i = 0; i < 1200; i += v.val) {
          const chairOriginX1 =
            originX - 3 + (outerCircleRadius1 + 16) * Math.sin(i);
          const chairOriginY1 =
            originY + 2 - (outerCircleRadius1 + 16) * Math.cos(i);
          const chairOriginX = originX + (outerCircleRadius + 5) * Math.sin(i);
          const chairOriginY = originY - (outerCircleRadius + 5) * Math.cos(i);

          svg.append("circle").attr({
            cx: i == 1200 ? Math.ceil(chairOriginX + 1) : chairOriginX,
            cy: chairOriginY,
            r: 4,
            fill:
              vm.dict[v.text] &&
              vm.dict[v.text].includes(i > 0 ? Math.ceil(i / v.val) : 0)
                ? vm.returnColor(v.text)
                : "lightgrey",
          });

          svg
            .append("text")
            .attr({
              x: chairOriginX1,
              y: chairOriginY1,
              stroke: "black",
              "font-size": 10,
            })
            .text(() => {
              return i > 0 ? Math.ceil(i / v.val) + 1 : 1;
            });
        }
      });
      /// rects
      svg
        .append("rect")
        .attr("width", 200)
        .attr("height", 200)
        .attr("x", 260)
        .attr("y", 290)
        .attr("fill", "none")
        .attr("stroke", "black");
      svg
        .append("text")
        .attr("x", 340)
        .attr("y", 400)
        .text("")
        .attr("fill", "none")
        .attr("stroke", "black");

      svg
        .append("rect")
        .attr("width", 200)
        .attr("height", 90)
        .attr("x", 260)
        .attr("y", 500)
        .attr("fill", "none")
        .attr("stroke", "black");
      svg
        .append("text")
        .attr("x", 340)
        .attr("y", 550)
        .text("Stage")
        .attr("fill", "none")
        .attr("stroke", "black");
    },
    individualTick(list) {
      return list.filter((t) => this.individualsData.includes(t.ticketCode));
    },
    groupTickets(list) {
      return list.filter((t) => this.groupData.includes(t.ticketCode));
    },
    openZelleModel: function () {
      this.$refs.zellemodal.open();
    },
    onChangeTicket: function (evt) {
      const countsList = [];

      (this.eventTicketResult || []).forEach((selectedTicket, idx) => {
        if (selectedTicket.ticketName === evt.target.value) {
          this.ticketForm.ticketId = selectedTicket.ticketCode;
          const at = this.eventTicketResult[idx].availableTickets;
          const apr = this.eventTicketResult[idx].allowedPerRequest;

          for (let k = 0; k < (at > apr ? apr : at); k++) {
            countsList.push(k + 1);
          }
        }
      });

      this.countsListShow = countsList;
    },
    validateFileType: function (ee) {
      const fileName = document.getElementById("fileName").value;
      const idxDot = fileName.lastIndexOf(".") + 1;
      const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
        this.ticketForm.file = ee.target.files[0];
      }
    },
    openmodel: function (mod) {
      let idx = 0;
      this.fullname = "";
      this.phoneNo = "";
      this.bname = "";
      this.email = "";
      this.TicketsResult.forEach((i, i1) => {
        if (i.ticketCode == mod.ticketCode) {
          idx = i1;
        }
      });

      this.redeemData = null;
      this.selectionList = [];
      this.redeemPriceId = "";
      this.$refs.modal.open();
      this.selected = idx;
      if (
        this.TicketsResult[idx].availableSeating &&
        this.TicketsResult[idx].availableSeating[0] &&
        this.TicketsResult[idx].ticketCode != "tk_pX0Lp69INGHCNiw"
      ) {
        this.availSeating = this.TicketsResult[idx].availableSeating[0].table;
      } else {
        this.availSeating = "";
      }
      this.tickcountlist = [];
      for (
        let k = 0;
        k <
        (this.TicketsResult[idx].availableTickets >
        this.TicketsResult[idx].allowedPerRequest
          ? this.TicketsResult[idx].allowedPerRequest
          : this.TicketsResult[idx].availableTickets);
        k++
      ) {
        this.tickcountlist.push(k + 1);
      }
    },
    paynow: function () {
      /**
       * This function here is for  pay methods
       */
      this.isSubmitted = true;
      const vm = this;
      const payloadToPush = {
        priceId:
          this.TicketsResult[vm.selected].discount &&
          this.redeemPriceId.trim().length
            ? this.redeemPriceId
            : this.priceIds[this.TicketsResult[vm.selected].ticketCode],
        eventId: this.TicketsResult[vm.selected].eventId,
        ticketId: this.TicketsResult[vm.selected].ticketCode,
        totalTickets: this.tickcount,
        userName: this.fullname,
        userEmail: this.email.toLowerCase(),
      };

      if (vm.groupData.includes(vm.TicketsResult[vm.selected].ticketCode)) {
        payloadToPush.extraInfo["required_power_needs"] =
          vm.required_power_needs;
      }

      if (vm.availSeating.length > 0) {
        payloadToPush.additionalInfo = {
          type: "Seating",
          value: this.availSeating,
        };
      }

      if (vm.TicketsResult[vm.selected].ticketCode == "tk_Fx4F63eTUSiM2mf") {
        const seatRecord = [];
        this.selectionList.forEach((r) => {
          seatRecord.push(r.alias);
        });
        payloadToPush.totalTickets = seatRecord.length;
        payloadToPush.additionalInfo = {
          type: "Seating",
          value: seatRecord,
        };
      }

      if (vm.TicketsResult[vm.selected].ticketCode != "tk_Fx4F63eTUSiM2mf") {
        const seatsArr = [];
        const initalval = this.availSeating;
        seatsArr.push(initalval + " - Seat 1");
        seatsArr.push(initalval + " - Seat 2");
        seatsArr.push(initalval + " - Seat 3");
        seatsArr.push(initalval + " - Seat 4");
        seatsArr.push(initalval + " - Seat 5");
        seatsArr.push(initalval + " - Seat 6");
        seatsArr.push(initalval + " - Seat 7");
        seatsArr.push(initalval + " - Seat 8");
        seatsArr.push(initalval + " - Seat 9");
        seatsArr.push(initalval + " - Seat 10");
        payloadToPush.additionalInfo = {
          type: "Seating",
          value: seatsArr,
        };
      }

      //   payloadToPush.extraInfo = {
      //     business_name: this.bname.trim(),
      //     phone: this.phoneNo,
      //   };

      const req =
        "https://myle-19701.uc.r.appspot.com/api/" +
        ((this.TicketsResult[vm.selected].ticketType != "FREE" &&
          !this.redeemData) ||
        (this.redeemData && this.redeemData.price !== 0)
          ? "requested_tickets"
          : "requested_free_tickets");
      axios
        .post(req, payloadToPush)
        .then((re) => {
          if (re.data && re.data.Result && re.data.Result.sessionId) {
            vm.checkout.clientReferenceId = re.data.Result.sessionId;
            localStorage.setItem("refsId", vm.checkout.clientReferenceId);
            vm.$refs.checkoutRef.redirectToCheckout();
          } else if (
            vm.TicketsResult[vm.selected].ticketType === "FREE" ||
            (vm.redeemData && vm.redeemData.price === 0)
          ) {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            vm.fullname = "";
            vm.email = "";
            vm.isSubmitted = false;
          }

          setTimeout(() => {
            vm.isSubmitted = false;
          }, 60000);
          vm.reserveFormAccept = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.reserveFormAccept = false;
        });
    },
    fetchAllTickets: function () {
      /**
       * This function here is used to fetch tickets
       */

      const vm = this;
      this.isSubmitted = true;
      this.eventTicketResult = [];
      this.TicketsResult = [];
      const payloadToPush = {
        eventId: "cFUSQgpOrgfFSx",
      };
      axios
        .post(
          "https://myle-19701.uc.r.appspot.com/api/fetchSponsoredEventTicketsWithSeating",
          payloadToPush
        )
        .then((ticketsResult) => {
          if (
            ticketsResult &&
            ticketsResult.data &&
            ticketsResult.data.Result
          ) {
            vm.TicketsResult = ticketsResult.data.Result;
          }

          const objtmp = {
            tk_Fx4F63eTUSiM2mf: null,
            tk_6jAiz9ZUHELvpdv: null,
            tk_bTDSY8ie8I9yDOF: null,
            tk_tOVDlpp3dg4HMDw: null,
            tk_20ws90syyvl0sxT: null,
            tk_rM7htyFperIy5yi: null,
          };

          vm.TicketsResult.forEach((tr) => {
            objtmp[tr.ticketCode] = tr; // tr.status="EXPIRED";
          });
          vm.TicketsResult = Object.values(objtmp);
          vm.eventTicketResult = vm.TicketsResult;
          vm.isSubmitted = false;
          this.drawseatsd1(vm.eventTicketResult);
        })
        .catch(() => {
          vm.isSubmitted = false;
        });
    },
    redeem: function () {
      const vm = this;
      vm.isSubmitted = true;
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/redeem_promo_code",
        data: {
          eventId: this.TicketsResult[this.selected].eventId,
          ticketId: this.TicketsResult[this.selected].ticketCode,
          promoCode: this.redeempromo,
        },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.redeemPriceId = re.data.Result.discountedPriceId;
            vm.redeemData = re.data.Result;
          }
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.$toast.error("Promo code failed", { timeout: 4000 });
          vm.redeemPriceId = "";
          vm.redeemData = null;
        });
    },
    payfornow: function () {
      /**
       * This function here is for Reservation form
       */

      // holding reference
      const vm = this;
      vm.isSubmitted = true;

      // creating form Data
      const bodyFormData = new FormData();
      bodyFormData.append("fullName", this.ticketForm.name);
      bodyFormData.append("email", this.ticketForm.email.toLowerCase());
      bodyFormData.append("phoneNumber", this.ticketForm.phone);
      bodyFormData.append("ticketId", this.ticketForm.ticketId);
      bodyFormData.append("quantity", this.ticketForm.ticketCount);
      bodyFormData.append("paymentType", this.ticketForm.method);
      bodyFormData.append("paymentReceipt", this.ticketForm.file);

      // making api call
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/ticket_order_confirmation",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
          }
          // make get request again
          this.fetchAllTickets();
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.$toast.error("Ticket Confirmation failed", { timeout: 4000 });
        });
    },
  },
  created() {
    // if (this.$route.path == "/new-years-eve-2021-@-stadium") {
    //  this.$router.push("new-years-eve-2022-@-stadium");
    // }
  },
  mounted() {
    console.log("gsgsgfg");
    this.fetchAllTickets();
    const id = localStorage.getItem("refsId");
    if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "success"
    ) {
      this.$toast.success("Please check your provided email for tickets.", {
        timeout: 8000,
      });
      localStorage.removeItem("refsId");
    } else if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "failed"
    ) {
      this.$toast.error("Ticket reservation canceled", { timeout: 6000 });
      localStorage.removeItem("refsId");
    }
  },
  computed: {
    invalidTicketEmail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.ticketForm.email
      );
    },
    invalidTicketPhone() {
      return (
        (this.ticketForm.phone || "").match(/\d/g) &&
        (this.ticketForm.phone || "").trim().length >= 10
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invalidphone() {
      return (
        (this.phoneNo || "").match(/\d/g) &&
        (this.phoneNo || "").trim().length >= 10
      );
    },
  },

  metaInfo: {
    title: "MYLE - 2024 NEW YEAR EVE BOW TIES & DIAMONDS EXPERIENCE",
    meta: [
      {
        name: "description",
        content: "MYLE - 2024 NEW YEAR EVE BOW TIES & DIAMONDS EXPERIENCE",
      },

      {
        name: "keywords",
        content: "MYLE - 2024 NEW YEAR EVE BOW TIES & DIAMONDS EXPERIENCE",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content:
          "https://myle.com/2024-new-year-eve-bow-ties-diamonds-experience",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}

@media (max-width: 768px) {
  #redeem {
    text-align: center;
  }
}

@media (max-width: 922px) {
  #d2 {
    overflow-x: scroll;
  }
}
@media (min-width: 922.1px) {
  #d2 {
    overflow-x: hidden;
  }
}

.cred {
  background: #c07f00 !important;
  border: 1px solid #c07f00 !important;
}

.cgreen {
  background: #0ea293;
  border: 1px solid #0ea293 !important;
}

.cgold {
  background: #a19140;
  border: 1px solid #a19140 !important;
}

.bolded {
  font-weight: bold;
}

@media (max-width: 622px) {
  #d1 {
    overflow-x: scroll;
  }
}
@media (min-width: 622.1px) {
  #d1 {
    overflow-x: hidden;
  }
}
</style>
