<template>
  <div>
    <!-- <form
      style="visibility: hidden"
      method="post"
      :action="`${action}`"
      name="formAuthorizeNetTestPage"
    >
      <input type="text" name="token" v-model="actionValue" />
      <button id="btnContinue">OPEN CHECKOUT</button>
    </form> -->

    <!-- form starts -->
    <vue-element-loading
      :active="loader"
      text="Loading"
      :text-style="loadStyle"
      :is-full-screen="true"
      class="h-full background-overlay"
      spinner="bar-fade-scale"
      color="#ffffff"
    />

    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="checkout.publishKey"
      :sessionId="checkout.clientReferenceId"
    />

    <nu-modal id="ele1" ref="modal" :hideCloseButton="showcreateForm">
      <div>
        <div class="container-fluid" v-if="showcreateForm">
          <div class="row">
            <div class="col-md-12 mb-4">
              <div class="text-center">
                <span
                  class="w-auto d-inline-block"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    font-size: 2.2em;
                    color: #113a72;
                  "
                >
                  Required Information
                </span>
              </div>
            </div>
            <div class="col-md-6 mb-1">
              <label
                class=""
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;

                  font-size: 1.2em;
                  color: black;
                "
                >Emergency Contact Name</label
              >
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Enter emergency contanct full name"
                required
                v-model="eContactName"
              />
            </div>

            <div class="col-md-6 mb-1">
              <label
                class=""
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
                >Emergency Contact Phone</label
              >
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Enter emergency contacnt phone number"
                required
                v-model="eContactPhone"
              />
            </div>
            <div class="col-md-6 mb-1">
              <label
                class=""
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;

                  font-size: 1.2em;
                  color: black;
                "
                >Are you a Delta Employee</label
              >
              <select class="form-control mb-3" v-model="eType">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in [
                    'Delta employee',
                    'Delta family member or friend',
                    'No affiliation, member of the public',
                  ]"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div class="col-md-6 mb-3" v-if="eType === 'Delta employee'">
              <label
                class=""
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
                >Delta Division</label
              >
              <select class="form-control" v-model="edivision">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in [
                    'ACS',
                    'Cargo',
                    'Corporate',
                    'Flight Ops',
                    'Inflight Services',
                    'IT',
                    'OCC',
                    'Reservations and Customer Care',
                    'Sales',
                    'TechOps',
                  ]"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div class="col-md-6 mb-3" v-if="eType === 'Delta employee'">
              <label
                class=""
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
                >What Delta Station are you based at?</label
              >
              <select class="form-control" v-model="estation">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in [
                    'AMS',
                    'ATL',
                    'BOS',
                    'DTW',
                    'JKF',
                    'MCO',
                    'BRU',
                    'MSP',
                    'PDX',
                    'SEA',
                    'SLC',
                    'TPA',
                    'Other - US',
                    'Other - EMEA',
                  ]"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div class="col-md-6">
              <label
                class=""
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
                >I live in..</label
              >
              <select class="form-control" v-model="ecountry">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in ['the USA', 'Europe', 'Other']"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 mt-4 mb-3">
              <div class="">
                <span
                  class="w-auto d-inline-block"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    font-size: 1.5em;
                    color: #113a72;
                  "
                >
                  Bike Rental
                </span>
              </div>
            </div>

            <div class="col-md-6 mb-3">
              <label
                class=""
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
                >Plan on renting a bike for Ride 4 the Fight?</label
              >
              <select class="form-control" v-model="eAllowBike">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in ['Yes', 'No']"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div class="col-md-6 mt-4">
              <label
                class=""
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
                >To order bikes, click link -

                <a
                  href="https://book.peek.com/s/42c899c7-32f6-4d5f-aa5e-5cdbb61f900c/dzm2l"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Trek Atlanta Bike Rental
                </a>
              </label>
            </div>

            <div class="col-md-6 mb-3" v-if="eAllowBike === 'Yes'">
              <label
                class=""
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
                >Your body length in inches(in)?</label
              >
              <input
                type="text"
                class="form-control"
                placeholder="Enter body length (inches)"
                required
                v-model="ebodySize"
              />

              <label
                class=""
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 0.9em;
                  color: black;
                "
                >For example: Jennifer is 5'6" tall.
              </label>
            </div>

            <div class="col-md-6 mb-3" v-if="eAllowBike === 'Yes'">
              <label
                class=""
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
                >Frame size of your bike at home?</label
              >
              <input
                type="text"
                class="form-control"
                placeholder="Enter frame size (inches)"
                required
                v-model="eframeSize"
              />
              <label
                class=""
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 0.9em;
                  color: black;
                "
                >If you own a road bike at home and know what the frame size is,
                we can do a better job finding the best bike for you. For
                example: Jennifer's road bike frame size is 16in.
              </label>
            </div>
          </div>

          <div class="row" v-if="false">
            <div class="col-md-12 mt-4 mb-3">
              <div class="">
                <span
                  class="w-auto d-inline-block"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    font-size: 1.5em;
                    color: #113a72;
                  "
                >
                  Shoe Rental
                </span>
              </div>
            </div>

            <div class="col-md-6 mb-3">
              <label
                class=""
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
                >Would you like to rent shoes?</label
              >
              <select class="form-control" v-model="eAllowShow">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in ['Yes', 'No']"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div class="col-md-6 mb-3" v-if="eAllowShow === 'Yes'">
              <label
                class=""
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
                >What is your size in US/EU?</label
              >
              <input
                type="text"
                class="form-control"
                placeholder="Enter shoe size"
                required
                v-model="eShoeSize"
              />
            </div>

            <div class="col-md-6 mb-3" v-if="false">
              <label
                class=""
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
                >Shoes Rental Price</label
              >
              <select class="form-control" v-model="showPrice">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in ['Free', '$10.00']"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 mt-4 mb-3">
              <div class="">
                <span
                  class="w-auto d-inline-block"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    font-size: 1.5em;
                    color: #113a72;
                  "
                >
                  Dinner For Guest ($20 Per Guest)
                </span>
              </div>
            </div>

            <div class="col-md-6 mb-3">
              <label
                class=""
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
                >Dinner Guest</label
              >

              <select class="form-control mr-2" v-model="dinnerGuest">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in ['No', 'Yes']"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div class="col-md-6">
              <label
                v-if="dinnerGuest == 'Yes'"
                class=""
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
                >Number of Guest(s)</label
              >

              <select
                class="form-control"
                v-if="dinnerGuest == 'Yes'"
                v-model="personCount"
              >
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in [1, 2, 3, 4, 5]"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 mt-4 mb-3">
              <div class="">
                <span
                  class="w-auto d-inline-block"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    font-size: 1.5em;
                    color: #113a72;
                  "
                >
                  Merchandise
                </span>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <label
                class=""
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
                >Included cycling jersey
              </label>
              <select class="form-control" v-model="eMerchandise">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in [
                    'Small',
                    'Medium',
                    'Large',
                    'Extra Large',
                    '2XL',
                    '3XL',
                  ]"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
              <label
                class=""
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 0.9em;
                  color: black;
                "
                >All riders will receive a complimentary Ride 4 the Fight
                cycling jersey as a part of the registration. Sizes are unisex.
              </label>
            </div>

            <div class="col-md-6 mb-3" v-if="false">
              <label
                class=""
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
                >Will bring my own pedals?
              </label>
              <select class="form-control" v-model="ownPedals">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in ['Yes', 'No']"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div class="col-md-6 mt-4">
              <label
                class=""
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
                >Note: All riders must bring their own shoes, helmets and
                pedals.
              </label>
            </div>

            <div class="col-md-6 mb-3" v-if="false">
              <label
                class=""
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
                >Will bring my own helmet?
              </label>
              <select class="form-control" v-model="ownHelmet">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in ['Yes', 'No']"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div class="col-md-6 mb-3 mt-4">
              <label
                class=""
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
                >Would you like to purchase a Souvenir T-shirt?
              </label>
              <select class="form-control" v-model="tshirt">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in ['Yes', 'No']"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
              <label
                class=""
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 0.9em;
                  color: black;
                "
                >(This does not include the riding jersey)
              </label>
            </div>

            <div class="col-md-6 mb-3 mt-5" v-if="tshirt == 'Yes'">
              <label
                class=""
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
              >
                T-Shirt Size
              </label>
              <select class="form-control" v-model="tshirtSize">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in [
                    'Small',
                    'Medium',
                    'Large',
                    'Extra Large',
                    '2XL',
                    '3XL',
                  ]"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div class="col-md-12 mb-3 mt-4">
              <label
                class=""
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
                >Provide medical emergency information (if any)
              </label>
              <input
                type="text"
                class="form-control"
                placeholder=""
                required
                v-model="emedical"
              />
            </div>

            <div class="col-md-12 mt-4 text-center">
              <input
                type="button"
                @click="saveNewForm()"
                class="btn solid-btn-DeltaRideForFight"
                id="reserve"
                :disabled="
                  !eContactName.trim().length ||
                  !eContactPhone.trim().length ||
                  !invalidePhone
                "
                value="Submit Details"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                  font-weight: bold;
                "
              />

              <input
                type="button"
                @click="closenewForm()"
                class="btn solid-btn-DeltaRideForFight ml-4"
                id="reserve"
                value="Close"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                  font-weight: bold;
                "
              />
            </div>
          </div>
        </div>

        <div class="container-fluid" v-if="!showcreateForm">
          <div class="row">
            <!-- Ticket Cart -->
            <div class="col-md-12">
              <div class="text-center">
                <span
                  class="w-auto d-inline-block"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    font-size: 2.2em;
                    color: #113a72;
                  "
                >
                  Reserve Your Ride
                </span>
                <br />
                <span
                  class="w-auto d-inline-block"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    font-size: 1.1em;
                    color: #113a72;
                  "
                >
                  (Maximum 1 Rider per Registration)
                </span>
              </div>
            </div>

            <div class="col-md-6 mt-4">
              <span
                class="w-auto d-inline-block labels"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
              >
                First Name
              </span>
              <input
                type="text"
                placeholder="Enter your first name"
                class="form-control mb-3"
                v-model="userFirstName"
              />
            </div>
            <div class="col-md-6 mt-4">
              <span
                class="w-auto d-inline-block labels"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
              >
                Last Name
              </span>
              <input
                type="text"
                placeholder="Enter your last name"
                class="form-control mb-3"
                v-model="userLastName"
              />
            </div>
            <div class="col-md-6 mt-4">
              <span
                class="w-auto d-inline-block labels"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
              >
                Email Address
              </span>
              <input
                type="email"
                placeholder="Enter your email address"
                class="form-control mb-3"
                v-model="userEmail"
              />
            </div>
            <div class="col-md-6 mt-4">
              <span
                class="w-auto d-inline-block labels"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
              >
                Gender
              </span>
              <select class="form-control" v-model="userGender">
                <option
                  class="form-control"
                  v-bind:key="count"
                  v-for="count in ['Male', 'Female']"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>
            <div class="col-md-6 mt-4">
              <span
                class="w-auto d-inline-block labels"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
              >
                Date Of Birth
              </span>
              <input
                type="text"
                placeholder="mm/dd/yyyy"
                class="form-control mb-3"
                v-model="userDOB"
              />
            </div>
            <div class="col-md-6 mt-4">
              <span
                class="w-auto d-inline-block labels"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
              >
                Phone
              </span>

              <div class="d-flex">
                <input
                  type="text"
                  v-model="userPhone"
                  class="form-control mr-2"
                  placeholder="Enter your phone number"
                />
              </div>
            </div>
            <hr class="w-100 p-2" />
            <div class="col-md-12 mb-3">
              <div class="text-center">
                <span
                  class="w-auto d-inline-block"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    font-size: 2.2em;
                    color: #113a72;
                  "
                >
                  Select Route
                </span>
              </div>
              <br />
              <div class="">
                <span
                  class="w-auto d-inline-block"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1em;
                    color: #113a72;
                  "
                >
                  The rider should be able to maintain an average speed of about
                  14 to 15 mph over the distance they choose. The courses are
                  hilly with around 2000 feet of climbing on the 33 mile route
                  and 3700 feet on the 66 mile route. The rider should generally
                  have good endurance and be comfortable riding in a group.
                </span>
              </div>
            </div>

            <!-- Ticket Options -->
            <div class="col-md-4">
              <label
                class="mt-3 labels"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
                >Route Option</label
              >
              <select
                @change="dayChange('daylist')"
                class="form-control"
                v-model="daylist"
                :disabled="isTicketSelected"
              >
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in fetchRouteTickets(completeList, true)"
                  :value="count"
                >
                  {{ count.ticketName }}
                </option>
              </select>
            </div>

            <div class="col-md-4 mb-3">
              <label
                class="mt-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
                >Number of Rider(s)</label
              >
              <select class="form-control" v-model="tickcount">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in tickcountlist"
                  :value="count"
                >
                  {{ count }}
                </option>

                <option
                  disabled
                  v-if="
                    tickcountlist.length == 0 &&
                    initvalue[daylist.ticketCode] != 0
                  "
                >
                  No more tickets
                </option>

                <option disabled v-if="initvalue[daylist.ticketCode] == 0">
                  Sold Out
                </option>
              </select>
            </div>

            <div class="col-md-4 mt-4 text-center">
              <p>
                <b
                  class="font-weight-bold"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.2em;
                    color: black;
                  "
                  >Per Person Price:</b
                >
                ${{
                  ttypelist && ttypelist.price
                    ? ttypelist.price.toFixed(2)
                    : "0.00"
                }}
              </p>
              <p>
                <b
                  class="font-weight-bold"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.2em;
                    color: black;
                  "
                  >Total Price:</b
                >
                ${{
                  (
                    (ttypelist && ttypelist.price ? ttypelist.price || 0 : 0) *
                    (tickcount || 0)
                  ).toFixed(2)
                }}
              </p>
            </div>

            <div class="col-md-12 text-center my-4">
              <input
                type="button"
                @click="addtocart('daylist')"
                class="btn solid-btn-DeltaRideForFight m-auto mr-2"
                id="reserve"
                :disabled="!daylist || !tickcount"
                value="Add To Cart"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                  font-weight: bold;
                "
              />
            </div>

            <hr class="w-100 p-2" />
            <!-- Ticket Options -->

            <!-- Donation Options -->
            <div class="col-md-12 mb-3">
              <div class="text-center">
                <span
                  class="w-auto d-inline-block"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    font-size: 2.2em;
                    color: #113a72;
                  "
                >
                  Select Donation(s)
                </span>
              </div>
            </div>

            <div class="col-md-4">
              <label
                class="mt-3 labels"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
                >Donation Type</label
              >
              <select
                @change="dayChange('daylist1')"
                class="form-control"
                v-model="daylist1"
              >
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in fetchRouteTickets(completeList, false)"
                  :value="count"
                >
                  {{ count.ticketName }}
                </option>
              </select>
            </div>

            <div class="col-md-4 mb-3">
              <label
                class="mt-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
                >Number of Donation(s)</label
              >
              <select class="form-control" v-model="tickcount1">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in tickcountlist1"
                  :value="count"
                >
                  {{ count }}
                </option>

                <option
                  disabled
                  v-if="
                    tickcountlist1.length == 0 &&
                    initvalue[daylist1.ticketCode] != 0
                  "
                >
                  No more tickets
                </option>

                <option disabled v-if="initvalue[daylist1.ticketCode] == 0">
                  Sold Out
                </option>
              </select>
            </div>

            <div class="col-md-4 mt-4 text-center">
              <p>
                <b
                  class="font-weight-bold"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.2em;
                    color: black;
                  "
                >
                  Donation Amount:</b
                >
                ${{
                  ttypelist1 && ttypelist1.price
                    ? ttypelist1.price.toFixed(2)
                    : "0.00"
                }}
              </p>
              <p>
                <b
                  class="font-weight-bold"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.2em;
                    color: black;
                  "
                  >Total Amount:</b
                >
                ${{
                  (
                    (ttypelist1 && ttypelist1.price
                      ? ttypelist1.price || 0
                      : 0) * (tickcount1 || 0)
                  ).toFixed(2)
                }}
              </p>
            </div>

            <div class="col-md-12 text-center my-4">
              <input
                type="button"
                @click="addtocart('daylist1')"
                class="btn solid-btn-DeltaRideForFight m-auto mr-2"
                id="reserve"
                :disabled="!daylist1 || !tickcount1"
                value="Add To Cart"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                  font-weight: bold;
                "
              />
            </div>

            <hr class="w-100 p-2" />

            <!-- Cart details -->
            <div class="col-md-12">
              <div class="text-center">
                <span
                  class="w-auto d-inline-block"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    font-size: 2.2em;
                    color: #113a72;
                  "
                  >Cart Details</span
                >
              </div>
            </div>

            <div class="col-md-12 mt-4">
              <table style="width: 100%">
                <thead>
                  <tr>
                    <th
                      style="
                        font-family: 'nunito', sans-serif;
                        font-weight: 600;
                        font-size: 1.3em;
                        color: black;
                      "
                    >
                      Selected item(s)
                    </th>
                    <th
                      style="
                        text-align: center;
                        font-family: 'nunito', sans-serif;
                        font-weight: 600;
                        font-size: 1.3em;
                        color: black;
                      "
                    >
                      Quantity
                    </th>
                    <th
                      style="
                        text-align: center;
                        font-family: 'nunito', sans-serif;
                        font-weight: 600;
                        font-size: 1.3em;
                        color: black;
                      "
                    >
                      Price
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(r, i) in selectedCarts" v-bind:key="r">
                    <td
                      style="
                        width: 60%;
                        font-family: 'nunito', sans-serif;
                        font-weight: 500;
                        font-size: 1.2em;
                        color: black;
                      "
                    >
                      <p>{{ r.daylist.ticketName }}</p>
                    </td>
                    <td
                      style="
                        width: 15%;
                        text-align: center;
                        font-family: 'nunito', sans-serif;
                        font-weight: 500;
                        font-size: 1.2em;
                        color: black;
                      "
                    >
                      <p>{{ r.tttcount }}</p>
                    </td>
                    <td
                      style="
                        width: 15%;
                        text-align: center;
                        font-family: 'nunito', sans-serif;
                        font-weight: 500;
                        font-size: 1.2em;
                        color: black;
                      "
                    >
                      <p>${{ r.price.toFixed(2) }}</p>
                    </td>
                    <td
                      style="
                        width: 10%;
                        text-align: right;
                        cursor: pointer;
                        font-family: 'Arial', sans-serif;
                        font-size: 1.1em;
                        color: rgb(34, 33, 33);
                      "
                    >
                      <p>
                        <span
                          @click="deleteRec(r, i)"
                          style="
                            color: #d90606;
                            display: block;
                            font-weight: bold;
                            font-family: 'Quicksand';
                          "
                        >
                          ❌</span
                        >
                      </p>
                    </td>
                  </tr>
                  <tr v-for="r in pricedRecords()" v-bind:key="r">
                    <td
                      style="
                        width: 60%;
                        font-family: 'nunito', sans-serif;
                        font-weight: 500;
                        font-size: 1.2em;
                        color: black;
                      "
                    >
                      <p>{{ r.data2.ticketName }}</p>
                    </td>
                    <td
                      style="
                        width: 15%;
                        text-align: center;
                        font-family: 'nunito', sans-serif;
                        font-weight: 500;
                        font-size: 1.2em;
                        color: black;
                      "
                    >
                      <p>{{ r.data.personCount }}</p>
                    </td>
                    <td
                      style="
                        width: 15%;
                        text-align: center;
                        font-family: 'nunito', sans-serif;
                        font-weight: 500;
                        font-size: 1.2em;
                        color: black;
                      "
                    >
                      <p>
                        ${{
                          (r.data.personCount || 0) * r.data2.price.toFixed(2)
                        }}
                      </p>
                    </td>
                    <td
                      style="
                        width: 10%;
                        text-align: right;
                        cursor: pointer;
                        font-family: 'Arial', sans-serif;
                        font-size: 1.1em;
                        color: rgb(34, 33, 33);
                      "
                    ></td>
                  </tr>

                  <tr v-if="selectedCarts.length == 0">
                    <td
                      colSpan="4"
                      style="
                        padding-top: 10px;
                        font-family: 'Arial', sans-serif;
                        font-size: 1.1em;
                      "
                    >
                      <p class="text-center">No Tickets</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <hr class="w-100" />
            <div
              class="col-md-12 mt-1"
              v-if="nonDonations(sections).length > 0"
            >
              <div class="text-center">
                <span
                  class="ml-1 w-auto d-inline-block"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    font-size: 2.2em;
                    color: #113a72;
                  "
                  >Required Information</span
                >
              </div>
            </div>

            <div
              class="col-md-12 mt-4"
              v-bind:key="rec1.ticketCode"
              v-for="rec1 in nonDonations(sections)"
            >
              <label
                style="
                  pointer-events: all;
                  cursor: pointer;
                  font-family: 'nunito', sans-serif;
                  font-weight: 900;
                  font-size: 1.3em;
                  color: #e28332;
                "
                >{{ rec1[0].ticketName }}</label
              >
              <table style="width: 100%">
                <thead>
                  <tr>
                    <th
                      style="
                        font-family: 'nunito', sans-serif;
                        font-weight: 700;
                        font-size: 1.3em;
                        color: black;
                      "
                    >
                      Rider
                    </th>
                    <th
                      style="
                        text-align: center;
                        font-family: 'nunito', sans-serif;
                        font-weight: 700;
                        font-size: 1.3em;
                        color: black;
                      "
                    >
                      Status
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(r, i) in rec1" v-bind:key="r">
                    <td
                      style="
                        font-family: 'nunito', sans-serif;
                        font-weight: 500;
                        font-size: 1.05em;
                        color: black;
                      "
                    >
                      <p>Rider #{{ i + 1 }}</p>
                    </td>
                    <td
                      style="
                        text-align: center;
                        font-family: 'nunito', sans-serif;
                        font-weight: 500;
                        font-size: 1.05em;
                        color: black;
                      "
                    >
                      <p>
                        {{
                          r.data ? "Information Submitted" : "Details Required"
                        }}
                      </p>
                    </td>
                    <td style="text-align: right; cursor: pointer">
                      <input
                        type="button"
                        @click="newForm(r)"
                        class="btn solid-btn-DeltaRideForFight m-auto mr-2"
                        id="reserve"
                        value="Edit Details"
                        style="
                          pointer-events: all;
                          cursor: pointer;
                          font-family: 'nunito', sans-serif;
                          font-weight: 700;
                          font-size: 1.2em;
                          font-weight: bold;
                        "
                      />
                    </td>
                  </tr>
                  <tr v-if="selectedCarts.length == 0">
                    <td
                      colSpan="4"
                      style="
                        padding-top: 10px;
                        font-family: 'Arial', sans-serif;
                        font-size: 1.1em;
                      "
                    >
                      <p class="text-center">No Tickets</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- Cart details -->
            <div
              class="row align-items-center justify-content-between mt-5"
              style="margin: auto; margin-top: 20px; width: 100% !important"
            >
              <div class="col-lg-12 col-md-12">
                <p
                  class="mb-0"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    font-size: 1.7em;
                    color: #113a72;
                  "
                >
                  Waiver and Release of Liability
                </p>

                <div style="height: 250px; overflow: auto">
                  <p
                    class=""
                    style="
                      font-family: 'nunito', sans-serif;
                      font-weight: 500;
                      font-size: 1.1em;
                      color: black;
                      width: 100%;
                    "
                  >
                    <br />
                    In consideration of accepting this entry I, the signee,
                    intending to be legally bound, hereby, for myself, my heirs,
                    executors and administrators, assume all risks of injury
                    incidental to my participation in any activities or programs
                    and waive and release any and all rights and claims for
                    damages I may have against Delta Air Lines, any organization
                    which donates the use of its property for the event and
                    their respective directors, officers, employees, agents,
                    contractors, officials, workers, representatives, sponsors,
                    successors or assigns for any and all injuries, damages,
                    costs or expenses that I may suffer or incur, whether
                    foreseen or unforeseen as a result of my participation in
                    this event.

                    <br /><br />
                    I acknowledge that I have carefully read this waiver and
                    release. I represent to the TechOps Event team that I am
                    physically fit to perform those activities which I may
                    undertake at the Ride 4 the fight event and that I am solely
                    responsible for all health risks associated with such
                    activities. By signing this waiver and release of liability,
                    I have reviewed and concur that all information in this
                    Agreement is correct to the best of my knowledge and belief.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-md-12 mt-4">
              <input type="checkbox" v-model="reserveFormAccept" />
              I agree to the waiver and release of liability stated above.
            </div>

            <div class="col-md-12 text-center mt-4">
              <button
                type="submit"
                @click="paynowcustom()"
                :disabled="
                  !userFirstName.trim().length ||
                  !userLastName.trim().length ||
                  !userEmail.trim().length ||
                  !userDOB.trim().length ||
                  !invalidEmail ||
                  !invalidPhone ||
                  !selectedCarts.length ||
                  !reserveFormAccept ||
                  !dobtest ||
                  !completed
                "
                class="btn solid-btn-DeltaRideForFight m-auto mr-2"
                id="reserve"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;

                  font-size: 1.2em;
                  font-weight: bold;
                "
              >
                Register
              </button>
            </div>
          </div>
        </div>
      </div>
    </nu-modal>
    <!-- form ends -->

    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <!-- <div class="row">
          <div class="col-md-12">
            <nav-bar />
          </div>
        </div> -->
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_AlbertsonsVISADallas_V1.png"
            style="width: 100%; max-width: 100%; height: auto"
          />
        </div>
      </div>
      <!-- banner section ends
      <div
        class="row w-100 m-0 promo-section MYLE-lite-green"
        style="background-color: #113a72"
      >
        <div class="col-lg-12 col-md-12">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center"
                style="
                  font-family: 'Outfit', sans-serif;
                  font-size: 3em;
                  font-weight: 700;
                  color: #e28332;
                "
              >
                Backyard Bash With

                <span
                  style="
                    font-family: 'Caveat', sans-serif;
                    font-weight: 700;
                    font-size: 1.3em;
                    color: #e28332;
                  "
                >
                  The Signature SELECT Life</span
                >
                – Dallas
              </h1>
            </div>
          </div>
        </div>
      </div> -->
      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between lessSideMargin"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="col-md-12">
            <div class="row mt-3">
              <div class="col-md-1 col-md-1"></div>
              <div class="col-md-10 col-md-10">
                <!-- <h1
                  class="text-center"
                  style="
                    font-family: 'Caveat', sans-serif;
                    font-weight: 700;
                    font-size: 3.5em;
                    color: #0071cf;
                  "
                >
                  backyard bash

                  <span
                    style="
                      font-family: 'Outfit', sans-serif;
                      font-weight: 700;
                      color: #0071cf;
                    "
                  >
                    With The Signature SELECT Life <br />
                    − Dallas −</span
                  >
                </h1> -->

                <!-- <br /> -->
                <p
                  class="text-center"
                  style="
                    font-family: 'Outfit', sans-serif;
                    font-size: 1.7em;
                    font-weight: 400;
                    font-optical-sizing: auto;
                    color: #635694;
                  "
                >
                  Join us Saturday for an afternoon of free, family fun with
                  Signature SELECT, a Tom Thumb and Albertsons exclusive brand!
                  Guests will enjoy sampling a variety of delightful Signature
                  SELECT products, games, photo ops, cooking demos, a meet and
                  greet with a football legend, and giveaways (while supplies
                  last).
                </p>

                <p
                  class="text-center"
                  style="
                    font-family: 'Outfit', sans-serif;
                    font-size: 2em;
                    font-weight: 600;
                    font-optical-sizing: auto;
                    color: #635694;
                  "
                >
                  Attendance is free and registration is not required.
                </p>
              </div>
              <div class="col-md-1 col-md-1"></div>
            </div>

            <hr />

            <div class="row mt-5">
              <div class="col-md-4 col-md-4 text-center"></div>
              <div class="col-md-4 col-md-4 text-center">
                <a
                  href="https://m.facebook.com/events/1020504859730096?mibextid=RQdjqZ"
                  target="_blank"
                  rel="noopener noreferrer"
                  style="color: #e9873a"
                >
                  <h1
                    class="text-center"
                    style="
                      font-family: 'Outfit', sans-serif;
                      font-size: 2.8em;
                      font-weight: 600;
                      color: #0071cf;
                    "
                  >
                    RSVP on Facebook
                  </h1>
                  <br />
                  <p
                    class="text-center"
                    style="
                      font-family: 'Outfit', sans-serif;
                      font-size: 1.6em;
                      color: #1c1b1a;
                    "
                  >
                    <img
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/icon_facebook_event.png"
                      style="width: 20%"
                    />
                    <br />
                    <span
                      style="
                        font-family: 'Outfit', sans-serif;
                        font-weight: bold;
                      "
                      >Saturday, September 21, 2024 </span
                    ><br />
                    11:00am - 3:00pm CT
                  </p>
                </a>
              </div>
              <div class="col-md-4 col-md-4"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="row w-100 m-0 promo-section" style="background: #0071cf">
        <div class="col-lg-12 col-md-12">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center"
                style="
                  font-family: 'Outfit', sans-serif;
                  font-size: 2.8em;
                  font-weight: 600;
                  color: white;
                "
              >
                Venue
              </h1>
            </div>
          </div>
        </div>
      </div>

      <div
        class="row align-items-center justify-content-between lessSideMargin"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="text-center">
            <p
              class="w-auto d-inline-block mb-2"
              style="
                font-family: 'Outfit', sans-serif;
                font-weight: 600;
                font-size: 2.7em;
                color: #e9873a;
              "
            >
              <a
                href="https://maps.app.goo.gl/LQowDAxJziteW3FCA"
                target="_blank"
                rel="noopener noreferrer"
                style="color: #e9873a"
              >
                Klyde Warren Park
              </a>
            </p>

            <p
              class=""
              style="
                font-family: 'nunito', sans-serif;
                font-size: 1.4em;
                color: rgb(34, 33, 33);
              "
            >
              <img
                class="mb-0"
                src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/icon_location_alberson.png"
                style="width: 1.6em"
              />

              <span class="bolded"> Address:</span>

              <a
                href="https://maps.app.goo.gl/LQowDAxJziteW3FCA"
                target="_blank"
                rel="noopener noreferrer"
                style="color: #635694"
              >
                2012 Woodall Rodgers Fwy, Dallas, TX 75201
              </a>
            </p>
          </div>

          <div class="row mt-2">
            <div class="col-md-3 col-md-3"></div>

            <div class="col-md-6 col-md-6">
              <div class="text-center">
                <a
                  href="https://maps.app.goo.gl/LQowDAxJziteW3FCA"
                  target="_blank"
                  rel="noopener noreferrer"
                  style="color: #e28332"
                >
                  <img
                    src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_venue_albertsons_visa_dallas.png"
                    class="img-fluid"
                    style="border-radius: 6%; width: 70%"
                  />
                </a>
              </div>
            </div>
            <div class="col-md-3 col-md-3"></div>
          </div>
        </div>
      </div>

      <br /><br />
    </div>
    <!-- <site-footer :show-subscribe="true" /> -->
  </div>
</template>

<script>
// import NavBar from "../../views/commons/NavBar";
// import SiteFooter from "../../views/index-five/SiteFooter";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";
import VueElementLoading from "vue-element-loading";

export default {
  name: "newyear",
  data() {
    return {
      priceedTicket: [],
      donations: [
        "tk_6WOnHlPSmg0YCyE",
        "tk_qL2Y12GHnTQutkV",
        "tk_J7jkT17N37dhvA2",
        "tk_MdfWQctxycQPaSg",
        "tk_F5REPaxIg3T2EeZ",
      ],
      pricemap: {
        tk_RJHGlyPtB0UCyRX: "price_1PeJ0ED0vTZ4QB9crsbIe8G4",
        tk_I3hMzALg0a2HLEJ: "price_1PeJ0cD0vTZ4QB9cs3Wmt6O8",
        tk_6WOnHlPSmg0YCyE: "price_1PeJ0xD0vTZ4QB9clZbWxxUa",
        tk_qL2Y12GHnTQutkV: "price_1PeJ0pD0vTZ4QB9cmw3Smc21",
        tk_J7jkT17N37dhvA2: "price_1PeJ1AD0vTZ4QB9cXSjuy4AQ",
        tk_MdfWQctxycQPaSg: "price_1PeJ1KD0vTZ4QB9c3V96AMGr",
        tk_F5REPaxIg3T2EeZ: "price_1PeJ1PD0vTZ4QB9cLxbMfI0r",
      },
      showcreateForm: false,
      eAllowBike: "Yes",
      ebodySize: "",
      eframeSize: "",
      showPrice: "No",
      dinnerGuest: "No",
      personCount: 1,
      eContactName: "",
      emedical: "",
      eContactPhone: "",
      eType: "Delta employee",
      edivision: "ACS",
      estation: "AMS",
      ecountry: "the USA",
      eMerchandise: "Small",
      ownPedals: "Yes",
      tshirt: "No",
      tshirtSize: "Small",
      ownHelmet: "Yes",
      eAllowShow: "Yes",
      eShoeSize: "",
      sections: [],
      editRecord: null,
      userFirstName: "",
      userLastName: "",
      userEmail: "",
      userGender: "Male",
      userDOB: "",
      userPhone: "",
      daylist: {},
      daylist1: {},
      loader: false,
      completeList: [],
      initvalue: {},
      action: "",
      actionValue: "",
      cntholder: {},
      selectedCarts: [],
      ttypelist: null,
      ttypelist1: null,
      reserveFormAccept: false,
      countsListShow: [],
      eventTicketResult: [],
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      tickcount: 1,
      tickcount1: 1,
      isSubmitted: false,
      tickcountlist: [],
      tickcountlist1: [],
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
    };
  },
  components: {
    VueElementLoading,
    StripeCheckout,
  },
  methods: {
    changeFavicon(url) {
      console.log("warda", url);
      var link = document.querySelectorAll("link[rel~='icon']");
      if (link && link.length) {
        link.forEach((r) => {
          r.remove();
        });
      }
      link = null;
      var applink = document.querySelectorAll("link[rel~='apple-touch-icon']");
      if (applink && applink.length) {
        applink.forEach((r) => {
          r.remove();
        });
      }
      applink = null;

      var mapplink = document.querySelectorAll("link[rel~='mask-icon']");
      if (mapplink && mapplink.length) {
        mapplink.forEach((r) => {
          r.remove();
        });
      }

      mapplink = null;

      setTimeout(() => {
        const mapplink1 = document.createElement("link");
        mapplink1.rel = "mask-icon";
        mapplink1.color = "#5bbad5";
        mapplink1.href = url + "safari-pinned-tab.svg?v=2";
        document.head.appendChild(mapplink1);

        const applink1 = document.createElement("link");
        applink1.rel = "apple-touch-icon";
        applink1.sizes = "180x180";
        applink1.href = url + "apple-touch-icon.png?v=2";
        document.head.appendChild(applink1);

        const applink12 = document.createElement("link");
        applink12.rel = "apple-touch-icon";
        applink12.sizes = "152x152";
        applink12.href = url + "apple-touch-icon.png?v=2";
        document.head.appendChild(applink12);

        const link1 = document.createElement("link");
        link1.rel = "icon";
        link1.sizes = "16x16";
        link1.href = url + "favicon-16x16.png?v=2";
        document.head.appendChild(link1);

        const link96 = document.createElement("link");
        link96.rel = "icon";
        link96.sizes = "96x96";
        link96.href = url + "favicon-96x96.png?v=2";
        document.head.appendChild(link96);

        const link192 = document.createElement("link");
        link192.rel = "icon";
        link192.sizes = "192x192";
        link192.href = url + "android-icon-192x192.png?v=2";
        document.head.appendChild(link192);

        const link180 = document.createElement("link");
        link180.rel = "apple-touch-icon";
        link180.sizes = "180x180";
        link180.href = url + "apple-icon-180x180.png?v=2";
        document.head.appendChild(link180);

        const link152 = document.createElement("link");
        link152.rel = "apple-touch-icon";
        link152.sizes = "152x152";
        link152.href = url + "apple-icon-152x152.png?v=2";
        document.head.appendChild(link152);

        const link144 = document.createElement("link");
        link144.rel = "apple-touch-icon";
        link144.sizes = "144x144";
        link144.href = url + "apple-icon-144x144.png?v=2";
        document.head.appendChild(link144);

        const link120 = document.createElement("link");
        link120.rel = "apple-touch-icon";
        link120.sizes = "120x120";
        link120.href = url + "apple-icon-120x120.png?v=2";
        document.head.appendChild(link120);

        const link114 = document.createElement("link");
        link114.rel = "apple-touch-icon";
        link114.sizes = "114x114";
        link114.href = url + "apple-icon-114x114.png?v=2";
        document.head.appendChild(link114);

        const link76 = document.createElement("link");
        link76.rel = "apple-touch-icon";
        link76.sizes = "76x76";
        link76.href = url + "apple-icon-76x76.png?v=2";
        document.head.appendChild(link76);

        const link72 = document.createElement("link");
        link72.rel = "apple-touch-icon";
        link72.sizes = "72x72";
        link72.href = url + "apple-icon-72x72.png?v=2";
        document.head.appendChild(link72);

        const link60 = document.createElement("link");
        link60.rel = "apple-touch-icon";
        link60.sizes = "60x60";
        link60.href = url + "apple-icon-60x60.png?v=2";
        document.head.appendChild(link60);

        const link57 = document.createElement("link");
        link57.rel = "apple-touch-icon";
        link57.sizes = "57x57";
        link57.href = url + "apple-icon-57x57.png?v=2";
        document.head.appendChild(link57);

        const link2 = document.createElement("link");
        link2.rel = "icon";
        link2.sizes = "32x32";
        link2.href = url + "favicon-32x32.png?v=2";
        document.head.appendChild(link2);

        const link3 = document.createElement("link");
        link3.rel = "icon";
        link3.href = url + "favicon.ico?v=2";
        document.head.appendChild(link3);
      }, 300);
    },

    nonDonations(list) {
      return list.filter((r) => r[0].t != "daylist1");
    },
    fetchRouteTickets(list, isRoute) {
      const routes = ["tk_RJHGlyPtB0UCyRX", "tk_I3hMzALg0a2HLEJ"];
      const donationn = [
        "tk_6WOnHlPSmg0YCyE",
        "tk_qL2Y12GHnTQutkV",
        "tk_J7jkT17N37dhvA2",
        "tk_MdfWQctxycQPaSg",
        "tk_F5REPaxIg3T2EeZ",
      ];

      if (isRoute) {
        return list.filter((r) => routes.includes(r.ticketCode));
      }

      return list.filter((r) => donationn.includes(r.ticketCode));
    },
    scrolldiv() {
      const collection = document.getElementsByClassName(
        "nu-modal has-content is-visible"
      );
      console.log(collection);
      collection[0].scrollIntoView();
    },
    findPosition(obj) {
      var currenttop = 0;
      if (obj.offsetParent) {
        do {
          currenttop += obj.offsetTop;
        } while ((obj = obj.offsetParent));
        return [currenttop];
      }
    },
    pricedRecords() {
      const list = [];
      this.sections.forEach((r) => {
        r.forEach((re) => {
          if (re.data2) {
            // for (let p = 0; p < re.data.personCount; p++) {
            list.push(re);
            //}
          }
        });
      });
      return list;
    },
    opencart() {
      this.userFirstName = "";
      this.userLastName = "";
      this.userEmail = "";
      this.userGender = "Male";
      this.userDOB = "";
      this.userPhone = "";
      this.daylist = {};
      this.daylist1 = {};
      this.initvalue = {};
      this.ttypelist = null;
      this.ttypelist1 = null;
      this.cntholder = {};
      this.tickcount = 1;
      this.tickcount1 = 1;
      this.selectedCarts = [];
      this.tickcountlist = [];
      this.tickcountlist1 = [];
      this.sections = [];
      this.section = [];

      this.showcreateForm = false;
      this.eAllowBike = "Yes";
      this.ebodySize = "";
      this.eframeSize = "";
      this.showPrice = "No";
      this.dinnerGuest = "No";
      this.personCount = 1;
      this.eContactName = "";
      this.emedical = "";
      this.eContactPhone = "";
      this.eType = "Delta employee";
      this.edivision = "ACS";
      this.estation = "AMS";
      this.ecountry = "the USA";
      this.eMerchandise = "Small";
      this.ownPedals = "Yes";
      this.tshirt = "No";
      this.tshirtSize = "Small";
      this.ownHelmet = "Yes";
      this.eAllowShow = "Yes";
      this.eShoeSize = "";
      this.editRecord = null;
      this.dayChange("daylist");
      this.dayChange("daylist1");
      this.$refs.modal.open();
    },
    newForm(param) {
      if (param.data) {
        this.eAllowBike = param.data.eAllowBike;
        this.ebodySize = param.data.ebodySize;
        this.eframeSize = param.data.eframeSize;
        this.showPrice = param.data.showPrice;
        this.dinnerGuest = param.data.dinnerGuest;
        this.personCount = param.data.personCount;
        this.eContactName = param.data.eContactName;
        this.emedical = param.data.emedical;
        this.eContactPhone = param.data.eContactPhone;
        this.eType = param.data.eType;
        this.edivision = param.data.edivision;
        this.estation = param.data.estation;
        this.ecountry = param.data.ecountry;
        this.eMerchandise = param.data.eMerchandise;
        this.ownPedals = param.data.ownPedals;
        this.tshirt = param.data.tshirt;
        this.tshirtSize = param.data.tshirtSize;
        this.ownHelmet = param.data.ownHelmet;
        this.eAllowShow = param.data.eAllowShow;
        this.eShoeSize = param.data.eShoeSize;
      } else {
        this.eAllowBike = "Yes";
        this.ebodySize = "";
        this.eframeSize = "";
        this.showPrice = "No";
        this.dinnerGuest = "No";
        this.personCount = 1;
        this.eContactName = "";
        this.emedical = "";
        this.eContactPhone = "";
        this.eType = "Delta employee";
        this.edivision = "ACS";
        this.estation = "AMS";
        this.ecountry = "the USA";
        this.eMerchandise = "Small";
        this.ownPedals = "Yes";
        this.tshirt = "No";
        this.tshirtSize = "Small";
        this.ownHelmet = "Yes";
        this.eAllowShow = "Yes";
        this.eShoeSize = "";
      }

      this.editRecord = param;
      this.showcreateForm = true;
      setTimeout(() => {
        this.scrolldiv();
      }, 100);
    },
    closenewForm() {
      this.showcreateForm = false;
    },
    saveNewForm() {
      this.editRecord.data = {};
      this.editRecord.data.eAllowBike = this.eAllowBike;
      this.editRecord.data.ebodySize = this.ebodySize;
      this.editRecord.data.eframeSize = this.eframeSize;
      this.editRecord.data.showPrice = this.showPrice;
      this.editRecord.data.dinnerGuest = this.dinnerGuest;
      this.editRecord.data.personCount = this.personCount;
      this.editRecord.data.eContactName = this.eContactName;
      this.editRecord.data.emedical = this.emedical;
      this.editRecord.data.eContactPhone = this.eContactPhone;
      this.editRecord.data.eType = this.eType;
      this.editRecord.data.edivision = this.edivision;
      this.editRecord.data.estation = this.estation;
      this.editRecord.data.ecountry = this.ecountry;
      this.editRecord.data.eMerchandise = this.eMerchandise;
      this.editRecord.data.ownPedals = this.ownPedals;
      this.editRecord.data.tshirt = this.tshirt;
      this.editRecord.data.tshirtSize = this.tshirtSize;
      this.editRecord.data.ownHelmet = this.ownHelmet;
      this.editRecord.data.eAllowShow = this.eAllowShow;
      this.editRecord.data.eShoeSize = this.eShoeSize;

      if (this.showPrice !== "No") {
        console.log("wwww");
        this.editRecord.data2 = this.priceedTicket;
      } else {
        this.editRecord.data2 = null;
      }

      if (this.dinnerGuest !== "No") {
        this.editRecord.data2 = this.priceedTicket;
      } else {
        this.editRecord.data2 = null;
      }

      this.showcreateForm = false;
    },
    setValues(c, t) {
      this.cntholder[this[t].ticketCode] = c;
    },
    addtocart(t) {
      const rec = { ...this[t] };
      if (t == "daylist" && this.tickcount < 1) {
        return;
      }
      if (t == "daylist1" && this.tickcount1 < 1) {
        return;
      }
      if (rec && t == "daylist") {
        for (let c = 0; c < this.tickcount; c++) {
          rec.tttcount = 1;
          rec.daylist = this[t];
          this.selectedCarts.push({ ...rec, t: t });
        }

        this.setValues(
          (this.cntholder[rec.daylist.ticketCode] || 0) + this.tickcount,
          t
        );
        console.log("dsds", this.cntholder);
      }

      if (rec && t == "daylist1") {
        for (let c = 0; c < this.tickcount1; c++) {
          rec.tttcount = 1;
          rec.daylist = this[t];
          this.selectedCarts.push({ ...rec, t: t });
        }

        this.setValues(
          (this.cntholder[rec.daylist.ticketCode] || 0) + this.tickcount1,
          t
        );
        console.log("dsds", this.cntholder);
      }

      this.dayChange(t);
    },
    deleteRec(rec, i) {
      let tmpi = false;
      this.sections.forEach((r, qq) => {
        r.forEach((www, re) => {
          if (!tmpi && rec.ticketCode === this.sections[qq][re].ticketCode) {
            this.sections[qq][re].del = true;
            tmpi = true;
          }
        });
      });

      this.sections.forEach((r, qq) => {
        this.sections[qq] = this.sections[qq].filter((g) => !g.del);
      });

      this.sections = this.sections.filter((r) => r.length > 0);

      this.selectedCarts = this.selectedCarts.filter((x, it) => {
        if (it == i) {
          this[rec.t] = rec.daylist;
          this.setValues(
            (this.cntholder[rec.daylist.ticketCode] || 0) - rec.tttcount,
            rec.t
          );
        }
        return it != i;
      });
      this.dayChange(rec.t);
    },
    dayChange(t) {
      if (t == "daylist") {
        this.tickcount = 1;
        this.tickcountlist = [];
      } else {
        this.tickcount1 = 1;
        this.tickcountlist1 = [];
      }

      const w = this[t];
      for (
        let k = 0;
        k <
        (w.availableTickets > w.allowedPerRequest
          ? w.allowedPerRequest
          : w.availableTickets);
        k++
      ) {
        if (!this.donations.includes(w.ticketCode)) {
          this.tickcountlist.push(k + 1);
        } else if (this.donations.includes(w.ticketCode)) {
          this.tickcountlist1.push(k + 1);
        }
      }
      if (t === "daylist") {
        if (this.cntholder && this.cntholder[this[t].ticketCode] > 0) {
          const len = this.tickcountlist.length;
          this.tickcountlist = [];
          for (
            let k1 = 0;
            k1 < len - this.cntholder[this[t].ticketCode];
            k1++
          ) {
            this.tickcountlist.push(k1 + 1);
          }
        }

        if (this.tickcountlist.length == 0) {
          this.tickcount = 0;
        }
      } else {
        if (this.cntholder && this.cntholder[this[t].ticketCode] > 0) {
          const len = this.tickcountlist1.length;
          this.tickcountlist1 = [];
          for (
            let k1 = 0;
            k1 < len - this.cntholder[this[t].ticketCode];
            k1++
          ) {
            this.tickcountlist1.push(k1 + 1);
          }
        }

        if (this.tickcountlist1.length == 0) {
          this.tickcount1 = 0;
        }
      }

      if (t == "daylist") {
        this.ttypelist = { ...this[t] };
        if (!(this[t].ticketCode in this.initvalue)) {
          this.initvalue[this[t].ticketCode] = this.tickcountlist.length;
        }
      } else {
        this.ttypelist1 = { ...this[t] };
        if (!(this[t].ticketCode in this.initvalue)) {
          this.initvalue[this[t].ticketCode] = this.tickcountlist1.length;
        }
      }
    },
    paynowcustom: function () {
      this.isSubmitted = true;
      const vm = this;
      let j = 0;

      const payloadData = {
        userName: this.userFirstName + " " + this.userLastName,
        userEmail: this.userEmail.toLowerCase(),
        userId: "",
        eventId: this.TicketsResult[0].eventId,
        origin: "WEB",
        tickets: [],
      };
      const recss = {};
      this.selectedCarts.forEach((re, i) => {
        recss[re.daylist.ticketCode] = {
          priceId: this.pricemap[re.daylist.ticketCode],
          ticketId: re.daylist.ticketCode,
          totalTickets:
            (recss[re.daylist.ticketCode]
              ? recss[re.daylist.ticketCode].totalTickets
              : 0) + re.tttcount,
          additionalInfo: {},
          extraInfo: {},
        };
        let n = 1;
        (this.sections[i] || []).forEach((qq) => {
          if (qq.data) {
            recss[re.daylist.ticketCode].extraInfo["participant_" + n] = {
              allow_bike: qq.data.eAllowBike,
              body_size: qq.data.ebodySize,
              frame_size: qq.data.eframeSize,
              //  price: qq.data.showPrice,
              dinner_guest: qq.data.dinnerGuest,
              guest_count:
                qq.data.dinnerGuest == "Yes" ? qq.data.personCount : 0,
              emergency_contact_name: qq.data.eContactName,
              emergency_contact_phone: qq.data.eContactPhone,
              medical_emergency: qq.data.emedical,
              type: qq.data.eType,
              division: qq.data.edivision,
              station: qq.data.estation,
              country: qq.data.ecountry,
              merchandise: qq.data.eMerchandise,
              //      own_pedals: qq.data.ownPedals,
              //   own_helmet: qq.data.ownHelmet,
              // allow_shoe: qq.data.eAllowShow,
              // shoe_size: qq.data.eShoeSize,
              tshirt: qq.data.tshirt,
              tshirtSize: qq.data.tshirt === "Yes" ? qq.data.tshirtSize : "",
            };
          } else {
            recss[re.daylist.ticketCode].extraInfo["participant_" + n] = {
              ticketId: qq.ticketCode,
            };
          }

          n++;
          console.log("wweewew");
          if (qq.data2) {
            if (!recss[qq.data2.ticketCode]) {
              recss[qq.data2.ticketCode] = {
                priceId: "price_1PgElTD0vTZ4QB9cRbS00pQS",
                ticketId: qq.data2.ticketCode,
                totalTickets: qq.data.personCount,
                additionalInfo: {},
                extraInfo: {},
              };
              recss[qq.data2.ticketCode].extraInfo["participant_" + 1] = {
                allow_bike: qq.data.eAllowBike,
                body_size: qq.data.ebodySize,
                frame_size: qq.data.eframeSize,
                //  price: qq.data.showPrice,
                dinner_guest: qq.data.dinnerGuest,
                guest_count:
                  qq.data.dinnerGuest == "Yes" ? qq.data.personCount : 0,
                emergency_contact_name: qq.data.eContactName,
                emergency_contact_phone: qq.data.eContactPhone,
                medical_emergency: qq.data.emedical,
                type: qq.data.eType,
                division: qq.data.edivision,
                station: qq.data.estation,
                country: qq.data.ecountry,
                merchandise: qq.data.eMerchandise,
                //   own_pedals: qq.data.ownPedals,
                //    own_helmet: qq.data.ownHelmet,
                // allow_shoe: qq.data.eAllowShow,
                // shoe_size: qq.data.eShoeSize,
                tshirt: qq.data.tshirt,
                tshirtSize: qq.data.tshirt === "Yes" ? qq.data.tshirtSize : "",
              };
            } else {
              j++;
              recss[qq.data2.ticketCode + j] = {
                priceId: "price_1PgElTD0vTZ4QB9cRbS00pQS",
                ticketId: qq.data2.ticketCode,
                totalTickets: 1,
                additionalInfo: {},
                extraInfo: {},
              };
              recss[qq.data2.ticketCode + j].extraInfo["participant_" + 1] = {
                allow_bike: qq.data.eAllowBike,
                body_size: qq.data.ebodySize,
                frame_size: qq.data.eframeSize,
                //  price: qq.data.showPrice,
                dinner_guest: qq.data.dinnerGuest,
                guest_count:
                  qq.data.dinnerGuest == "Yes" ? qq.data.personCount : 0,
                emergency_contact_name: qq.data.eContactName,
                emergency_contact_phone: qq.data.eContactPhone,
                medical_emergency: qq.data.emedical,
                type: qq.data.eType,
                division: qq.data.edivision,
                station: qq.data.estation,
                country: qq.data.ecountry,
                merchandise: qq.data.eMerchandise,
                //   own_pedals: qq.data.ownPedals,
                //    own_helmet: qq.data.ownHelmet,
                // allow_shoe: qq.data.eAllowShow,
                // shoe_size: qq.data.eShoeSize,
                tshirt: qq.data.tshirt,
                tshirtSize: qq.data.tshirt === "Yes" ? qq.data.tshirtSize : "",
              };
            }
            n++;
          }
        });
      });

      payloadData.tickets = Object.values(recss);
      console.log(payloadData);

      vm.isSubmitted = true;
      axios
        .post(
          "https://myle-19701.uc.r.appspot.com/api/request_cart_tickets",
          payloadData
        )
        .then((re) => {
          if (re.data && re.data.Result && re.data.Result.sessionId) {
            vm.checkout.clientReferenceId = re.data.Result.sessionId;
            localStorage.setItem("refsId", vm.checkout.clientReferenceId);
            vm.$refs.checkoutRef.redirectToCheckout();
            setTimeout(() => {
              vm.isSubmitted = false;
            }, 60000);

            vm.reserveFormAccept = false;
            vm.reserveFormAcceptAboutAgeRestriction = false;
          } else {
            vm.$toast.error("Session Id not Received", { timeout: 4000 });
          }
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.reserveFormAccept = false;
          vm.reserveFormAcceptAboutAgeRestriction = false;
        })
        .finally(() => {
          vm.isSubmitted = false;
        });
    },
    fetchAllTickets: function () {
      const vm = this;
      this.loader = true;
      this.eventTicketResult = [];
      this.TicketsResult = [];
      axios
        .get(
          "https://myle-19701.uc.r.appspot.com/api/fetchSponsoredDeltaRideForTheFightTickets"
        )
        .then((ticketsResult) => {
          if (ticketsResult && ticketsResult.data.Result) {
            vm.priceedTicket = (ticketsResult.data.Result.filter(
              (r) => r.ticketCode === "tk_abUzszRQvcC6hQF"
            ) || [])[0];
            console.log(vm.priceedTicket);
            vm.TicketsResult = ticketsResult.data.Result.filter(
              (r) => this.pricemap[r.ticketCode]
            );
            vm.completeList = ticketsResult.data.Result.filter(
              (r) => this.pricemap[r.ticketCode]
            );
          }
          vm.TicketsResult = vm.completeList;
          vm.eventTicketResult = vm.TicketsResult;
          vm.dayChange("daylist");
          vm.dayChange("daylist1");
        })
        .finally(() => {
          vm.loader = false;
        });
    },
  },
  computed: {
    isTicketSelected() {
      const routes = ["tk_RJHGlyPtB0UCyRX", "tk_I3hMzALg0a2HLEJ"];
      let isPresent = false;
      this.selectedCarts.forEach((r) => {
        if (!isPresent) {
          isPresent = routes.includes(r.ticketCode);
        }
      });

      return isPresent;
    },
    dobtest() {
      const re =
        /(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d/;
      // /^(((0[1-9]|[12][0-9]|30)[-/]?(0[13-9]|1[012])|31[-/]?(0[13578]|1[02])|(0[1-9]|1[0-9]|2[0-8])[-/]?02)[-/]?[0-9]{4}|29[-/]?02[-/]?([0-9]{2}(([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048]|0[0-9]|1[0-6])00))$/;
      return re.test(this.userDOB);
    },
    completed() {
      console.log(this.showcreateForm);
      let isFilled = true;
      Object.values(this.sections).forEach((rec) => {
        rec.forEach((item) => {
          if (!item.data && item.t != "daylist1") {
            isFilled = false;
          }
        });
      });

      if (!Object.keys(this.sections).length) {
        return false;
      }

      return isFilled;
    },
    invalidEmail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.userEmail
      );
    },
    invalidPhone() {
      return (
        (this.userPhone || "").match(/\d/g) &&
        (this.userPhone || "").trim().length >= 10
      );
    },
    invalidePhone() {
      return (
        (this.eContactPhone || "").match(/\d/g) &&
        (this.eContactPhone || "").trim().length >= 10
      );
    },
  },

  created() {
    const r = window.location.origin;
    this.changeFavicon(r + "/img/icons5/");
  },
  mounted() {
    // this.fetchAllTickets();
  },
  watch: {
    selectedCarts(list) {
      const w = {};
      this.sections = JSON.parse(JSON.stringify(this.sections));

      list.forEach((r) => {
        if (!w[r.ticketCode]) {
          w[r.ticketCode] = [];
        }
        for (let e = 0; e < r.tttcount; e++) {
          w[r.ticketCode].push({ ...r });
        }
      });

      if (this.sections.length) {
        Object.keys(w).forEach((h) => {
          let present = false;

          this.sections.forEach((rr) => {
            if (rr[0].ticketCode === h) {
              present = true;
            }
          });

          if (!present) {
            this.sections.push(JSON.parse(JSON.stringify(w[h])));
          }
        });

        this.sections.forEach((rr, i) => {
          Object.keys(w[rr[0].ticketCode]).forEach((wx) => {
            if (!this.sections[i][wx]) {
              this.sections[i].push(w[rr[0].ticketCode][wx]);
            }
          });
        });

        this.sections = [...this.sections];
      } else {
        this.sections = Object.values(w);
      }

      console.log(this.sections);
    },
  },
  metaInfo: {
    title: "Backyard Bash With The Signature SELECT Life - Dallas",

    meta: [
      {
        name: "description",
        content: "Backyard Bash With The Signature SELECT Life - Dallas",
      },

      {
        name: "keywords",
        content: "Backyard Bash With The Signature SELECT Life - Dallas",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content:
          "https://myle.com/backyard-bash-with-the-signature-select-life-dallas",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #304cb291 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}
.bolded {
  font-weight: bold;
}

.nu-modal.has-content.is-visible {
  max-width: 600px !important;
}

.bolded {
  font-weight: bold;
}

iframe {
  width: 100% !important;
}

.labels {
  font-family: "Arial", sans-serif;
  font-size: 1.1em;
  color: rgb(34, 33, 33);
}
</style>
