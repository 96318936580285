<template>
  <div>
    <form
      style="visibility: hidden"
      method="post"
      :action="`${action}`"
      name="formAuthorizeNetTestPage"
    >
      <input type="text" name="token" v-model="actionValue" />
      <button id="btnContinue">OPEN CHECKOUT</button>
    </form>

    <!-- form starts -->
    <vue-element-loading
      :active="isSubmitted || loader"
      text="Loading"
      :text-style="loadStyle"
      :is-full-screen="true"
      class="h-full background-overlay"
      spinner="bar-fade-scale"
      color="#ffffff"
    />

    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="checkout.publishKey"
      :sessionId="checkout.clientReferenceId"
    />
    <nu-modal ref="smodal" title="">
      <successModal @closemodal="closeModaldata()" />
    </nu-modal>
    <nu-modal ref="modalreg1" title="">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 mb-4">
            <div class="text-center">
              <h3
                style="
                  font-family: 'Source Sans 3';
                  font-size: 2.3em;
                  font-weight: 700;
                  color: #0f3d70;
                "
              >
                Reservation
              </h3>
            </div>
          </div>

          <div class="col-md-12 mt-3 mb-2">
            <div class="t">
              <h3
                style="
                  font-family: 'Source Sans 3';
                  font-size: 1.5em;
                  font-weight: 700;
                  color: #0f3d70;
                "
              >
                General Details
              </h3>
            </div>
          </div>

          <div class="col-md-6 mb-1">
            <label
              style="
                font-family: 'Source Sans 3';
                font-size: 1.2em;
                font-weight: 600;
                color: #0f3d70;
              "
              >First Name</label
            >
            <input
              type="text"
              placeholder="Enter first name"
              class="form-control"
              v-model="userFirstName"
            />
            <span
              v-bind:class="{
                'op-0': !(istouched && userFirstName.trim().length <= 0),
              }"
              class="mb-3"
              style="color: red; font-size: 12px"
              >*First name is required</span
            >
          </div>
          <div class="col-md-6 mb-1">
            <label
              style="
                font-family: 'Source Sans 3';
                font-size: 1.2em;
                font-weight: 600;
                color: #0f3d70;
              "
              >Last Name</label
            >
            <input
              type="text"
              placeholder="Enter last name"
              class="form-control"
              Stashed
              changes
              v-model="userLastName"
            />
            <span
              v-bind:class="{
                'op-0': !(istouched && userLastName.trim().length <= 0),
              }"
              class="mb-3"
              style="color: red; font-size: 12px"
              >*Last name is required</span
            >
          </div>
          <div class="col-md-6 mb-1">
            <label
              style="
                font-family: 'Source Sans 3';
                font-size: 1.2em;
                font-weight: 600;
                color: #0f3d70;
              "
              >Email</label
            >
            <input
              type="text"
              placeholder="Enter email address"
              class="form-control"
              v-model="usersEmail"
            />
            <span
              v-bind:class="{ 'op-0': !(istouched && !invaliduemail) }"
              class="mb-3"
              style="color: red; font-size: 12px"
              >*Please provide valid email address</span
            >
            <!-- <label
              class="mb-3"
              style="
                font-family: 'Montserrat', sans-serif;
                font-size: 0.9em;
                color: black;
              "
              >The email address you enter must match the email on your Barclays
              Card Account.
            </label> -->
          </div>
          <div class="col-md-6 mb-1">
            <label
              class=""
              style="
                font-family: 'Source Sans 3';
                font-size: 1.2em;
                font-weight: 600;
                color: #0f3d70;
              "
              >Phone
            </label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter phone number"
              @keypress="isNumber()"
              v-model="userContactPhone"
            />
            <span
              v-bind:class="{ 'op-0': !(istouched && !invaliduphone) }"
              class="mb-3"
              style="color: red; font-size: 12px"
              >*please fill valid phone number</span
            >
          </div>

          <!-- <div class="col-md-12 mt-5 mb-2">
            <div class="t">
              <h3
                style="
                  font-family: 'Montserrat', sans-serif;
                  font-size: 1.4em;
                  font-weight: 600;
                  color: #0068ef;
                "
              >
                Address Details
              </h3>
            </div>
          </div> -->

          <!-- <div class="col-md-6 mb-1">
            <label
              style="
                font-family: 'Montserrat', sans-serif;
                font-size: 1.1em;
                font-weight: 600;
                color: #003c8a;
              "
              >Street Address</label
            >
            <input
              type="text"
              placeholder="Enter street address"
              class="form-control"
              v-model="streetAddress"
            />
            <span
              v-bind:class="{
                'op-0': !(istouched && streetAddress.trim().length <= 0),
              }"
              class="mb-3"
              style="color: red; font-size: 12px"
              >*Street address is required</span
            >
          </div>
          <div class="col-md-6 mb-1">
            <label
              style="
                font-family: 'Montserrat', sans-serif;
                font-size: 1.1em;
                font-weight: 600;
                color: #003c8a;
              "
              >City</label
            >
            <input
              type="text"
              placeholder="Enter city"
              class="form-control"
              v-model="city"
            />
            <span
              v-bind:class="{ 'op-0': !(istouched && city.trim().length <= 0) }"
              class="mb-3"
              style="color: red; font-size: 12px"
              >*City is required</span
            >
          </div>
          <div class="col-md-6 mb-1">
            <label
              style="
                font-family: 'Montserrat', sans-serif;
                font-size: 1.1em;
                font-weight: 600;
                color: #003c8a;
              "
              >State</label
            >
            <input
              type="text"
              placeholder="Enter state"
              class="form-control"
              v-model="state"
            />
            <span
              v-bind:class="{
                'op-0': !(istouched && state.trim().length <= 0),
              }"
              class="mb-3"
              style="color: red; font-size: 12px"
              >*State is required</span
            >
          </div>
          <div class="col-md-6 mb-1">
            <label
              style="
                font-family: 'Montserrat', sans-serif;
                font-size: 1.1em;
                font-weight: 600;
                color: #003c8a;
              "
              >Zip Code</label
            >
            <input
              type="text"
              placeholder="Enter zip code"
              class="form-control"
              @keypress="isNumber()"
              v-model="zip"
            />
            <span
              v-bind:class="{ 'op-0': !(istouched && zip.trim().length <= 0) }"
              class="mb-3"
              style="color: red; font-size: 12px"
              >*Zipcode is required</span
            >
          </div> -->

          <div class="col-md-12 mt-1 mb-2">
            <div class="t">
              <h3
                style="
                  font-family: 'Source Sans 3';
                  font-size: 1.5em;
                  font-weight: 700;
                  color: #0f3d70;
                "
              >
                Dietary Restrictions
              </h3>
            </div>
          </div>

          <div class="col-md-12 mb-1">
            <label
              style="
                font-family: 'Source Sans 3';
                font-size: 1.2em;
                font-weight: 600;
                color: #0f3d70;
              "
              >Share any dietary restrictions (optional)</label
            >
            <input type="text" class="form-control mb-3" v-model="question" />
          </div>

          <div class="col-md-12 mt-4 mb-2">
            <div class="t">
              <h3
                style="
                  font-family: 'Source Sans 3';
                  font-size: 1.5em;
                  font-weight: 700;
                  color: #0f3d70;
                "
              >
                Guest Information
              </h3>
            </div>
          </div>

          <div class="col-md-6 mb-1">
            <label
              style="
                font-family: 'Source Sans 3';
                font-size: 1.2em;
                font-weight: 600;
                color: #0f3d70;
              "
              >Are you bringing a guest?</label
            >
            <select class="form-control" v-model="guestComing">
              <option
                class="form-control"
                v-bind:key="count + 'b'"
                v-for="count in ['Yes', 'No']"
                :value="count"
              >
                {{ count }}
              </option>
            </select>
          </div>

          <div class="col-md-6 mb-1" v-if="guestComing == 'Yes'">
            <label
              style="
                font-family: 'Source Sans 3';
                font-size: 1.2em;
                font-weight: 600;
                color: #0f3d70;
              "
              >Guest First Name</label
            >
            <input
              type="text"
              placeholder="Enter guest first name"
              class="form-control"
              v-model="guestFirstName"
            />
            <span
              v-bind:class="{
                'op-0': !(istouched && guestFirstName.trim().length <= 0),
              }"
              class="mb-3"
              style="color: red; font-size: 12px"
              >*Guest First name is required</span
            >
          </div>
          <div class="col-md-6 mb-1" v-if="guestComing == 'Yes'">
            <label
              style="
                font-family: 'Source Sans 3';
                font-size: 1.2em;
                font-weight: 600;
                color: #0f3d70;
              "
              >Guest Last Name</label
            >
            <input
              type="text"
              placeholder="Enter guest last name"
              class="form-control"
              Stashed
              changes
              v-model="guestLastName"
            />
            <span
              v-bind:class="{
                'op-0': !(istouched && guestLastName.trim().length <= 0),
              }"
              class="mb-3"
              style="color: red; font-size: 12px"
              >*Guest Last name is required</span
            >
          </div>
          <div class="col-md-6 mb-1" v-if="guestComing == 'Yes'">
            <label
              style="
                font-family: 'Source Sans 3';
                font-size: 1.2em;
                font-weight: 600;
                color: #0f3d70;
              "
              >Guest Email</label
            >
            <input
              type="text"
              placeholder="Enter guest email address"
              class="form-control"
              v-model="guestEmail"
            />
            <span
              v-bind:class="{ 'op-0': !(istouched && !invalidgemail) }"
              class="mb-3"
              style="color: red; font-size: 12px"
              >*Please provide valid guest email address</span
            >
          </div>
          <div class="col-md-6 mb-1" v-if="guestComing == 'Yes'">
            <label
              class=""
              style="
                font-family: 'Source Sans 3';
                font-size: 1.2em;
                font-weight: 600;
                color: #0f3d70;
              "
              >Guest Phone
            </label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter guest phone number"
              @keypress="isNumber1()"
              v-model="guestContactPhone"
            />
            <span
              v-bind:class="{ 'op-0': !(istouched && !invaliduphone) }"
              class="mb-3"
              style="color: red; font-size: 12px"
              >*please fill valid guest phone number</span
            >
          </div>

          <div class="col-md-12 mb-1" v-if="guestComing == 'Yes'">
            <label
              style="
                font-family: 'Source Sans 3';
                font-size: 1.2em;
                font-weight: 600;
                color: #0f3d70;
              "
              >Share any guest dietary restrictions (optional)</label
            >
            <input type="text" class="form-control mb-3" v-model="qquestion" />
          </div>

          <div
            class="col-md-12 mt-5"
            style="
              font-family: 'Source Sans 3';
              font-size: 1.1em;
              font-weight: 500;
              color: black;
            "
          >
            <input type="checkbox" v-model="reserveFormAccept" />
            By Participating in this program, I agree to the
            <a
              style="color: #0068ef; cursor: pointer"
              target="_self"
              rel="noopener noreferrer"
              @click="openTermsModel()"
            >
              Terms & Conditions.</a
            >
          </div>

          <div class="col-md-12 mt-4 text-center">
            <button
              type="submit"
              @click="saveNewForm()"
              class="btn solid-btn-NavyFederal m-auto mr-2"
              style="
                pointer-events: all;
                cursor: pointer;
                font-family: 'Source Sans 3';
                font-size: 1.3em;
                font-weight: 600;
              "
            >
              Reserve Now
            </button>
          </div>
        </div>
      </div>
    </nu-modal>

    <nu-modal ref="modelwait" title="">
      <SWAVisaWaitlistSuccess style="height: 360px" />
    </nu-modal>
    <nu-modal ref="model2" title="">
      <SWAVisaCardVerifyFailed style="height: 300px" />
    </nu-modal>
    <!-- conifrm model -->
    <nu-modal ref="confirmmodal" title="">
      <confirmationPopup
        :showsuccess="showsuccess"
        @closemodal="closeModalerror"
      />
    </nu-modal>
    <!-- confrim model -->

    <!-- error model -->
    <nu-modal ref="emodal" title="">
      <errorModal @closemodal="closeModalerror" />
    </nu-modal>
    <!-- error model -->

    <nu-modal ref="modal1" title="">
      <div class="opa0" v-bind:class="{ opa100: !isSubmitted }">
        <iframe
          width="100%"
          id="iframeid"
          target="_parent"
          :src="iframeSrc"
          @load="onLoad"
        ></iframe>

        <p
          class="text-center"
          style="
            font-family: 'Arial', sans-serif;
            font-size: 0.95em;
            color: rgb(34, 33, 33);
          "
        >
          Please enter your 16-digit Southwest Rapid Rewards<sup>®</sup> Visa
          Credit Card number.
        </p>

        <p
          class="text-center"
          style="
            font-family: 'Arial', sans-serif;
            font-size: 0.95em;
            color: rgb(34, 33, 33);
          "
        >
          It will be used to verify and authenticate you, so you can receive
          access to The Southwest Landing Lounge.

          <br /><br />

          This experience is intended for Southwest Rapid Rewards Visa
          Cardmembers only.
        </p>

        <p
          class="text-center"
          style="
            font-family: 'Arial', sans-serif;
            font-size: 0.95em;
            color: rgb(34, 33, 33);
          "
        >
          For more information about how Visa uses your personal information,
          please visit
          <a
            href="https://pk.visamiddleeast.com/legal/global-privacy-notice.html"
            target="_blank"
            rel="noopener noreferrer"
            style="color: #304cb2"
          >
            Visa's Privacy Notice</a
          >.
        </p>

        <div v-if="false" class="col-md-12 text-center mt-4">
          <button
            type="submit"
            @click="opencartm()"
            class="btn solid-btn-SWA m-auto mr-2"
            id="reserve"
            style="
              pointer-events: all;
              cursor: pointer;
              font-family: 'Arial', sans-serif;
              font-size: 1.3em;
            "
          >
            Verify now
          </button>
        </div>
      </div>
    </nu-modal>

    <nu-modal ref="modal" title="">
      <form style="font-family: 'Arial', sans-serif">
        <div class="container-fluid">
          <div class="row">
            <!-- Ticket Cart -->
            <div class="col-md-12">
              <div class="text-center">
                <span
                  class="w-auto d-inline-block"
                  style="
                    font-family: 'Arial', sans-serif;
                    font-weight: bold;
                    font-size: 2em;
                    color: #f9b329;
                  "
                >
                  Reserve your tickets here
                </span>
              </div>
            </div>

            <div class="col-md-6 mt-4">
              <span
                class="w-auto d-inline-block"
                style="
                  font-family: 'Arial', sans-serif;
                  font-size: 1.1em;
                  color: rgb(34, 33, 33);
                "
              >
                First Name
              </span>
              <input
                type="text"
                class="form-control mb-3"
                style="
                  font-family: 'Arial', sans-serif;
                  font-size: 1.3em;
                  color: rgb(34, 33, 33);
                "
                placeholder="Enter your first name"
                required
                v-model="firstName"
              />
            </div>

            <div class="col-md-6 mt-4">
              <span
                class="w-auto d-inline-block"
                style="
                  font-family: 'Arial', sans-serif;
                  font-size: 1.1em;
                  color: rgb(34, 33, 33);
                "
              >
                Last Name
              </span>
              <input
                type="text"
                class="form-control mb-3"
                style="
                  font-family: 'Arial', sans-serif;
                  font-size: 1.3em;
                  color: rgb(34, 33, 33);
                "
                placeholder="Enter your last name"
                required
                v-model="lastName"
              />
            </div>

            <div class="col-md-6 mt-4">
              <span
                class="w-auto d-inline-block"
                style="
                  font-family: 'Arial', sans-serif;
                  font-size: 1.1em;
                  color: rgb(34, 33, 33);
                "
              >
                Email Address
              </span>
              <input
                type="email"
                class="form-control mb-3"
                style="
                  font-family: 'Arial', sans-serif;
                  font-size: 1.3em;
                  color: rgb(34, 33, 33);
                "
                placeholder="Email associated with your Southwest Rapid Rewards Account"
                v-model="email"
                required
              />
            </div>

            <hr class="w-100 p-2" />
            <!-- Ticket Cart -->

            <!-- Ticket Options -->
            <div class="col-md-12">
              <div class="text-center">
                <span
                  class="w-auto d-inline-block"
                  style="
                    font-family: 'Arial', sans-serif;
                    font-weight: bold;
                    font-size: 2em;
                    color: #f9b329;
                  "
                  >Select your ticket(s)</span
                >
              </div>
            </div>

            <div class="col-md-12">
              <div
                class="my-3"
                style="
                  font-family: 'Arial', sans-serif;
                  font-size: 1.3em;
                  color: rgb(34, 33, 33);
                "
              >
                <span style="font-weight: bold">Ticket Type: </span>
                General admission
                <!-- {{ ttypelist ? ttypelist.ticketName : "" }} -->
              </div>
            </div>

            <div class="col-md-4">
              <label
                class="mt-3"
                style="
                  font-family: 'Arial', sans-serif;
                  font-size: 1.1em;
                  color: rgb(34, 33, 33);
                "
                >Event Day</label
              >

              <select
                @change="dayChange()"
                class="form-control"
                v-model="daylist"
                v-if="activeWaitList && isjlist"
              >
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in completeList"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>

              <select
                @change="dayChange()"
                class="form-control"
                v-model="daylist"
                v-if="!isjlist"
              >
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in ['Thursday', 'Friday', 'Saturday']"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div class="col-md-4 mb-3">
              <label
                class="mt-3"
                style="
                  font-family: 'Arial', sans-serif;
                  font-size: 1.1em;
                  color: rgb(34, 33, 33);
                "
                >Number of Ticket(s)</label
              >
              <select
                class="form-control"
                v-model="tickcount"
                @change="countChange()"
              >
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in tickcountlist"
                  :value="count"
                >
                  {{ count }}
                </option>

                <option
                  disabled
                  v-if="tickcountlist.length == 0 && initvalue[daylist] != 0"
                >
                  No more tickets
                </option>

                <option disabled v-if="!isjlist && initvalue[daylist] == 0">
                  Sold Out
                </option>
              </select>
            </div>

            <div class="col-md-4 mt-4 text-center">
              <p
                v-if="
                  !redeemData &&
                  TicketsResult[selected] &&
                  TicketsResult[selected].ticketType != 'FREE'
                "
              >
                <b
                  class="font-weight-bold"
                  style="
                    font-family: 'Arial', sans-serif;
                    font-size: 1.1em;
                    color: rgb(34, 33, 33);
                  "
                  >Per Person Price:</b
                >
                ${{ ttypelist ? ttypelist.price.toFixed(2) : "" }}
              </p>
              <p v-if="redeemData && redeemData.price">
                <b
                  class="font-weight-bold"
                  style="
                    font-family: 'Arial', sans-serif;
                    font-size: 1.1em;
                    color: rgb(34, 33, 33);
                  "
                  >Per Person Price:</b
                >
                ${{ redeemData.price.toFixed(2) }}
              </p>
              <p
                v-if="
                  !redeemData && ttypelist && ttypelist.ticketType != 'FREE'
                "
              >
                <b
                  class="font-weight-bold"
                  style="
                    font-family: 'Arial', sans-serif;
                    font-size: 1.1em;
                    color: rgb(34, 33, 33);
                  "
                  >Total Price:</b
                >
                ${{
                  (
                    (ttypelist ? ttypelist.price || 0 : 0) * (tickcount || 0)
                  ).toFixed(2)
                }}
              </p>
              <p v-if="redeemData && redeemData.price">
                <b class="font-weight-bold">Total Price:</b> ${{
                  ((redeemData.price || 0) * (tickcount || 0)).toFixed(2)
                }}
              </p>
            </div>

            <div
              class="col-md-6 mt-3"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <label class="">Do you have a promo code?</label>

              <input
                type="text"
                v-model="redeempromo"
                class="form-control"
                placeholder="Promo code"
              />
            </div>

            <div
              class="col-md-6"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            ></div>

            <div
              class="col-md-6 text-center"
              id="redeem"
              v-if="TicketsResult[selected] && TicketsResult[selected].discount"
            >
              <button
                type="button"
                @click="redeem()"
                :disabled="!redeempromo.trim().length"
                class="btn solid-btn-SWA btn-sm my-auto"
                id="reserve"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  padding: 7px;
                  margin-top: 11px !important;
                  font-family: 'Arial', sans-serif;
                  font-size: 1.3em;
                "
              >
                Redeem Code
              </button>
            </div>

            <div class="col-md-12 text-center my-4">
              <input
                type="button"
                @click="addtocart(ttypelist)"
                class="btn solid-btn-SWA m-auto mr-2"
                :disabled="
                  (!isjlist &&
                    (!ttypelist ||
                      tickcountlist.length == 0 ||
                      initvalue[daylist] == 0)) ||
                  (!tickcount && isjlist) ||
                  (completeList.length &&
                    isjlist &&
                    !completeList.includes(daylist))
                "
                id="reserve"
                value="Add to cart"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  font-family: 'Arial', sans-serif;
                  font-size: 1.3em;
                "
              />
            </div>

            <hr class="w-100 p-2" />
            <!-- Ticket Options -->

            <!-- Cart details -->
            <div class="col-md-12">
              <div class="text-center">
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/SWA_icon_cart.png"
                  style="width: 1.8em"
                />
                <span
                  class="ml-1 w-auto d-inline-block"
                  style="
                    font-family: 'Arial', sans-serif;
                    font-weight: bold;
                    font-size: 2em;
                    color: #f9b329;
                  "
                  >Cart details</span
                >
              </div>
            </div>

            <div class="col-md-12 mt-4">
              <table style="width: 100%">
                <thead>
                  <tr>
                    <th
                      style="
                        font-family: 'Arial', sans-serif;
                        font-weight: bold;
                        font-size: 1.2em;
                        color: rgb(34, 33, 33);
                      "
                    >
                      Ticket
                    </th>
                    <th
                      style="
                        text-align: center;
                        font-family: 'Arial', sans-serif;
                        font-weight: bold;
                        font-size: 1.2em;
                        color: rgb(34, 33, 33);
                      "
                    >
                      Quantity
                    </th>
                    <th
                      style="
                        font-weight: bold;
                        text-align: center;
                        font-family: 'Arial', sans-serif;
                        font-weight: bold;
                        font-size: 1.2em;
                        color: rgb(34, 33, 33);
                      "
                    >
                      Price
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(r, i) in selectedCarts" v-bind:key="r">
                    <td
                      style="
                        width: 60%;
                        font-family: 'Arial', sans-serif;
                        font-size: 1.1em;
                        color: rgb(34, 33, 33);
                      "
                    >
                      <p>{{ daytitle[r.daylist] }}</p>
                    </td>
                    <td
                      style="
                        width: 15%;
                        text-align: center;
                        font-family: 'Arial', sans-serif;
                        font-size: 1.1em;
                        color: rgb(34, 33, 33);
                      "
                    >
                      <p>{{ r.tttcount }}</p>
                    </td>
                    <td
                      style="
                        width: 15%;
                        text-align: center;
                        font-family: 'Arial', sans-serif;
                        font-size: 1.1em;
                        color: rgb(34, 33, 33);
                      "
                    >
                      <p>${{ (r.tttcount * r.price).toFixed(2) }}</p>
                    </td>
                    <td
                      style="
                        width: 10%;
                        text-align: right;
                        cursor: pointer;
                        font-family: 'Arial', sans-serif;
                        font-size: 1.1em;
                        color: rgb(34, 33, 33);
                      "
                    >
                      <p>
                        <span
                          @click="deleteRec(r, i)"
                          style="
                            color: #d90606;
                            display: block;
                            font-weight: bold;
                            font-family: 'Quicksand';
                          "
                        >
                          ❌</span
                        >
                      </p>
                    </td>
                  </tr>
                  <tr v-if="selectedCarts.length == 0">
                    <td
                      colSpan="4"
                      style="
                        padding-top: 10px;
                        font-family: 'Arial', sans-serif;
                        font-size: 1.1em;
                      "
                    >
                      <p class="text-center">No Tickets</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- Cart details -->

            <div
              class="row col-md-12 m-auto mt-4 w-100 p-0 pb-4"
              v-if="
                false && section1.includes(TicketsResult[selected].ticketCode)
              "
            >
              <div class="col-md-6">
                <input
                  type="text"
                  v-model="ePhoneNumber"
                  class="form-control"
                  @keypress="isNumber()"
                  placeholder="Phone Number"
                />
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  v-model="eOrgNumber"
                  class="form-control"
                  placeholder="Organization Or Institution: ex: DEPA (if applicable)"
                />
              </div>
            </div>

            <hr
              class="w-100 p-2"
              v-if="
                TicketsResult[selected] &&
                section2.includes(TicketsResult[selected].ticketCode)
              "
            />
            <div
              class="row"
              v-if="
                TicketsResult[selected] &&
                section2.includes(TicketsResult[selected].ticketCode)
              "
            >
              <div class="col-md-6 mb-2">
                <label class="">Name of Business/Individual</label>
                <input
                  type="text"
                  v-model="nameOfBusiness"
                  class="form-control"
                  placeholder="Name of Business/Individual"
                />
              </div>
              <div class="col-md-6 mb-2">
                <label class="">Address</label>
                <input
                  type="text"
                  v-model="address"
                  class="form-control"
                  placeholder="Address"
                />
              </div>
              <div class="col-md-6 mb-2">
                <label class="">City, State, Zip</label>
                <input
                  type="text"
                  v-model="cityStateZip"
                  class="form-control"
                  placeholder="City, State, Zip"
                />
              </div>
              <div class="col-md-6 mb-2">
                <label class="">Contact Person</label>
                <input
                  type="text"
                  v-model="contactPerson"
                  class="form-control"
                  placeholder="Contact Person"
                />
              </div>

              <div class="col-md-6 mb-2">
                <label class="">Contact Number</label>
                <input
                  type="text"
                  v-model="telephoneNumber"
                  class="form-control"
                  placeholder="Contact Number"
                />
              </div>
              <div class="col-md-6 mb-2">
                <label class="">Email Address</label>
                <input
                  type="text"
                  v-model="emailAddress"
                  class="form-control"
                  placeholder="Email Address"
                />
              </div>
            </div>

            <div
              class="row align-items-center justify-content-between mt-5"
              style="margin: auto; margin-top: 20px; width: 100% !important"
            >
              <div class="col-lg-12 col-md-12">
                <p
                  class="mb-1"
                  style="
                    font-family: 'Arial', sans-serif;
                    font-size: 1.4em;
                    color: black;
                  "
                >
                  Terms & Conditions
                </p>

                <div style="height: 250px; overflow: auto">
                  <p
                    class="mb-1"
                    style="
                      font-family: 'Arial', sans-serif;
                      font-size: 11px;
                      color: black;
                      width: 100%;
                    "
                  >
                    <br />
                    The Southwest<sup>®</sup> Landing Lounge (the “Event”) is
                    available to select Cardmembers. The Event will occur on
                    5/2/2024 between the hours of 7:00pm CST to 10:30pm CST and
                    on 5/3/2024 - 5/4/2024 between the hours of 6:30pm CST to
                    10:00pm CST. Participation in the Event is limited, and
                    available on a first-come, first-served basis. Cardmembers
                    and their guests (“Participants”) may register for no more
                    than one (1) Event package (“Event Package”) per day for a
                    total of up to three (3) days. Participants may register for
                    up to six (6) nonrefundable tickets per day registered,
                    i.e., per Event Package. Therefore, by way of example,
                    should a Participant register for each Event Day (3 days)
                    and for the maximum number of tickets per day (6),
                    Participant would be entitled to receive a total of 18
                    tickets. Participants must be able to provide proof (a) that
                    they reside in the United States and (b) that they are 21
                    years of age or older.

                    <br /><br />
                    Event Packages may only be reserved by the named recipient
                    in the email upon the successful completion of registration
                    using the emailed link. Event Packages must be purchased
                    with a Southwest Rapid Rewards Credit Card.

                    <br /><br />

                    Southwest Airlines<sup>®</sup> reserves the right to modify
                    or cancel the Event at any time and will use good faith
                    efforts to notify Participants. Event schedule and staffing
                    are subject to change. Offer cannot be combined with any
                    other offer or discount.

                    <br /><br />
                    Event Packages are for Southwest Rapid Rewards Cardmembers
                    and their guest(s) only. Event Packages are non-transferable
                    and may not be resold or redeemed for cash. In the event a
                    Cardmember needs to cancel tickets for themselves or their
                    guest for any reason, tickets are non-refundable and
                    non-transferable.
                    <br /><br />

                    Offer is void where prohibited. Offer is valid through
                    5/1/2024.
                    <br /><br />
                    Event Packages are subject to terms and conditions as
                    specified by Event Package issuer. By attending this Event,
                    Participants accept these terms and conditions (“Terms and
                    Conditions”). These Terms and Conditions apply to you and
                    any guests attending the Event. References to “you” or
                    “your” includes participating guests. The invitation is for
                    you and for non-commercial use. Any other use or activity in
                    connection with the Event is strictly prohibited.
                    <br /><br />
                    By attending this Event, you grant Southwest Airlines and
                    its affiliates the irrevocable right and permission to use
                    your name, likeness, image, and voice, whether derived from
                    photographs, video footage, other recordings taken during
                    the Event, for commercial and other purposes and that no
                    royalties or compensation will be paid to you for such uses.
                    All ownership and copyright interests shall become and
                    remain property of Southwest Airlines.

                    <br /><br />
                    The Event Parties (as defined below) will solely make all
                    determinations in connection with the Event and access to
                    the Event. Event Parties reserve the right to ask
                    Participants to leave if they determine that you are
                    behaving in a disruptive manner or violating any of the
                    rules of The Chicory LLC.

                    <br /><br />

                    Limitations of Liability & Release: You agree to comply with
                    all applicable The Chicory LLC regulations with respect to
                    the package offer. In consideration of your access to and
                    participation in the Event, you agree that in no event will
                    any of the Event Parties be liable under any theory of tort,
                    contract, strict liability, or other legal or equitable
                    theory for any lost profits, lost data, lost opportunities,
                    costs of cover, exemplary, punitive, personal
                    injury/wrongful death, contraction of illness or disease,
                    special, incidental, indirect, or other consequential
                    damages, and you release officers, directors, Employees,
                    agents, and assigns of Southwest Airlines, The Chicory LLC,
                    Vega Group LLC, Visa Inc., Visa U.S.A. Inc., Visa
                    International Service Association, and their respective
                    parents, subsidiaries, successors, affiliates, and related
                    companies, independent contractors, subcontractors, client
                    financial institutions, prize suppliers, and advertising,
                    marketing, and promotional agencies (collectively, the
                    “Event Parties”) from all such claims. Further, to the
                    fullest extent permitted by law, in no event will the Event
                    Parties be liable for any damages resulting from your access
                    to or participation in the Event, or acceptance, possession,
                    use, misuse, or nonuse of the offer (including any travel or
                    travel-related activity thereto), each of which, to the
                    fullest extent permitted by law, is hereby excluded by
                    agreement of the parties, regardless of whether or not
                    either party has been advised of the possibility of such
                    damages.
                    <br /><br />

                    No Warranties. EVENT PARTIES MAKE NO WARRANTIES OF ANY KIND,
                    EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO,
                    IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                    PARTICULAR PURPOSE, OR NON-INFRINGEMENT REGARDING THE OFFER.
                    ATTENDANCE OF EVENT IS AT YOUR OWN RISK.
                    <br /><br />
                    Infectious Disease Acknowledgement. You acknowledge that by
                    participating in or traveling to the Event you may come into
                    contact with other individuals and may be at risk of
                    contracting infectious diseases (including without
                    limitation COVID-19). You assume all risks of contracting
                    any such infectious diseases in connection with the Event.
                    You agree to abide by any and all safety practices,
                    guidelines and protocols provided by Event Parties.

                    <br /><br />

                    LIMITATIONS OF LIABILITY:
                    <br />
                    JPMorgan Chase Bank, N.A. (“Chase”), the issuer of the
                    Southwest<sup>®</sup>
                    Visa Signature Credit Card, and Visa Inc., Visa U.S.A. Inc.,
                    Visa International Service Association, and their respective
                    successors, affiliates, and related companies are not
                    responsible for offer fulfillment or the provision of or
                    failure to provide the stated items, activities, and/or
                    benefits described in the offer. Event Package is subject to
                    terms and conditions as specified by Package issuer.
                    Participant agrees to comply with all applicable venue
                    regulations with respect to the event offer. Participant
                    agrees to hold harmless Chase, Southwest Airlines, Visa
                    Inc., Visa U.S.A. Inc., Visa International Service
                    Association, and their respective officers, directors,
                    employees, agents, successors and assigns, affiliates, prize
                    suppliers, and advertising and promotion agencies, from any
                    and all liability or damage of any kind resulting from or
                    arising from participation in the offer or acceptance,
                    possession, misuse or non-use of the offer (including
                    travel-related activity). Appropriate attire required at all
                    times. Must be 21 or older to consume alcoholic beverages.
                    Void where prohibited.

                    <br /><br />
                    The Contactless Symbol and Contactless Indicator are
                    trademarks owned by and used with the permission of EMVCo,
                    LLC.

                    <br /><br />

                    JPMorgan Chase Bank, N.A. Member FDIC
                    <br /><br />

                    SOUTHWEST RAPID REWARDS<sup>®</sup> PROGRAM INFORMATION

                    <br /><br />

                    The Southwest Rapid Rewards Credit Card is brought to you by
                    Southwest Airlines<sup>®</sup> and Chase. Southwest Airlines
                    is responsible for the redemption of Rapid Rewards points
                    toward benefits and services. The number of points needed
                    for a particular Southwest flight is set by Southwest and
                    will vary depending on destination, time, day of travel,
                    demand, fare type, point redemption rate, and other factors,
                    and is subject to change at any time until the booking is
                    confirmed. Rapid Rewards points can only be transferred to
                    the primary Cardmember’s Rapid Rewards account. All Rapid
                    Rewards rules and regulations apply and can be found at
                    <a
                      href="http://www.southwest.com/rrterms"
                      target="_blank"
                      rel="noopener noreferrer"
                      style="color: #304cb2"
                    >
                      Southwest.com/rrterms</a
                    >. Southwest reserves the right to amend, suspend, or change
                    the program and/or program rules at any time without notice.
                    Rapid Rewards Members do not acquire property rights in
                    accrued points.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-md-12 mt-4">
              <input type="checkbox" v-model="reserveFormAccept" />
              I have read and agree with the terms and conditions.
            </div>

            <div class="col-md-12 mt-1">
              <input
                type="checkbox"
                v-model="reserveFormAcceptAboutAgeRestriction"
              />
              I understand that guests must be 21 years or older in order to
              attend the event.
            </div>

            <div class="col-md-12 text-center mt-4">
              <button
                v-if="!isjlist"
                type="submit"
                @click="paynowcustom()"
                :disabled="
                  !lastName.trim().length ||
                  !firstName.trim().length ||
                  !invalidemail ||
                  isSubmitted ||
                  !selectedCarts.length ||
                  !reserveFormAccept ||
                  !reserveFormAcceptAboutAgeRestriction
                "
                class="btn solid-btn-SWA m-auto mr-2"
                id="reserve"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  font-family: 'Arial', sans-serif;
                  font-size: 1.3em;
                "
              >
                Reserve now
              </button>

              <button
                v-if="activeWaitList && isjlist"
                type="submit"
                @click="joinpaynowcustom()"
                :disabled="
                  !lastName.trim().length ||
                  !firstName.trim().length ||
                  !invalidemail ||
                  isSubmitted ||
                  !selectedCarts.length ||
                  !reserveFormAccept ||
                  !reserveFormAcceptAboutAgeRestriction
                "
                class="btn solid-btn-SWA m-auto mr-2"
                id="reserve"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  font-family: 'Arial', sans-serif;
                  font-size: 1.3em;
                "
              >
                Join waitlist
              </button>
            </div>
          </div>
        </div>
      </form>
    </nu-modal>
    <!-- form ends -->

    <nu-modal ref="termsModal" title="">
      <div class="container-fluid">
        <div class="row">
          <!-- Ticket Cart -->

          <div
            class="row align-items-center justify-content-between"
            style="margin: auto; width: 100% !important"
          >
            <div class="col-md-12">
              <div class="text-center">
                <span
                  class="w-auto d-inline-block"
                  style="
                    font-family: 'Montserrat', sans-serif;
                    font-weight: bold;
                    font-size: 2em;
                    color: #0068ef;
                  "
                >
                  Terms & Conditions
                </span>
              </div>
            </div>
            <div class="col-lg-12 col-md-12">
              <div style="height: auto; overflow: auto">
                <p
                  class="mb-1 mt-5"
                  style="
                    font-family: 'Montserrat', sans-serif;
                    font-size: 1em;
                    color: black;
                    width: 100%;
                  "
                >
                  MUST BE U.S. RESIDENT 18 OR OLDER. Offer valid through Sunday,
                  October 20, 2024. The Priceline Visa Virtual Cooking (“Event”)
                  participation is limited, and is on a first come, first-served
                  basis. Limit ONE (1) event link per cardholder (each, an
                  “Event Ticket”). No payment required. Cardholder must have
                  access to high-speed internet to take advantage of this offer
                  and participate in the Event. Event Tickets are not
                  transferable and may not be resold or redeemed for cash. Void
                  where prohibited. Offer cannot be combined with any other
                  offer or discount. Any applicable taxes must be paid by each
                  redeemer of offer.

                  <br /><br />
                  Event Packages may only be reserved by the named recipient in
                  the email upon the successful completion of registration using
                  the emailed link. Event Packages must be purchased with a
                  Southwest Rapid Rewards Credit Card.

                  <br /><br />

                  By accessing or participating in the Event, you accept these
                  terms and conditions and the Additional Terms and Conditions
                  set forth below (together, the “Terms and Conditions”). These
                  Terms and Conditions apply to each redeemer of the Offer
                  (“you”). References to “you” or “your” includes participating
                  guests.

                  <br /><br />
                  Only Priceline Visa Signature<sup>®</sup> Credit Cardholders
                  who received an email invitation for the Event are eligible to
                  register for the Event. <br /><br />

                  <span class="bolded"> Event Cancellation; Delays.</span> Visa
                  reserves the right to modify, reschedule or cancel any event
                  at any time and shall use good faith efforts to notify
                  participants. Offer is non-refundable. Circumstances beyond
                  the control of Visa Inc., Visa U.S.A. Inc., Visa International
                  Service Association, Priceline, Vega Group, LLC, Mina Group,
                  Will Blackmon, Make Your Life Entertaining, Inc., or each of
                  their client financial institutions, prize suppliers, and
                  advertising, promotion and marketing agencies, or each of
                  their respective parents, subsidiaries, successors,
                  affiliates, and related companies (collectively, “Event
                  Parties”) may cause delays in delivery of any products,
                  content or materials to be delivered to you for the Event.
                  <br /><br />

                  <span class="bolded"> No Warranties.</span> THE EVENT PARTIES
                  MAKE NO WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED,
                  INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
                  MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR
                  NONINFRINGEMENT REGARDING THE OFFER. PARTICIPATION IN THE
                  EVENT IS AT YOUR OWN RISK.

                  <br /><br />

                  <span class="bolded">
                    Limitation of Liability & Release.</span
                  >
                  In consideration of your access to and participation in the
                  Event, you agree that in no event will any of the Event
                  Parties be liable under any theory of tort, contract, strict
                  liability or other legal or equitable theory for any lost
                  profits, lost data, lost opportunities, costs of cover,
                  exemplary, punitive, personal injury/wrongful death,
                  contraction of illness or disease, special, incidental,
                  indirect or other consequential damages, and you release the
                  Event Parties from all such claims. Further, to the fullest
                  extent permitted by law, in no event will the Event Parties be
                  liable for any damages resulting from your access to or
                  participation in the Event, or acceptance, possession, use,
                  misuse or nonuse of the offer, each of which, to the fullest
                  extent permitted by law, is hereby excluded by agreement of
                  the parties regardless of whether or not either party has been
                  advised of the possibility of such damages. Event Parties are
                  not responsible for any lost or missing packages. California
                  Residents: If you are a California resident, you hereby waive
                  California Civil Code Section 1542 in connection with the
                  foregoing, which states: "a general release does not extend to
                  claims which the creditor does not know or suspect to exist in
                  his or her favor at the time of executing the release, which
                  if known by him or her must have materially affected his or
                  her settlement with the debtor." Prohibited Activities: The
                  Event is provided for your personal and non-commercial use.
                  Any other use or activity in connection with the Event or
                  materials provided by the Event Parties requires the prior
                  written consent of the Event Parties. The Event Parties will
                  solely make all determinations in connection with the Event,
                  and your access to the Event or any content or materials
                  related thereto may suspend or terminated for any reason at
                  any time with or without notice.

                  <br /><br />

                  <span class="bolded"> Event Recording.</span> By participating
                  in the Event, you hereby grant to the Event Parties the
                  irrevocable and absolute right and permission to use, in whole
                  or in part, your name, image, photograph, voice, statements
                  (actual or paraphrased), performance and/or any other likeness
                  or indicia identifying you (your “Likeness”) as recorded and
                  embodied in one or more recordings, videos and still images
                  (“Recordings”), including without limitation in connection
                  with internet and digital materials and in press, public
                  relations and publicity materials, all for the purpose of
                  advertising, publicity and marketing for Event Parties and its
                  products and services and/or any other lawful purpose,
                  worldwide, in any and all media now known or hereinafter
                  invented. In addition, at any time Event Parties may use the
                  Recordings and any materials produced therefrom anywhere in
                  the world for industry trade events, sales meetings and for
                  intra-company, research, award, publicity, file and reference
                  purposes.

                  <br /><br />

                  <span class="bolded"> Questions:</span> Should you have any
                  questions regarding these Terms and Conditions or the Event,
                  please email
                  <a
                    style="color: #0068ef"
                    href="mailto:pricelinerewards@vegagroup.com"
                  >
                    pricelinerewards@vegagroup.com</a
                  >.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nu-modal>

    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar @opencart="opencart" />
        </div>
      </div>
      <!-- Navbar section ends -->

      <!-- banner section starts -->
      <div class="row text-center" style="margin-top: 40px">
        <div class="col-md-12" id="banner">
          <div class="col-md-12 col-lg-12 position p-0">
            <div class="mouse_scroll">
              <div>
                <span class="m_scroll_arrows unu"></span>
                <span class="m_scroll_arrows doi"></span>
                <span class="m_scroll_arrows trei"></span>
              </div>
            </div>
          </div>
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_NavyFederalCreditMember_V1.png"
            style="width: 100%; max-width: 100%; height: auto"
          />
        </div>
      </div>
      <!-- banner section ends -->
      <!-- <div
          class="row w-100 m-0 promo-section MYLE-lite-green"
          style="background: #304cb2"
        >
          <div class="col-lg-12 col-md-12">
            <div class="mt-4">
              <div class="section-heading mb-4">
                <h1
                  class="text-center"
                  style="
                    font-family: 'Arial', sans-serif;
                    font-size: 2.5em;
                    color: white;
                  "
                >
                  Laissez les bons temps rouler!
                </h1>
              </div>
            </div>
          </div>
        </div> -->
      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between lessSideMargin"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="col-md-12">
            <div class="row mt-3">
              <div
                class="col-md-12 col-md-12"
                style="font-family: 'Montserrat', sans-serif"
              >
                <p
                  class=""
                  style="
                    font-family: 'Source Sans 3';
                    font-size: 1.4em;
                    font-weight: 400;
                    color: #0c0d0e;
                    text-align: justify;
                  "
                >
                  As a distinguished Navy Federal Credit Union Visa Signature
                  Credit member, you and a guest are formally invited to join us
                  for an exclusive dining experience. In honor of Veteran’s Day,
                  we will be hosting a dinner at The 57th Fighter Group
                  Restaurant on Saturday, November 9th. Event details are below.
                </p>
              </div>

              <!-- <div class="col-md-6 col-md-6">
                <vue-flux
                  :options="vfOptions"
                  :images="vfEventImgs"
                  :transitions="vfTransitions"
                  ref="slider"
                  style=""
                >
                  <template v-slot:preloader>
                    <flux-preloader />
                  </template>
                  <template v-slot:controls>
                    <flux-controls />
                  </template>
                </vue-flux>
              </div> -->
            </div>

            <hr />
            <div class="row mt-2">
              <div class="col-md-12 col-md-12">
                <div class="download-content mt-4 justify-content-center">
                  <h1
                    class="text-center"
                    style="
                      font-family: 'Source Sans 3';
                      font-weight: 700;
                      font-size: 2.8em;
                      color: #0f3d70;
                    "
                  >
                    Event Details
                  </h1>

                  <div class="row mt-5">
                    <div class="col-md-4 col-md-4"></div>
                    <div class="col-md-4 col-md-4">
                      <p
                        class="text-center"
                        style="
                          font-family: 'Source Sans 3';
                          font-weight: 600;
                          font-size: 1.3em;
                          color: #0c0d0e;
                        "
                      >
                        <img
                          src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/SWA_icon_calendaer.png"
                          style="border-radius: 3%; width: 15%"
                        />
                        <br />
                        <span
                          style="
                            font-family: 'Source Sans 3';
                            font-weight: 700;
                            color: #0f3d70;
                          "
                          >Saturday, November 9, 2024 </span
                        ><br />
                        6:00 pm − 9:00 pm ET
                      </p>
                    </div>
                    <div class="col-md-4 col-md-4"></div>
                  </div>
                </div>
              </div>

              <div class="col-md-4"></div>
              <div class="col-md-4">
                <p
                  class="text-center"
                  style="
                    font-family: 'Source Sans 3';
                    font-weight: 600;
                    font-size: 1.3em;
                    color: #0c0d0e;
                  "
                >
                  <img
                    src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/SWA_icon_location.png"
                    style="border-radius: 3%; width: 8%"
                  />
                  <br />

                  <a
                    href="https://maps.app.goo.gl/FNFLW1ySME5PeSj17"
                    target="_blank"
                    rel="noopener noreferrer"
                    style="color: #0c0d0e"
                  >
                    <span
                      style="
                        font-family: 'Source Sans 3';
                        font-weight: 700;
                        color: #0f3d70;
                      "
                      >The 57th Fighter Group Restaurant </span
                    ><br />
                    3829 Clairmont Rd, Atlanta, GA 30341
                  </a>
                </p>
              </div>
              <div class="col-md-4"></div>
            </div>
            <hr />

            <div class="row justify-content-center">
              <div class="col-md-12">
                <div class="mt-2">
                  <div
                    class="row text-center align-items-center justify-content-between"
                    style="background: #e8f1fa; border-radius: 8px"
                  >
                    <div class="col-lg-12 col-md-12">
                      <div class="align-items-center justify-content-between">
                        <div
                          class="align-items-center justify-content-between mt-3 mb-3"
                        >
                          <button
                            class="btn solid-btn-NavyFederal align-items-center justify-content-between"
                            @click="openRegisterModal()"
                            style="
                              pointer-events: all;
                              cursor: pointer;
                              font-family: 'Source Sans 3';
                              font-size: 1.3em;
                              font-weight: 600;
                            "
                          >
                            {{ "RESERVE NOW" }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="mt-5">
                    <p
                      class=""
                      style="
                        font-family: 'Source Sans 3';
                        font-weight: 400;
                        font-size: 1.3em;
                        color: #0c0d0e;
                      "
                    >
                      For a contribution of $20, you can secure admission for
                      two (2) to this member-exclusive event. This includes:
                      <br />
                      <span class="ml-4"
                        >• A cocktail hour to kick-start your evening</span
                      >
                      <br />
                      <span class="ml-4"
                        >• A three-course dinner with open bar</span
                      ><br />
                      <span class="ml-4"
                        >• Keepsake professional photos and exclusive Navy
                        Federal swag</span
                      ><br />
                      <span class="ml-4">
                        • A $100 rideshare code to ensure safe and comfortable
                        transportation to and from the restaurant</span
                      >
                      <br /><br />
                    </p>

                    <p
                      style="
                        font-family: 'Source Sans 3';
                        font-weight: 600;
                        font-size: 1.3em;
                        color: #0c0d0e;
                      "
                      class="text-center"
                    >
                      100% of proceeds will go to The Mission Continues, a
                      national nonprofit organization that connects military
                      veterans with new missions in under-resourced communities.
                    </p>
                  </div>

                  <hr v-if="activeWaitList" />
                  <!-- ////////// WAIT LIST SECTION ////////// -->
                  <div class="text-center mt-4" v-if="activeWaitList">
                    <p
                      class="w-auto d-inline-block mb-0"
                      style="
                        font-family: 'Arial', sans-serif;
                        font-weight: bold;
                        font-size: 2.2em;
                        color: #f9b329;
                      "
                    >
                      Waitlist
                    </p>
                    <p
                      style="
                        font-family: 'Arial', sans-serif;
                        font-size: 1.2em;
                        color: rgb(34, 33, 33);
                      "
                    >
                      Reserve a spot on our waitlist and let us know which event
                      day and the number of tickets you are interested in.
                    </p>

                    <button
                      class="btn solid-btn-SWA"
                      style="
                        pointer-events: all;
                        cursor: pointer;
                        font-family: 'Arial', sans-serif;
                        font-size: 1.2em;
                      "
                      @click="openmodel(section1[0], true)"
                    >
                      Join waitlist
                    </button>
                  </div>
                  <!-- ////////////////////////////// -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />
    </div>
  </div>
</template>

<script>
// Component Imports

import NavBar from "../../../views/commons/Navbar4";
// import SiteFooter from "../../views/index-five/SiteFooter";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";
// import SWAVisaCardVerifyFailed from "./SWAVisaCardVerifyFailed/SWAVisaCardVerifyFailed.vue";
import confirmationPopup from "../../../components/confirmationPopup";
import errorModal from "../../../components/Error/Error";
import successModal from "./Success/Success";
import VueElementLoading from "vue-element-loading";
// import SWAVisaWaitlistSuccess from "./SWAVisaWaitlistSuccess/SWAVisaWaitlistSuccess.vue";
// import { VueFlux, FluxPreloader, FluxControls } from "vue-flux";

// defining component state
export default {
  name: "newyear",
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  data() {
    return {
      guestComing: "Yes",
      guestFirstName: "",
      guestLastName: "",
      guestEmail: "",
      guestContactPhone: "",
      qquestion: "",
      istouched: false,
      preicelessData: [],
      userFirstName: "",
      userLastName: "",
      usersEmail: "",
      userContactPhone: "",
      streetAddress: "",
      city: "",
      zip: "",
      state: "",
      question: "",
      loader: false,
      isjlist: false,
      completeList: [],
      activeWaitList: false,
      daytitle: {},
      ticketbyid: {},
      initvalue: {},
      maping: {
        Thursday: {
          id: "tk_4vCv9db4olSi3e7",
          pid: "price_1Oz3YhD0vTZ4QB9cl5n5D5kN",
        },
        Friday: {
          id: "tk_3X7BWkfGQs11KnH",
          pid: "price_1Oz3YxD0vTZ4QB9cJKFpot7I",
        },
        Saturday: {
          id: "tk_wfTvChE2zgJdC46",
          pid: "price_1Oz3Z2D0vTZ4QB9cmzNpZflP",
        },
      },
      action: "",
      actionValue: "",
      rr1: null,
      observer: null,
      vcesId: null,
      ismounted: false,
      iframeSrc: "https://google.com", // /?Token={1}&amp;Lang={2}"
      cntholder: {},
      firstName: "",
      lastName: "",
      prevRoute: "",
      daylist: "Thursday",
      selectedCarts: [],
      ttypelist: null,
      nameOfBusiness: "",
      address: "",
      cityStateZip: "",
      contactPerson: "",
      telephoneNumber: "",
      emailAddress: "",
      names: {},
      showsuccess: false,
      states: [
        { "States in USA": "Alabama" },
        { "States in USA": "Alaska" },
        { "States in USA": "Arizona" },
        { "States in USA": "Arkansas" },
        { "States in USA": "Califonria" },
        { "States in USA": "Colorado" },
        { "States in USA": "Connecticut" },
        { "States in USA": "Delaware" },
        { "States in USA": "Dictrict of Columbia" },
        { "States in USA": "Florida" },
        { "States in USA": "Georgia" },
        { "States in USA": "Hawaii" },
        { "States in USA": "Idaho" },
        { "States in USA": "Illinois" },
        { "States in USA": "Indiana" },
        { "States in USA": "Iowa" },
        { "States in USA": "Kansas" },
        { "States in USA": "Kentucky" },
        { "States in USA": "Louisiana" },
        { "States in USA": "Maine" },
        { "States in USA": "Maryland" },
        { "States in USA": "Massachusetts" },
        { "States in USA": "Michigan" },
        { "States in USA": "Minnesota" },
        { "States in USA": "Mississippi" },
        { "States in USA": "Missouri" },
        { "States in USA": "Montana" },
        { "States in USA": "Nebraska" },
        { "States in USA": "Nevada" },
        { "States in USA": "New Hampshire" },
        { "States in USA": "New Jersey" },
        { "States in USA": "New Mexico" },
        { "States in USA": "New York" },
        { "States in USA": "North Carolina" },
        { "States in USA": "North Dakota" },
        { "States in USA": "Ohio" },
        { "States in USA": "Oklahoma" },
        { "States in USA": "Oregon" },
        { "States in USA": "Pennsylvania" },
        { "States in USA": "Rhode Island" },
        { "States in USA": "South Carolina" },
        { "States in USA": "South Dakota" },
        { "States in USA": "Tennessee" },
        { "States in USA": "Texas" },
        { "States in USA": "Utah" },
        { "States in USA": "Vermont" },
        { "States in USA": "Virginia" },
        { "States in USA": "Washington" },
        { "States in USA": "West Virginia" },
        { "States in USA": "Wisconsin" },
        { "States in USA": "Wyoming" },
      ],
      district: [
        {
          Dr: {
            superintendent: "No District Affiliation",
          },
        },
        {
          Dr: {
            superintendent: "Dr. Sandra Thomas, Echo Joint Agreement",
          },
        },

        {
          Dr: {
            superintendent: "Dr. Tina Halliman, SPEED Joint Agreement",
          },
        },

        {
          Dr: {
            superintendent: "District 92 - Dr. Janiece Jackson, Lindop School",
          },
        },

        {
          Dr: {
            superintendent: "District 98 - Dr. Michelle Smith, Berwyn School",
          },
        },

        {
          Dr: {
            superintendent:
              "District 111 - Dr. Genevra Walters, Kankakee School",
          },
        },

        {
          Dr: {
            superintendent: "District 125 - Dr. Lisa Cole, Atwood Heights",
          },
        },

        {
          Dr: {
            superintendent:
              "District 132 - Dr. Marcus Alexander, Calumet School",
          },
        },

        {
          Dr: {
            superintendent:
              "District 143.5 - Dr. Anthony Edison, Posen Robbins School",
          },
        },

        {
          Dr: {
            superintendent:
              "District 144 - Dr. Denise Julius, Dr. Terry O'brien, Prairie Hills School",
          },
        },

        {
          Dr: {
            superintendent:
              "District 147 - Dr. Jerry Jordan, Dr. Creg Williams, Harvey Dixmoor School",
          },
        },

        {
          Dr: {
            superintendent:
              "District 148 - Kevin Nohelty, Dolton Riverdale School",
          },
        },

        {
          Dr: {
            superintendent: "District 149 - Dr. Maureen White, Dolton School",
          },
        },

        {
          Dr: {
            superintendent:
              "District 150 - Jerome Farrell, South Holland School",
          },
        },

        {
          Dr: {
            superintendent: "District 152 - Dr. Lela Bridges, Harvey School",
          },
        },

        {
          Dr: {
            superintendent:
              "District 152.5 - Dr. Kenneth Spells, East Hazel Crest School",
          },
        },

        {
          Dr: {
            superintendent: "District 156 - Dr. Anita Rice, Lincoln School",
          },
        },

        {
          Dr: {
            superintendent:
              "District 157 - Dr. Dwayne Evans, Calumet City School",
          },
        },

        {
          Dr: {
            superintendent:
              "District 159 - Dr. Tiffany Brunson, Elementary School",
          },
        },

        {
          Dr: {
            superintendent:
              "District 160 - Dr. Duane Meighan, Country Club Hills School",
          },
        },

        {
          Dr: {
            superintendent: "District 162 - Dr. Blondean Davis, Matteson/SCP",
          },
        },

        {
          Dr: {
            superintendent:
              "District 163 - Dr. Joyce Carmine, Park Forest School",
          },
        },

        {
          Dr: {
            superintendent: "District 167 - Bethany Lindsay, Brookwood School",
          },
        },

        {
          Dr: {
            superintendent:
              "District 168 - Dr. Donna Simpson Leak, Sauk Village School",
          },
        },

        {
          Dr: {
            superintendent: "District 169 - Dr. Gregory Jackson, Ford Heights",
          },
        },

        {
          Dr: {
            superintendent:
              "District 171 - Dr. Erika Millhouse, Sunnybrook School",
          },
        },

        {
          Dr: {
            superintendent: "District 172 - Dr. Kim Nalls, Sandridge School",
          },
        },

        {
          Dr: {
            superintendent:
              "District 205 - Dr. Nathaniel Cunningham, Thornton HS",
          },
        },

        {
          Dr: {
            superintendent: "District 206 - Dr. Lenell Navarre, Bloom Township",
          },
        },

        {
          Dr: {
            superintendent: "District 209 - Dr. Bessie Karvelas, Proviso HS",
          },
        },

        {
          Dr: {
            superintendent:
              "District 215 - Dr. John M. Robinzine, Thornton Fractional HS",
          },
        },

        {
          Dr: {
            superintendent:
              "District 227 - Dr. Johnnie Thomas, Rich Township HS",
          },
        },

        {
          Dr: {
            superintendent: "District 228 - Dr. Brad Sakora, Breman HS",
          },
        },

        {
          Dr: {
            superintendent:
              "District 233 - Dr. Scott Wakeley, Homewood Flossmoor HS",
          },
        },

        {
          Dr: {
            superintendent: "Dr. Cynthia Broughton",
          },
        },

        {
          Dr: {
            superintendent: "Dr. Eric King",
          },
        },

        {
          Dr: {
            superintendent: "Dr. Gloria Davis",
          },
        },

        {
          Dr: {
            superintendent: "Dr. John Sawyer",
          },
        },

        {
          Dr: {
            superintendent: "Dr. Louise Coleman",
          },
        },

        {
          Dr: {
            superintendent: "Dr. Mable Alfred",
          },
        },

        {
          Dr: {
            superintendent: "Dr. Sheila Harrison-Williams",
          },
        },

        {
          Dr: {
            superintendent: "Dr. Willie Mack",
          },
        },
        {
          Dr: {
            superintendent: "Kay Giles",
          },
        },

        {
          Dr: {
            superintendent: "Ms. Lisa Cole Hide",
          },
        },

        {
          Dr: {
            superintendent: "Phylistine Murphy",
          },
        },

        {
          Dr: {
            superintendent: "Theodis Swopes",
          },
        },
      ],
      vchange: true,
      validSection: true,
      elName: "",
      eEmail: "",
      business_organization_name: "",
      emNumber: "",
      eTitle: "",
      eRefferedBy: "",
      efatherName: "",
      efEmail: "",
      ePhoneNumber: "",
      eOrgNumber: "",
      age: {},
      section2: [],
      section1: ["tk_PmKuRNH6Hb4CTDw"],
      redeempromo: "",
      redeemPriceId: "",
      redeemData: null,
      reserveFormAccept: false,
      reserveFormAcceptAboutAgeRestriction: false,
      tkList: {
        tk_PmKuRNH6Hb4CTDw: {
          messages: [],
        },
      },

      priceIds: {
        tk_PmKuRNH6Hb4CTDw: "price_1PxrgWD0vTZ4QB9cqKQzf4P9",
      },

      countsListShow: [],
      eventTicketResult: [],
      selectedAccor: null,
      ticketForm: {
        name: "",
        email: "",
        phone: "",
        ticketCount: 0,
        ticketName: "",
        ticketId: "",
        method: "",
        accept: "",
        file: null,
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      email: "",
      tickcount: 1,
      phone: "",
      isSubmitted: false,
      tickcountlist: [],
      show: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
      vfOptions: {
        autoplay: true,
      },
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],
      vfImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
      ],
      vfEventImgs: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/priceline_visa_slider_images/priceline_visa_slidershow_img_3.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/priceline_visa_slider_images/priceline_visa_slidershow_img_1.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/priceline_visa_slider_images/priceline_visa_slidershow_img_4.jpg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/priceline_visa_slider_images/priceline_visa_slidershow_img_2.jpg",
      ],
    };
  },
  components: {
    NavBar,
    // SWAVisaCardVerifyFailed,
    // SiteFooter,
    confirmationPopup,
    VueElementLoading,
    StripeCheckout,
    // SWAVisaWaitlistSuccess,
    // FluxControls,
    // VueFlux,
    // FluxPreloader,
    successModal,
    errorModal,
  },
  methods: {
    fetchRecords(cb) {
      let vm = this;
      axios({
        method: "get",
        url: "https://myle-19701.uc.r.appspot.com/api/fetchSponsoredPricelineVisaTickets",
      }).then((response) => {
        if (response && response.data && response.data.Result) {
          vm.preicelessData = response.data.Result;
        }

        if (cb) {
          cb();
        }
      });
    },
    closeModaldata() {
      this.$refs.modalreg1.close();
      this.$refs.smodal.close();
    },
    saveNewForm() {
      this.istouched = true;
      if (this.valCheck || !this.reserveFormAccept) {
        return;
      }
      this.isLoading = true;
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/requested_tickets",
        data: {
          eventId: "5FMjfSHsgCDPqb",
          totalTickets: this.guestComing === "Yes" ? 2 : 1,
          userName: this.userFirstName + " " + this.userLastName,
          userEmail: this.usersEmail.toLowerCase(),
          priceId: "price_1PxrgWD0vTZ4QB9cqKQzf4P9",
          ticketId: "tk_PmKuRNH6Hb4CTDw",
          extraInfo: {
            dietRestriction: this.question,
            phone: this.userContactPhone,
            guestName: this.guestFirstName + " " + this.guestLastName,
            guestEmail: this.guestEmail.toLowerCase(),
            guestPhone: this.guestContactPhone,
            guestDietRestriction: this.qquestion,
          },
        },
      })
        .then((re) => {
          if (re.data && re.data.Result && re.data.Result.sessionId) {
            this.checkout.clientReferenceId = re.data.Result.sessionId;
            this.$refs.checkoutRef.redirectToCheckout();
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    opencartfalse() {
      this.$refs.modal1.close();
      this.$refs.model2.open();
    },
    getstatus() {
      this.isSubmitted = true;
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/vces_verify_card_eligibility",
        data: {
          vcesId: this.vcesId,
        },
      })
        .then((resp) => {
          if (resp && resp.data && resp.data.Result) {
            if (resp.data.Result.eligible) {
              this.opencartm();
            } else {
              this.opencartfalse();
            }
          } else {
            this.opencartfalse();
          }
          console.log(resp);
        })
        .catch(() => {
          this.opencartfalse();
        })
        .finally(() => {
          this.isSubmitted = false;
        });
    },
    closeModalerror() {
      this.$refs.emodal.close();
    },
    onLoad() {
      if (this.ismounted) {
        setTimeout(() => {
          this.isSubmitted = false;
        }, 0);
      }
    },
    opencartm() {
      this.$refs.modal1.close();
      this.$refs.modal.open();
    },
    changeFavicon(url) {
      console.log("warda", url);
      var link = document.querySelectorAll("link[rel~='icon']");
      if (link && link.length) {
        link.forEach((r) => {
          r.remove();
        });
      }
      link = null;
      var applink = document.querySelectorAll("link[rel~='apple-touch-icon']");
      if (applink && applink.length) {
        applink.forEach((r) => {
          r.remove();
        });
      }
      applink = null;

      var mapplink = document.querySelectorAll("link[rel~='mask-icon']");
      if (mapplink && mapplink.length) {
        mapplink.forEach((r) => {
          r.remove();
        });
      }

      mapplink = null;

      setTimeout(() => {
        const mapplink1 = document.createElement("link");
        mapplink1.rel = "mask-icon";
        mapplink1.color = "#5bbad5";
        mapplink1.href = url + "safari-pinned-tab.svg?v=2";
        document.head.appendChild(mapplink1);

        const applink1 = document.createElement("link");
        applink1.rel = "apple-touch-icon";
        applink1.sizes = "180x180";
        applink1.href = url + "apple-touch-icon.png?v=2";
        document.head.appendChild(applink1);

        const applink12 = document.createElement("link");
        applink12.rel = "apple-touch-icon";
        applink12.sizes = "152x152";
        applink12.href = url + "apple-touch-icon.png?v=2";
        document.head.appendChild(applink12);

        const link1 = document.createElement("link");
        link1.rel = "icon";
        link1.sizes = "16x16";
        link1.href = url + "favicon-16x16.png?v=2";
        document.head.appendChild(link1);

        const link96 = document.createElement("link");
        link96.rel = "icon";
        link96.sizes = "96x96";
        link96.href = url + "favicon-96x96.png?v=2";
        document.head.appendChild(link96);

        const link192 = document.createElement("link");
        link192.rel = "icon";
        link192.sizes = "192x192";
        link192.href = url + "android-icon-192x192.png?v=2";
        document.head.appendChild(link192);

        const link180 = document.createElement("link");
        link180.rel = "apple-touch-icon";
        link180.sizes = "180x180";
        link180.href = url + "apple-icon-180x180.png?v=2";
        document.head.appendChild(link180);

        const link152 = document.createElement("link");
        link152.rel = "apple-touch-icon";
        link152.sizes = "152x152";
        link152.href = url + "apple-icon-152x152.png?v=2";
        document.head.appendChild(link152);

        const link144 = document.createElement("link");
        link144.rel = "apple-touch-icon";
        link144.sizes = "144x144";
        link144.href = url + "apple-icon-144x144.png?v=2";
        document.head.appendChild(link144);

        const link120 = document.createElement("link");
        link120.rel = "apple-touch-icon";
        link120.sizes = "120x120";
        link120.href = url + "apple-icon-120x120.png?v=2";
        document.head.appendChild(link120);

        const link114 = document.createElement("link");
        link114.rel = "apple-touch-icon";
        link114.sizes = "114x114";
        link114.href = url + "apple-icon-114x114.png?v=2";
        document.head.appendChild(link114);

        const link76 = document.createElement("link");
        link76.rel = "apple-touch-icon";
        link76.sizes = "76x76";
        link76.href = url + "apple-icon-76x76.png?v=2";
        document.head.appendChild(link76);

        const link72 = document.createElement("link");
        link72.rel = "apple-touch-icon";
        link72.sizes = "72x72";
        link72.href = url + "apple-icon-72x72.png?v=2";
        document.head.appendChild(link72);

        const link60 = document.createElement("link");
        link60.rel = "apple-touch-icon";
        link60.sizes = "60x60";
        link60.href = url + "apple-icon-60x60.png?v=2";
        document.head.appendChild(link60);

        const link57 = document.createElement("link");
        link57.rel = "apple-touch-icon";
        link57.sizes = "57x57";
        link57.href = url + "apple-icon-57x57.png?v=2";
        document.head.appendChild(link57);

        const link2 = document.createElement("link");
        link2.rel = "icon";
        link2.sizes = "32x32";
        link2.href = url + "favicon-32x32.png?v=2";
        document.head.appendChild(link2);

        const link3 = document.createElement("link");
        link3.rel = "icon";
        link3.href = url + "favicon.ico?v=2";
        document.head.appendChild(link3);
      }, 300);
    },
    setValues(c) {
      this.cntholder[this.daylist] = c;
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    isNumber1: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    opencart() {
      this.openRegisterModal();
    },
    openRegisterModal() {
      this.guestComing = "No";
      this.guestFirstName = "";
      this.guestLastName = "";
      this.guestEmail = "";
      this.guestContactPhone = "";
      this.qquestion = "";
      this.istouched = false;
      this.userFirstName = "";
      this.userLastName = "";
      this.usersEmail = "";
      this.userContactPhone = "";
      this.streetAddress = "";
      this.city = "";
      this.zip = "";
      this.state = "";
      this.question = "";
      this.$refs.modalreg1.open();
    },
    addtocart(rec) {
      // console.log("dasdsadsad");
      if (this.tickcount < 1) {
        return;
      }
      if (rec) {
        rec.tttcount = this.tickcount;
        rec.daylist = this.daylist;
        this.selectedCarts.push({ ...rec });
        this.setValues((this.cntholder[rec.daylist] || 0) + this.tickcount);
        console.log("dsds", this.cntholder);
      }

      this.dayChange();
    },
    deleteRec(rec, i) {
      console.log(i);

      this.selectedCarts = this.selectedCarts.filter((x, it) => {
        if (it == i) {
          this.daylist = rec.daylist;
          this.setValues((this.cntholder[rec.daylist] || 0) - rec.tttcount);
        }
        return it != i;
      });
      this.ttypelist = this.TicketsResult[0];
      this.dayChange();
    },
    totalCount(list) {
      if (this.TicketsResult[this.selected]) {
        const tmp = Object.keys(list).length;
        return tmp > 1 ? [...Array(tmp - 1).keys()] : [];
      }

      return [];
    },
    dayChange() {
      this.tickcount = 1;
      this.tickcountlist = [];
      console.log("Fff");
      const w = this.ticketbyid[this.maping[this.daylist].id];
      for (
        let k = 0;
        k <
        (w.availableTickets > w.allowedPerRequest
          ? w.allowedPerRequest
          : w.availableTickets);
        k++
      ) {
        this.tickcountlist.push(k + 1);
      }

      if (this.cntholder && this.cntholder[this.daylist] > 0) {
        const len = this.tickcountlist.length;
        this.tickcountlist = [];
        for (let k1 = 0; k1 < len - this.cntholder[this.daylist]; k1++) {
          this.tickcountlist.push(k1 + 1);
        }
      }

      if (this.tickcountlist.length == 0) {
        this.tickcount = 0;
      }

      if (!(this.daylist in this.initvalue)) {
        this.initvalue[this.daylist] = this.tickcountlist.length;
      }

      if (this.isjlist) {
        for (let k = 0; k < w.allowedPerRequest; k++) {
          this.tickcountlist.push(k + 1);
        }

        if (this.cntholder && this.cntholder[this.daylist] > 0) {
          const len = this.tickcountlist.length;
          this.tickcountlist = [];
          for (let k1 = 0; k1 < len - this.cntholder[this.daylist]; k1++) {
            this.tickcountlist.push(k1 + 1);
          }
        }
      }
    },
    countChange() {
      const tmp = { ...this.names };
      this.names = {};

      if (this.tickcount > 1) {
        for (let i = 0; i < this.tickcount - 1; i++) {
          this.names["name" + i] = tmp["name" + i] || "";
          this.names["email" + i] = tmp["email" + i] || "";
        }
      }
    },
    invalidNum(n) {
      return (n || "").match(/\d/g) && (n || "").trim().length >= 10;
    },
    checkInvalidEmail(e) {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(e);
    },
    section1Filter(list) {
      return list.filter((l) => this.section1.includes(l.ticketCode));
    },
    filteredMessages(obj) {
      if (obj && this.tkList && this.tkList[obj.ticketCode]) {
        return this.tkList[obj.ticketCode].messages;
      }

      return [];
    },
    closeModal() {
      this.showsuccess = false;
      this.$refs.confirmmodal.close();
    },
    openmodelmyle() {
      this.validSection = true;
      this.nameOfBusiness = "";
      this.address = "";
      this.cityStateZip = "";
      this.contactPerson = "";
      this.telephoneNumber = "";
      this.emailAddress = "";
      this.firstName = "";
      this.lastName = "";
      this.email = "";
      this.elName = "";
      this.eEmail = "";
      this.business_organization_name = "";
      this.emNumber = "";
      this.eTitle = "";
      this.eRefferedBy = "";
      this.efatherName = "";
      this.efEmail = "";
      this.ePhoneNumber = "";
      this.eOrgNumber = "";
      this.age = {};
      this.reserveFormAccept = false;
      this.reserveFormAcceptAboutAgeRestriction = false;
      this.selectedCarts = [];
      this.names = {};
      this.ttypelist = this.TicketsResult[0];
      this.redeemData = null;
      this.redeemPriceId = "";
      this.$refs.modal.open();
      this.selected = 0;
      const idx = 0;
      this.tickcountlist = [];
      for (
        let k = 0;
        k <
        (this.TicketsResult[idx].availableTickets >
        this.TicketsResult[idx].allowedPerRequest
          ? this.TicketsResult[idx].allowedPerRequest
          : this.TicketsResult[idx].availableTickets);
        k++
      ) {
        this.tickcountlist.push(k + 1);
      }
    },

    openTermsModel: function () {
      this.$refs.termsModal.open();
    },

    openmodel: function (code, fg) {
      this.isjlist = fg;
      this.validSection = true;
      this.iframeSrc = null;
      this.nameOfBusiness = "";
      this.address = "";
      this.cityStateZip = "";
      this.contactPerson = "";
      this.telephoneNumber = "";
      this.emailAddress = "";
      this.firstName = "";
      this.lastName = "";
      this.email = "";
      this.elName = "";
      this.eEmail = "";
      this.business_organization_name = "";
      this.emNumber = "";
      this.eTitle = "";
      this.eRefferedBy = "";
      this.efatherName = "";
      this.efEmail = "";
      this.ePhoneNumber = "";
      this.eOrgNumber = "";
      this.reserveFormAccept = false;
      this.reserveFormAcceptAboutAgeRestriction = false;
      this.selectedCarts = [];
      this.names = {};
      this.ttypelist = this.TicketsResult[0];
      let idx = 0;
      this.loader = true;
      this.TicketsResult.forEach((t, ti) => {
        if (t.ticketCode === code) {
          idx = ti;
        }
      });
      this.redeemData = null;
      this.redeemPriceId = "";

      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/vces_load_card_validator",
        data: {},
      })
        .then((resp) => {
          if (resp && resp.data && resp.data.Result) {
            this.iframeSrc = resp.data.Result.url;
            this.vcesId = resp.data.Result.vcesId;
          }
        })
        .finally(() => {
          this.$refs.modal1.open();
          this.loader = false;
        });
      this.selected = idx;
      this.tickcountlist = [];
      for (
        let k = 0;
        k <
        (this.TicketsResult[idx].availableTickets >
        this.TicketsResult[idx].allowedPerRequest
          ? this.TicketsResult[idx].allowedPerRequest
          : this.TicketsResult[idx].availableTickets);
        k++
      ) {
        this.tickcountlist.push(k + 1);
      }

      if (this.cntholder && this.cntholder[this.daylist] > 0) {
        this.tickcountlist = [];
        for (let k1 = 0; k1 < this.cntholder[this.daylist]; k1++) {
          this.tickcountlist.push(k1 + 1);
        }
      }
    },
    joinpaynowcustom: function () {
      /**
       * This function here is for  pay methods
       */
      this.isSubmitted = true;
      const vm = this;

      const payloadData = {
        userName: this.firstName + " " + this.lastName,
        userEmail: this.email.toLowerCase(),
        userId: "",
        eventId: this.TicketsResult[0].eventId,
        origin: "WEB",
        tickets: [],
      };
      const recss = {
        Thursday: { totalTickets: 0 },
        Friday: { totalTickets: 0 },
        Saturday: { totalTickets: 0 },
      };
      this.selectedCarts.forEach((re) => {
        recss[re.daylist] = {
          priceId: this.maping[re.daylist].pid,
          ticketId: this.maping[re.daylist].id,
          totalTickets: recss[re.daylist].totalTickets + re.tttcount,
          additionalInfo: {},
          extraInfo: {},
        };
      });

      if (!recss["Thursday"].priceId) {
        delete recss["Thursday"];
      }

      if (!recss["Friday"].priceId) {
        delete recss["Friday"];
      }

      if (!recss["Saturday"].priceId) {
        delete recss["Saturday"];
      }

      payloadData.tickets = Object.values(recss);

      vm.isSubmitted = true;

      axios
        .post(
          "https://myle-19701.uc.r.appspot.com/api/addToWaitList",
          payloadData
        )
        .then((re) => {
          vm.action = "";
          vm.actionValue = "";
          if (re.data && re.data.Message) {
            vm.$refs.modal1.close();
            vm.$refs.model2.close();
            vm.$refs.modal.close();
            vm.$refs.modelwait.open();
            vm.fetchAllTickets();
          } else {
            vm.$toast.error("Session Id not Received", { timeout: 4000 });
          }
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.reserveFormAccept = false;
          vm.reserveFormAcceptAboutAgeRestriction = false;
        })
        .finally(() => {
          vm.isSubmitted = false;
        });
    },
    paynowcustom: function () {
      /**
       * This function here is for  pay methods
       */
      this.isSubmitted = true;
      const vm = this;

      const payloadData = {
        userName: this.firstName + " " + this.lastName,
        userEmail: this.email.toLowerCase(),
        userId: "",
        eventId: this.TicketsResult[0].eventId,
        origin: "WEB",
        tickets: [],
      };
      const recss = {
        Thursday: { totalTickets: 0 },
        Friday: { totalTickets: 0 },
        Saturday: { totalTickets: 0 },
      };
      this.selectedCarts.forEach((re) => {
        recss[re.daylist] = {
          priceId: this.maping[re.daylist].pid,
          ticketId: this.maping[re.daylist].id,
          totalTickets: recss[re.daylist].totalTickets + re.tttcount,
          additionalInfo: {},
          extraInfo: {},
        };
      });

      if (!recss["Thursday"].priceId) {
        delete recss["Thursday"];
      }

      if (!recss["Friday"].priceId) {
        delete recss["Friday"];
      }

      if (!recss["Saturday"].priceId) {
        delete recss["Saturday"];
      }

      payloadData.tickets = Object.values(recss);

      vm.isSubmitted = true;

      axios
        .post(
          "https://myle-19701.uc.r.appspot.com/api/request_cart_tickets",
          payloadData
        )
        .then((re) => {
          vm.action = "";
          vm.actionValue = "";
          if (
            re.data &&
            re.data.Result &&
            re.data.Result.sessionId &&
            re.data.Result.gateway
          ) {
            vm.action = re.data.Result.url;
            vm.actionValue = re.data.Result.sessionId;
            setTimeout(() => {
              console.log(vm.action, vm.actionValue);
              const tbtn = document.getElementById("btnContinue");
              tbtn.click();
            }, 1000);
          } else if (re.data && re.data.Result && re.data.Result.sessionId) {
            vm.checkout.clientReferenceId = re.data.Result.sessionId;
            localStorage.setItem("refsId", vm.checkout.clientReferenceId);
            vm.$refs.checkoutRef.redirectToCheckout();
            setTimeout(() => {
              vm.isSubmitted = false;
            }, 60000);

            vm.reserveFormAccept = false;
            vm.reserveFormAcceptAboutAgeRestriction = false;
          } else {
            vm.$toast.error("Session Id not Received", { timeout: 4000 });
          }
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.reserveFormAccept = false;
          vm.reserveFormAcceptAboutAgeRestriction = false;
        })
        .finally(() => {
          vm.isSubmitted = false;
        });
    },
    redeem: function () {
      const vm = this;
      vm.isSubmitted = true;
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/redeem_promo_code",
        data: {
          eventId: this.TicketsResult[this.selected].eventId,
          ticketId: this.TicketsResult[this.selected].ticketCode,
          promoCode: this.redeempromo,
        },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.redeemPriceId = re.data.Result.discountedPriceId;
            vm.redeemData = re.data.Result;
          }
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.$toast.error("Promo code failed", { timeout: 4000 });
          vm.redeemPriceId = "";
          vm.redeemData = null;
        });
    },
    fetchAllTickets: function (cb) {
      /**
       * This function here is used to fetch tickets
       */

      const vm = this;
      this.isSubmitted = true;
      this.eventTicketResult = [];
      this.TicketsResult = [];
      this.activeWaitList = false;
      const payloadToPush = {
        eventId: "5FMjfSHsgCDPqb",
      };
      axios
        .post(
          "https://myle-19701.uc.r.appspot.com/api/fetchSponsoredEventTicketsWithSeating",
          payloadToPush
        )
        .then((ticketsResult) => {
          if (ticketsResult && ticketsResult.data.Result) {
            vm.TicketsResult = ticketsResult.data.Result;
            vm.TicketsResult.forEach((e) => {
              if (e.activeWaitList) {
                this.activeWaitList = true;
              }
              if (e.ticketName.includes("Thursday")) {
                this.daytitle["Thursday"] = e.ticketName;
              }

              if (e.ticketName.includes("Friday")) {
                this.daytitle["Friday"] = e.ticketName;
              }

              if (e.ticketName.includes("Saturday")) {
                this.daytitle["Saturday"] = e.ticketName;
              }
            });

            const r1 = this.TicketsResult.filter((e) => e.activeWaitList);
            vm.completeList = r1.map((e) => {
              if (e.ticketName.includes("Thursday")) {
                return "Thursday";
              }

              if (e.ticketName.includes("Friday")) {
                return "Friday";
              }

              if (e.ticketName.includes("Saturday")) {
                return "Saturday";
              }
            });
          }

          vm.TicketsResult = vm.TicketsResult.filter(
            (rec) => vm.tkList[rec.ticketCode]
          );

          const objtmp = {
            tk_PmKuRNH6Hb4CTDw: null,
          };

          ticketsResult.data.Result.forEach((tr) => {
            vm.ticketbyid[tr.ticketCode] = tr;
          });

          vm.TicketsResult.forEach((tr) => {
            objtmp[tr.ticketCode] = tr; // tr.status="EXPIRED";
          });
          vm.TicketsResult = Object.values(objtmp);
          vm.eventTicketResult = vm.TicketsResult;
          vm.ttypelist = vm.TicketsResult[0];
          vm.isSubmitted = false;
          vm.dayChange();
          if (cb) {
            cb();
          }
        })
        .catch(() => {
          vm.isSubmitted = false;
        });
    },
  },
  created() {
    document.addEventListener("eventverify", () => {
      this.getstatus();
      this.dayChange();
    });
    const r = window.location.origin;
    this.changeFavicon(r + "/img/icons6/");
  },
  mounted() {
    console.log("change");
    setTimeout(() => {
      this.fetchRecords(() => {
        if (this.prevRoute.path == "/navy-federal-credit-member-in-ga-faqs") {
          console.log(this.prevRoute);
          if (window.showpopup) {
            window.showpopup = false;
            this.openRegisterModal();
          }
        }
      });
    }, 1000);
    if (
      this.$route &&
      this.$route.params &&
      this.$route.params.checkout == "checkout" &&
      this.$route.params.status == "success"
    ) {
      this.$refs.smodal.open();
    } else if (
      this.$route &&
      this.$route.params.checkout == "checkout" &&
      this.$route.params.status == "failed"
    ) {
      this.$refs.emodal.open();
    }

    setTimeout(() => {
      this.ismounted = true;
    }, 0);
  },
  // watch: {
  //   $route(to) {
  //     console.log(to);
  // document.title = `APPLICATION_NAME - ${to.meta.title}`;
  // const link = document.querySelector("[rel='icon']");
  // link.setAttribute("href", to.meta.icon);
  //   },
  // },

  // watch: {
  //   $route: {
  //     handler: function (to) {
  //       document.title = `APPLICATION_NAME - ${to.meta.title}`;
  //       const link = document.querySelector("[rel='icon']");
  //       link.setAttribute("href", to.meta.icon);
  //     },
  //     deep: true,
  //     immediate: true,
  //   },
  // },
  computed: {
    valCheck() {
      return (
        !this.reserveFormAccept ||
        !this.userFirstName.trim().length ||
        !this.userLastName.trim().length ||
        !this.usersEmail.trim().length ||
        !this.userContactPhone.trim().length ||
        // !this.city.trim().length ||
        // !this.state.trim().length ||
        // !this.zip.trim().length ||
        // !this.streetAddress.trim().length ||
        !this.invaliduphone ||
        !this.invalidZip ||
        !this.invaliduemail ||
        (this.guestComing == "Yes"
          ? !this.invalidgemail ||
            !this.guestFirstName.trim().length ||
            !this.guestLastName.trim().length ||
            !this.guestEmail.trim().length ||
            !this.invalidgphone ||
            !this.guestContactPhone.trim().length
          : false)
      );
    },
    invalidZip() {
      return (this.zip || "").match(/^[0-9]*$/g);
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invalidphone() {
      return (
        (this.phone || "").match(/\d/g) &&
        (this.phone || "").trim().length >= 10
      );
    },
    invaliduemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.usersEmail
      );
    },
    invalidgemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.guestEmail
      );
    },
    invaliduphone() {
      if (this.userContactPhone.length <= 0) {
        return true;
      }
      return (
        (this.userContactPhone || "").match(/\d/g) &&
        (this.userContactPhone || "").trim().length >= 10
      );
    },

    invalidgphone() {
      if (this.guestContactPhone.length <= 0) {
        return true;
      }
      return (
        (this.guestContactPhone || "").match(/\d/g) &&
        (this.guestContactPhone || "").trim().length >= 10
      );
    },
  },

  metaInfo: {
    title: "Navy Federal Credit Member in GA",

    meta: [
      {
        name: "description",
        content: "Navy Federal Credit Member in GA",
      },

      {
        name: "keywords",
        content: "Navy Federal Credit Member in GA",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content:
          "https://myle.com/navy-federal-credit-member-in-ga/:checkout?/:status?",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #304cb291 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}
.bolded {
  font-weight: bold;
}

.nu-modal.has-content.is-visible {
  max-width: 600px !important;
}

.bolded {
  font-weight: bold;
}

iframe {
  width: 100% !important;
}
</style>
